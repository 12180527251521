import Vue from 'vue';
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-040077 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Tenant Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Hummingbird )_only_for_( 5 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Hummingbird )_need_to_be_licensed___( Hummingbird )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 May 2024 )____[v2]_MTcxNjQxODgwMDAwMA==c33dfce5d4ca93b517cb6ced4ff8d412");

import App from './App.vue';
// import './registerServiceWorker';
import router from './router';

import store from './store';
import vuetify from './plugins/vuetify';
import { addTokenPrefix } from './utils/common.js'

import  VueResource from 'vue-resource';
import  VueClip from 'vue-clip';
import  StatusMessages from './plugins/StatusMessages.js';
import  HBLoading from './plugins/Loading.js';
import  AuthenticationRole from './mixins/authRole.js';
import  UnitTypeMixin from './mixins/unitTypeMixin.js';
import VeeValidate from 'vee-validate';
import draggable from 'vuedraggable';
import VueObserveVisibility from 'vue-observe-visibility';

import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);

// hummingbird-aviary components
import HbAutocomplete from './aviary/HbAutocomplete.vue';
import HbBladeHeader from './aviary/HbBladeHeader.vue';
import HbBottomActionBar from './aviary/HbBottomActionBar.vue';
import HbBreadcrumb from './aviary/HbBreadcrumb.vue';
import HbBtn from './aviary/HbBtn.vue';
import HbCard from './aviary/HbCard.vue';
import HbCheckbox from './aviary/HbCheckbox.vue';
import HbChip from './aviary/HbChip.vue';
import HbCombobox from './aviary/HbCombobox.vue';
import HbCommunication from './aviary/HbCommunication.vue';
import HbDataTable from './aviary/HbDataTable.vue';
import HbDataTableHeader from './aviary/HbDataTableHeader.vue';
import HbEmptyState from './aviary/HbEmptyState.vue';
import HbExpansionPanel from './aviary/HbExpansionPanel.vue';
import HbFileInput from './aviary/HbFileInput.vue';
import HbForm from './aviary/HbForm.vue';
import HbGlobalNotification from './aviary/HbGlobalNotification.vue';
import HbHeader from './aviary/HbHeader.vue';
import HbHeaderInfo from './aviary/HbHeaderInfo.vue';
import HbIcon from './aviary/HbIcon.vue';
import HbInfoCard from './aviary/HbInfoCard.vue';
import HbLink from './aviary/HbLink.vue';
import HbMenu from './aviary/HbMenu.vue';
import HbMiniProfile from './aviary/HbMiniProfile.vue';
import HbMiniProfileItem from './aviary/HbMiniProfileItem.vue';
import HbModal from './aviary/HbModal.vue';
import HbNotification from './aviary/HbNotification.vue';
import HbPageHeader from './aviary/HbPageHeader.vue';
import HbPageMenu from './aviary/HbPageMenu.vue';
import HbPropertySelector from './aviary/HbPropertySelector.vue';
import HbRadio from './aviary/HbRadio.vue';
import HbRadioGroup from './aviary/HbRadioGroup.vue';
import HbSelect from './aviary/HbSelect.vue';
import HbSpaceIcon from './aviary/HbSpaceIcon.vue';
import HbStatus from './aviary/HbStatus.vue';
import HbStatusGeneral from './aviary/HbStatusGeneral.vue';
import HbStepper from './aviary/HbStepper.vue';
import HbStepperHeader from './aviary/HbStepperHeader.vue';
import HbStepperStep from './aviary/HbStepperStep.vue';
import HbSwitch from './aviary/HbSwitch.vue';
import HbTabs from './aviary/HbTabs.vue';
import HbTextarea from './aviary/HbTextarea.vue';
import HbTextField from './aviary/HbTextField.vue';
import HbTimeline from './aviary/HbTimeline.vue';
import HbTimelineItem from './aviary/HbTimelineItem.vue';
import HbTimelineItemAction from './aviary/HbTimelineItemAction.vue';
import HbTimelineItemSubAction from './aviary/HbTimelineItemSubAction.vue';
import HbToast from './aviary/HbToast.vue';
import HbToastItem from './aviary/HbToastItem.vue';
import HbTooltip from './aviary/HbTooltip.vue';
import HbUnitIcon from './aviary/HbUnitIcon.vue';
// hummingbird-aviary components

// hummingbird specific components
import ContactView from './components/contacts/ContactView.vue';
import UnitView from './components/units/UnitView.vue';
import DisplayInvoice from './components/invoices/DisplayInvoice.vue';
import EditPayment from './components/payments/EditPayment.vue'
import CardNumber from './components/payments/CardNumber.vue';
import CardCVV from './components/payments/CardCVV.vue';
import NewCardNumber from './components/one_time_payment/NewCardNumber.vue';
import NewCardCVV from './components/one_time_payment/NewCardCVV.vue';
import MapViewer from "./components/property_map/MapViewer.vue";
import HelpJuice from "./components/includes/HelpJuice.vue"
// hummingbird specific components

import HBTwilioDevice from './plugins/TwilioDevice';

Vue.component('HbAutocomplete', HbAutocomplete);
Vue.component('HbBladeHeader', HbBladeHeader);
Vue.component('HbBottomActionBar', HbBottomActionBar);
Vue.component('HbBreadcrumb', HbBreadcrumb);
Vue.component('HbBtn', HbBtn);
Vue.component('HbCard', HbCard);
Vue.component('HbCheckbox', HbCheckbox);
Vue.component('HbChip', HbChip);
Vue.component('HbCombobox', HbCombobox);
Vue.component('HbCommunication', HbCommunication);
Vue.component('HbDataTable', HbDataTable);
Vue.component('HbDataTableHeader', HbDataTableHeader);
Vue.component('HbEmptyState', HbEmptyState);
Vue.component('HbExpansionPanel', HbExpansionPanel);
Vue.component('HbFileInput', HbFileInput);
Vue.component('HbForm', HbForm);
Vue.component('HbGlobalNotification', HbGlobalNotification);
Vue.component('HbHeader', HbHeader);
Vue.component('HbHeaderInfo', HbHeaderInfo);
Vue.component('HbIcon', HbIcon);
Vue.component('HbInfoCard', HbInfoCard);
Vue.component('HbLink', HbLink);
Vue.component('HbMenu', HbMenu);
Vue.component('HbMiniProfile', HbMiniProfile);
Vue.component('HbMiniProfileItem', HbMiniProfileItem);
Vue.component('HbModal', HbModal);
Vue.component('HbNotification', HbNotification);
Vue.component('HbPageHeader', HbPageHeader);
Vue.component('HbPageMenu', HbPageMenu);
Vue.component('HbPropertySelector', HbPropertySelector);
Vue.component('HbRadio', HbRadio);
Vue.component('HbRadioGroup', HbRadioGroup);
Vue.component('HbSelect', HbSelect);
Vue.component('HbSpaceIcon', HbSpaceIcon);
Vue.component('HbStatus', HbStatus);
Vue.component('HbStatusGeneral', HbStatusGeneral);
Vue.component('HbStepper', HbStepper);
Vue.component('HbStepperHeader', HbStepperHeader);
Vue.component('HbStepperStep', HbStepperStep);
Vue.component('HbSwitch', HbSwitch);
Vue.component('HbTabs', HbTabs);
Vue.component('HbTextarea', HbTextarea);
Vue.component('HbTextField', HbTextField);
Vue.component('HbTimeline', HbTimeline);
Vue.component('HbTimelineItem', HbTimelineItem);
Vue.component('HbTimelineItemAction', HbTimelineItemAction);
Vue.component('HbTimelineItemSubAction', HbTimelineItemSubAction);
Vue.component('HbToast', HbToast);
Vue.component('HbToastItem', HbToastItem);
Vue.component('HbTooltip', HbTooltip);
Vue.component('HbUnitIcon', HbUnitIcon);

Vue.component('contact', ContactView);
Vue.component('unit', UnitView);
Vue.component('invoice', DisplayInvoice);
Vue.component('payment', EditPayment);
Vue.component('CardNumber', CardNumber);
Vue.component('CardCvv', CardCVV);
Vue.component('NewCardNumber', NewCardNumber);
Vue.component('NewCardCvv', NewCardCVV);
Vue.component("MapViewer", MapViewer);
Vue.component("HelpJuice", HelpJuice);


Vue.use(VeeValidate, {
  events: 'blur'
});

Vue.use(VueResource);
Vue.use(VueClip);
Vue.use(StatusMessages);
Vue.use(HBLoading);
Vue.use(draggable);
Vue.use(VueObserveVisibility);
Vue.use(AuthenticationRole);
Vue.use(UnitTypeMixin);
//Vue.use(VueGridLayout);

Vue.use(HBTwilioDevice);

function extractHostname(url,tld) {
  let hostname;

  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
  } else {
      hostname = url.split('/')[0];
  }
  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];
  if(tld){
    let hostnames = hostname.split('.');
    hostname = hostnames[hostnames.length-2] + '.' + hostnames[hostnames.length-1];
  }
  return hostname;
}

  
// Vue Resource Configuration
Vue.http.options.xhr = { withCredentials : true };
// var paths = location.hostname.split('.');
//var subdomain = paths[0];

// console.log("this.$route", extractHostname( window.location.origin ,true)); 
Vue.http.options.root = process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT + '/v2';
// Vue.http.options.root = process.env.VUE_APP_API_PROTOCOL + '://api.alokin.tenant-curiosity.com/v1';

//Vue.http.options.root = process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + extractHostname( window.location.origin ,true) + '/v1';

Vue.http.interceptors.push((request, next)  => {
  if (store.getters['authenticationStore/checkAuth']){ 
    let authToken = localStorage.getItem('auth_token')
    let gdsUrl = process.env.VUE_APP_GDS_BASE_URL;
    if (gdsUrl && request.url.includes(gdsUrl)) {
      authToken = addTokenPrefix(authToken)
    }
    request.headers.set('authorization', authToken);

    let cid = store.getters['authenticationStore/getCid'];
    if(cid && request.url.indexOf(':company_id') >= 0){
      request.url = request.url.replace(':company_id', cid);
    }
  }

  if(request.url.indexOf(':company_id') >= 0 && request.url.indexOf('panda-docs') < 0 && request.url.indexOf('sign-document') < 0){
    store.dispatch('authenticationStore/logout')
  }


  next(function (response) {
    if(!response.body){
      response.body = {
        status: 500,
        msg: 'We could not process your request. Please try again later.'
      };
    }
    //TODO Fix this!
    if(response.body.status && response.body.status === 401){

      store.dispatch('statusStore/setStatus', {
        status: false,
        message: "Your session has expired. Please log in to continue"
      });
      
      store.dispatch('authenticationStore/logout')
      
      // invalidate login
      // set not logged in flag,
      // show login modal,
      // repeat the requests
    }
  }.bind(this));
});

// TODO Add router callbacks beforeEach
require('./filters');

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

store.$app = app;
