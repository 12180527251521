<template>
    <v-expansion-panels class="mt-4" v-model="panel">
        <hb-expansion-panel class="mb-2">
            <template v-slot:title>
                Single Sign-on Provider
            </template>
            <template v-slot:content>
                <hb-form label="Provider" required>
                    <HbTextField v-model="form.name"></HbTextField>
                </hb-form>
                <hb-form label="Client Id" required>
                    <HbTextField v-model="form.value.clientId"></HbTextField>
                </hb-form>
                <hb-form label="Client Secret" required>
                    <HbTextField v-model="form.value.clientSecret"></HbTextField>
                </hb-form>
                <hb-form label="Tenant Id" required>
                    <HbTextField v-model="form.value.tenantId"></HbTextField>
                </hb-form>
                <hb-form label="Redirect URI" required>
                    <HbTextField v-model="form.value.redirectUri"></HbTextField>
                </hb-form>
             </template>
             <template v-slot:footer>
            <hb-bottom-action-bar :top-border="false"><template v-slot:right-actions><hb-btn @click="saveProvider">Save</hb-btn></template></hb-bottom-action-bar>
        </template>
        </hb-expansion-panel>
    </v-expansion-panels>
</template>
<script type="text/babel">
    import { mapGetters } from 'vuex';
    import api from '../../../../assets/api.js'
    import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
    export default {
        name: 'SSOConfigurationCard',
        mixins: [notificationMixin],
        data() {
            return {
                panel: 0,   
                form: {
                    id: '',
                    name: '',
                    value: {
                        clientId: "",
                        clientSecret: "",
                        tenantId: "",
                        redirectUri: ""
                    }
                } 
            }
        },
        props: ['connection', 'provider', 'ssoproviders'], 
        mounted() {
            if(this.provider?.value){
                this.form = JSON.parse(JSON.stringify(this.provider))
            }
        },
        computed:{
             ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }), 
        },
        components: {
            //Authorizenet,
            //FatzebraCard,
            //TsysCard,
            //TenantPaymentsCard
        },
        methods:{
            remove(selected){
                this.$emit('remove', selected);
            },
            cancel(){
                this.form = JSON.parse(JSON.stringify(this.connection));
            },
            async saveProvider() {
                let data = this.form;
                try {
                    let response = {}
                    if (!this.form.id){
                        response = await api.post(this, api.SSO_PROVIDER, data);
                    }else{
                        response = await api.put(this, api.SSO_PROVIDER,data);
                    }
                    if(response.provider_id){
                        this.setNotification('success',`Saved SSO Provider details.`);
                    }
                } catch (error) {
                    this.setNotification('error', error);
                }
            },
            setNotification(notificationType, notificationText) {
                this.showMessageNotification({ type: notificationType, description: notificationText })
            },
        },
        watch: {
            connection: { 
                handler: function (val, oldVal) {
                     this.form = JSON.parse(JSON.stringify(val));
            
                },
                deep: true

            }

        }
    }

</script>
