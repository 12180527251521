<!-- BCT: Component separated from OverviewLeaseWidget -->
<template>
  <div>
    <v-expansion-panels class="hb-expansion-panel" v-model="rent_settings" multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="px-4 hb-lease-dropdown-header" :class="{'hb-link' : rent_settings.includes(0)}">
          <span class="hb-font-body-medium hb-text-night">Rent & Concessions</span>
        </v-expansion-panel-header>
        <v-divider v-if="rent_settings.includes(0)"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">

          <!-- Move-In -->
          <hb-form v-if="getMoveInData.length" label="Move-in">
            <template v-slot:tooltipHeader>
              <div style="width: 250px">
                Modify the scheduled rent amount for the user. Adjusting the rent amount may result in changes to the scheduled timeframe for deployment.
              </div>
            </template>
            <div v-for="(data, index) in getMoveInData" 
              :key="index" 
              class="mb-2"
            >
              <span v-if="data.value">
                <hb-tooltip v-if="data.toolTip">
                  <template v-slot:item>
                    <hb-icon small class="mr-1">mdi-information</hb-icon>
                  </template>
                  <template v-slot:body>
                    {{ data.toolTip }}
                  </template>
                </hb-tooltip>
                <span v-if="data.label" class="hb-font-caption" style="color:#637381">{{ data.label }}: </span>
                <span v-if="data.type === 'money' && formatMoney(data.value)" class="hb-font-caption">{{ formatMoney(data.value) }}</span>
                <span v-else  class="hb-font-caption hb-night-light">{{ data.value }}</span>
              </span>
            </div>
          </hb-form>
          
          <!-- Rent -->
          <hb-form class="hb-text-night" :full="true" v-if="leaseWithTenants?.rent >= 0" label="Current Rent" :divider="false">
            <v-row class="mb-0">
              <v-col class="pt-0 pb-0 mb-0"  cols="10">
                <div class="rent-details">
                  <div class="mb-0 pb-1">
                    <span>{{ leaseWithTenants.rent | formatMoney }}</span>
                  </div>
                  <div
                    class="hb-font-caption rent-details pt-0"
                  >
                    <div v-if="!is_closed && rentChange.last_rent_change && isValidValue(rentChange.last_rent_change.rent_change) 
                          && isValidValue(rentChange.last_rent_change.schedule_date)">
                      Last Change:
                      <span>
                        {{ rentChange.last_rent_change.rent_change }}%,
                        {{
                          rentChange.last_rent_change.schedule_date
                            | formatDateCustom("MMM DD, YYYY")
                        }}
                      </span>
                    </div>
                    <div class="pb-1" v-if="!is_closed && isValidValue(leaseWithTenants.Unit.price)">
                      Sell Rate:
                      <span> {{ leaseWithTenants.Unit.price | formatMoney }} </span>
                    </div>
                    <div class="pb-1" v-if="!is_closed && isValidValue(leaseWithTenants.Unit.set_rate)">
                      Set Rate:
                      <span>
                        {{ leaseWithTenants.Unit.set_rate | formatMoney }}
                      </span>
                    </div>
                    <div class="pb-1" v-if="!is_closed && rentChange.rent_sell_variance">
                      Variance (Current Rent/Sell):
                      <span v-if="isValidValue(rentChange.rent_sell_variance.amount)">
                        {{ formatMoney(rentChange.rent_sell_variance.amount) }} 
                      </span>
                      <span v-if="isValidValue(rentChange.rent_sell_variance.percentage)">
                      / {{ rentChange.rent_sell_variance.percentage }}% 
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="showChangeRent"
                    class="mt-2"
                  >
                    <hb-link @click="changeRent(false)">Manual Rent Change</hb-link>
                  </div>
                </div>
              </v-col>
              <v-col cols="2" class="mx-0 mb-0 py-0 px-2 mt-n2">
                <hb-menu  align-right bottom left options 
                  v-if="(rentManagementPermissions.exempt_rent_changes || showAssignRentPlan) && !is_closed"
                >
                  <v-list >
                    <v-list-item 
                      v-if="rentManagementPermissions.exempt_rent_changes"
                      @click="showExemptModal"
                    >
                      {{ getExemptActionlabel  }}
                    </v-list-item>
                    <v-list-item 
                      v-if="showAssignRentPlan"
                      @click="changeRentPlan"
                    >
                      {{ assignRentPlanText }}
                    </v-list-item>
                  </v-list>
                </hb-menu>
              </v-col>
            </v-row>
          </hb-form>

          <!-- Manual rent change modal -->
          <RentChangeModal 
            :ids="[ids]"
            :title="rentChangeTitle"
            :edit="isEdit"
            :currentRent="leaseWithTenants.rent"
            :rentData="rentChangeData"
            :tenantProperty="leaseWithTenants.Unit.property_id"
            :manualRentChangeModal="showManualRentChangeModal"
            @closeModal="closeRentChangeModal"
          />

          <!-- Next rent change -->
          <hb-form class="hb-text-night" v-if="rentChange && rentChange.next_rent_change && rentChange.rent_change_id && !is_closed" :full="true" label="Next Rent Change">
            <v-row class="mb-0">
              <v-col  class="pt-0 pb-0 mb-0"  cols="10">
                <div class="rent-details">
                  <div class="pb-1 hb-text-night" >
                    {{ rentChange.next_rent_change.schedule_rent | formatMoney }} on 
                    {{ rentChange.next_rent_change.schedule_date | formatDateCustom('MMM DD, YYYY') }}
                  </div>
                  <div class="pb-1 hb-font-caption" v-if="rentChange.next_rent_change.rent_plan_name">
                    Affected Rent Plan:
                    <span>
                      {{ rentChange.next_rent_change.rent_plan_name }}
                    </span>
                  </div>
                  <div class="pb-1 hb-font-caption" v-if="rentChange.next_rent_change.rent_change && rentChange.next_rent_change.rent_change_amt">
                    Rent Change: 
                    <span>
                      {{ formatMoney(rentChange.next_rent_change.rent_change_amt) }} /
                      {{ rentChange.next_rent_change.rent_change }}%
                    </span>
                  </div>
                  <div class="hb-font-caption pb-1" v-if="rentChange.next_rent_change.notification.date">
                    Notification:
                    <span >
                      {{ rentChange.next_rent_change.notification.date | formatDateCustom('MMM DD, YYYY') }}
                    </span>
                  </div>
                  <div class="hb-font-caption pb-1" v-if="rentChange.rent_change_notice.id && rentChange.rent_change_notice.name">
                    Notice: 
                    <span>
                      <hb-link @click="downloadRentChangeNotice(rentChange.rent_change_notice.id)">{{ rentChange.rent_change_notice.name }}</hb-link>
                    </span>
                  </div>
                  <div class="hb-font-caption" v-if="rentChange.notice_status">
                    Notice Status: 
                    <span style="display: inline-block">
                      <hb-status-general :type="scheduleStatusType" :status="rentChange.notice_status"></hb-status-general>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="2" class="mx-0 mb-0 py-0 px-2 mt-n2">
                <hb-menu align-right bottom left options v-if="showMenu && !isAuctioned" >
                  <v-list >
                    <v-list-item 
                      v-if="rentManagementPermissions.manage_rent_change_status && rentChange.rent_change_id"
                      @click="() => showSkipCancel=true"
                    >
                      Skip/Cancel Rent Change
                    </v-list-item>
                    <v-list-item v-if="showEditRent" @click="changeRent(true)">Edit Rent Change</v-list-item>
                  </v-list>
                </hb-menu>
              </v-col>
            </v-row>
            <skip-cancel-rent 
              :showSkipCancel="showSkipCancel" 
              :property="leaseWithTenants.Unit.property_id"
              :skipCancelData="leaseWithTenants.rent_management"
              :rent-change-id="getLeaseRentChangeId"
              @closeSkip = "showSkipCancel=false"
            />
          </hb-form>

          <hb-form 
            v-if="showTimeLine" 
            label="Rent Change Timeline"
          >
            <hb-time-line class="hb-timeline" />
          </hb-form>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="hb-expansion-panel" v-model="lease_settings" multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="px-4 hb-lease-dropdown-header" :class="{'hb-link' : lease_settings.includes(0)}">
          <span class="hb-font-body-medium hb-text-night">Lease</span>
        </v-expansion-panel-header>
        <v-divider v-if="lease_settings.includes(0)"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">

          <!-- Lease End -->
          <hb-form label="Lease End" v-if="leaseWithTenants.end_date">
            {{ leaseWithTenants.end_date | formatDateServices }}
          </hb-form>
          <!-- Promotion -->
          <PromotionsTenantProfile
            v-if="!nationalAccount && !lease?.BillingCycle?.id"
            label="promotion"
            :promotions.sync="promotionsData.promotions"
            :lease="lease"
            :contactId="contact.id"
          >
          </PromotionsTenantProfile>

          <!-- Discount -->
          <PromotionsTenantProfile
            :nationalAccount="nationalAccount"
            label="discount"
            :promotions.sync="promotionsData.discounts"
            :lease="lease"
            :contactId="contact.id"
          >
          </PromotionsTenantProfile>

          <!-- Protected Property -->
          <protected-property-items
              form-label="Protected Property"
              :lease-id="lease.id"
              :only-editable="false"
              :hide-actions="false"
              :show-form-label="true"
              :show-description="true"
              :is_closed="(is_closed || isAuctioned)"
          />

          <v-divider></v-divider>

          <!-- Tenants -->
          <hb-form label="Tenants" class="field-padding" :full="true">
            <v-row
              class="ma-0 pa-0 py-3 pl-4"
              :class="{ 'hb-link hb-form-editable col-cursor-pointer': tenant.contact_id !== $route.params.contact_id }"
              v-for="(tenant, i) in leaseWithTenants.Tenants"
              :key="'tenant' + i"
              @click=" tenant.contact_id !== $route.params.contact_id ? goContact(tenant) : '' "
            >
              {{ tenant.Contact.first | capitalize }}
              {{ tenant.Contact.middle | capitalize }}
              {{ tenant.Contact.last | capitalize }}
            </v-row>
          </hb-form>

          <!-- Vehicle -->
          <hb-form label="Vehicle" class="field-padding" :full="true">
            <span :class="{ 'hb-form-editable': !(is_closed || isAuctioned) }" >
              <v-row
                class="ma-0 pa-0"
                @mouseover="hovering.vehicle = true"
                @mouseleave="hovering.vehicle = false"
              >
                <v-col
                  cols="12"
                  class="ma-0 px-4 pt-1 pb-0"
                  @click="!(is_closed || isAuctioned) ? (addEditVehicle = true) : ''"
                >
                  <div v-if="selectedVehicle.id">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="10" class="ma-0 pa-0">
                        <div class="pa-0 ma-0 pt-2 py-4">
                          <span class="font-weight-medium">{{ getSelectedVehicleType }}:</span> {{ getSelectedVehicle }}
                        </div>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0 pt-0" align="right">

                        <hb-menu
                          v-if=" !(is_closed || isAuctioned)"
                          options
                          align-right
                        >
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in menuItems"
                              :key="index"
                              @mouseover="hoverItem = index"
                              @mouseleave="hoverItem = -1"
                              :class="{ 'hb-primary-lighter' : hoverItem === index }"
                              :ripple="false"
                              @click="handleVehicleItemClick(item)"
                            >
                              {{ item.label }}
                            </v-list-item>
                          </v-list>
                        </hb-menu>

                      </v-col>
                      <hb-modal
                        size="medium"
                        v-model="confirmRemoveModal"
                        title="Remove Vehicle"
                        confirmation
                      >
                        <template v-slot:content
                            >
                            <div class="px-6 py-4">
                              Are you sure you want to remove the following vehicle?
                              <li class="pt-3 pl-3">
                                <span>{{ getSelectedVehicle }}</span>
                              </li>
                            </div>
                        </template>
                        <template v-slot:actions>
                            <hb-btn color="destructive" @click="removeVehicle()">Remove</hb-btn>
                        </template>
                      </hb-modal>
                    </v-row>
                  </div>
                  <div v-else class="pt-2 pb-4">
                    <span v-if="!(is_closed || isAuctioned)">
                      <a>Click to Add</a>
                    </span>
                    <span v-else>--</span>
                  </div>
                </v-col>
              </v-row>
            </span>
          </hb-form>

          <!-- Stored Contents -->
          <stored-contents
              form-label="Stored Contents"
              :lease-id="lease.id"
              :only-editable="false"
              :hide-actions="false"
              :show-description="true"
              :show-form-label="true"
              :is_closed="(is_closed || isAuctioned)"
            />
          <v-divider></v-divider>

          <!-- Additiona Contact -->
          <hb-form label="Additional Contact">
            <additional-contact :lease="lease" :nationalAccount="nationalAccount" :contact="contact" />
          </hb-form>

          <!-- Deny Online Payments -->
          <deny-online-payments 
            :lease="leaseWithTenants" 
            :is_closed="(is_closed || isAuctioned)"
            @refreshData="refreshLeaseData"
          />

          <!-- Deny Electronic-Payments -->
          <deny-electronic-payments
            :lease="leaseWithTenants" 
            :is_closed="(is_closed || isAuctioned)"
            @refreshData="refreshLeaseData"
          />
          <!-- <v-divider></v-divider> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="hb-expansion-panel" v-model="billing_settings" multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="px-4 hb-lease-dropdown-header" :class="{'hb-link' : billing_settings.includes(0)}" v-slot="{ open }">
          <v-row class="ma-0 pa-0">
            <v-col cols="9" class="pa-0 ma-0 d-flex align-center hb-expansion-panel-header-left-col-wrapper">
              <span class="hb-font-body-medium hb-text-night">Billing</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="ma-0 py-0 pl-0 pr-2 d-flex align-center justify-end">
              <v-hover v-slot:default="{ hover }">
                <span class="billing-btn-wrapper">
                  <v-btn v-if="open && allowedResetToDefault" small :class="['billing-button', hover? 'billing-button-hover' : '']" depressed :ripple="false" :loading="billingBtnLoading" :disabled="billingBtnLoading || is_closed || isAuctioned || !hasPermission('reset_lease_billing_settings')" @click.stop="resetLeaseToBillingProfile(lease)">
                    <span class="font-weight-regular billing-button-text">Reset to Defaults</span>
                  </v-btn>
                </span>
              </v-hover>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-divider v-if="billing_settings.includes(0)"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">

          <!-- Payment Cycle -->
          <payment-cycle
            v-if="isPaymentCycleAllowed && paymentCycleOptions && paymentCycleOptions.length"
            :lease="lease"
            :payment_cycles="paymentCycles"
          />

          <hb-form 
            label="Billing Cycle"
            :empty="false"
            :full="true"
            :active.sync="active"
            :editable="false"
            v-if="isBillingCycleAllowed && lease?.BillingCycle?.value && lease.BillingCycleOptions?.length > 0"
          >
            <!-- Billing Cycle On Edit mode -->
            <template v-slot:edit>
              <div>
                {{ lease?.BillingCycle?.value | formatMonth }}
              </div>
            </template>
            <!-- Payment Cycle On Read mode -->
            <template v-slot:display>
              <div>
                {{ lease?.BillingCycle?.value | formatMonth }}
              </div>
            </template>
          </hb-form>
          <!-- Billing On -->
          <hb-form
            label="Billing On"
            :empty="false"
            :full="true"
            :editable="(is_closed || isAuctioned) ? false : true"
            :active.sync="active"
            @editStart="editStart('billing')"
            @save="save"
            @cancel="cancel"
          >
            <!-- Billing On Editable mode -->
            <template v-slot:edit>
              <div>
                <span @click="showNotificationModal('edit_bill_day')">
                  <v-select
                    :items="daysOfMonth"
                    v-model="lease.bill_day"
                    @change="changeBillDay"
                    class="pa-0 ma-0"
                    id="bill_day"
                    name="bill_day"
                    hide-details
                    :disabled="!hasPermission('edit_bill_day_tenant_profile')"
                  >
                    <template v-slot:selection="{ item, index }">
                      {{ item | dayEnding }} of the month
                    </template>
                    <template v-slot:item="{ item, index }">
                      {{ item | dayEnding }}
                    </template>
                  </v-select>
                  <div v-if="prorate_invoice.lease_id">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6" class="mx-0 px-0">Next Rent Due</v-col>
                      <v-col cols="6" class="mx-0 px-0 text-right">{{
                        prorate_invoice.due | formatLocalShortDate
                      }}</v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6" class="ma-0 pa-0">Prorate Days</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >{{ prorate_days }} days</v-col
                      >
                    </v-row>
                    <v-row
                      class="ma-0 pa-0 pt-3"
                      v-for="(lines, i) in prorate_invoice.InvoiceLines"
                      :key="'invoiceLine' + i"
                    >
                      <v-col
                        cols="6"
                        class="ma-0 pa-0"
                        v-if="lines.Service.name == 'Rent'"
                        >Prorated {{ lines.Service.name }}</v-col
                      >
                      <v-col cols="6" class="ma-0 pa-0" v-else>{{
                        lines.Service.name
                      }}</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >{{ (lines.cost * lines.qty) | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 pt-3">
                      <v-col cols="6" class="ma-0 pa-0">Rental Tax</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >{{ prorate_invoice.total_tax | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 pt-3">
                      <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >({{ prorate_invoice.discounts | formatMoney }})
                      </v-col>
                    </v-row>
                    <v-row class="mx-0 px-0 py-3 font-weight-medium">
                      <v-col cols="6" class="ma-0 pa-0">Balance Due</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >{{ prorate_invoice.balance | formatMoney }}
                      </v-col>
                    </v-row>
                  </div>
                </span>
                <hb-checkbox
                    class="pa-0 ma-0 mt-2 billing-on-item"
                    hide-details
                    id="consecutive_pay"
                    name="consecutive_pay"
                    v-model.lazy="should_email_monthly_invoice"
                >
                  <span slot="label">
                    Email tenant monthly invoice
                  </span>
                </hb-checkbox>
              </div>
            </template>
            <!-- Billing On Read mode -->
            <template v-slot:display>
              <div>
                {{ $options.filters.dayEnding(lease.bill_day) }} of month
                <br />
                <!-- {{ should_email_monthly_invoice ? 'Email Monthly Invoice' : 'Not Emailing Monthly Invoice' }} -->

                <hb-checkbox
                  class="ml-n1 my-0 mt-2 pl-1 billing-on-item"
                  disabled
                  flat
                  hide-details
                  single-line
                  solo
                  v-model.lazy="should_email_monthly_invoice"
                >
                  <span slot="label">
                    Email tenant monthly invoice
                  </span>
                </hb-checkbox>

              </div>
            </template>
          </hb-form>

          <!-- AutoPay -->
          <hb-form
            v-if="!(is_closed || isAuctioned)"
            label="AutoPay"
            :full="true"
            :editable="(is_closed || isAuctioned || !this.hasPermission('edit_autopay_in_tenant_profile') || denyAutoPay) ? false : true"
            :active.sync="active"
            :divider="false"
            @editStart="editStart('autopay')"
            @save="save"
            @cancel="cancel"
            >
          <!-- AutoPay Payment Methods -->
          <template v-slot:before >
              <OverviewLeasePaymentMethods
                :autoPaymentMethods="autoPaymentMethods"
                :paymentMethods="payment_methods"
                :is_closed="(is_closed || isAuctioned)"
                :unit="unit"
                :property_id="unit.property_id"
                :contact="contact"
                :deny_auto_pay="denyAutoPay"
                @refreshData="fetchPaymentMethods"
                @setToAutoPay="setToAutoPay"
                @removeAutoPay="removeAutoPay"
                class="mx-n4"
                style="margin-top:-10px;"
              ></OverviewLeasePaymentMethods>
          </template>

            <!-- AutoPay Editable mode -->
            <template v-slot:edit>
              <div class="mt-n2">
                <v-row no-gutters class="mx-0 pa-0">
                  <v-col cols="6" class="mb-0 mx-0 pa-0" style="margin-top: 7px; max-width: 7.7em;">
                    AutoPay will run
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0 px-2">
                    <hb-select
                      :items="autopayDays"
                      placeholder="Select"
                      id="auto_pay_after_billing_date"
                      name="auto_pay_after_billing_date"
                      data-vv-as="days"
                      data-vv-scope="info"
                      v-validate="'required'"
                      v-model="lease.auto_pay_after_billing_date"
                      :clearable="false"
                      class="mt-1"
                    >
                      <template v-slot:selection="data">
                        <span v-if="data.item == 1" class="pb-1">{{ data.item }}-Delinquent
                        </span>
                        <span v-else class="pb-1">{{ data.item }}</span>
                      </template>
                      <template v-slot:item="data">
                        <span v-if="data.item == 1"
                          >{{ data.item }}-Delinquent
                        </span>
                        <span v-else>{{ data.item }}</span>
                      </template>
                    </hb-select>
                  </v-col>
                  <v-col cols="12" class="ma-0 pt-2 pb-0 px-0">
                    days after the billing date.
                    <div v-if="autoPaymentMethodActivity?.activity">
                      <div v-if="autoPaymentMethodActivity" class="text-body-2 hb-text-light">
                        Last {{ autoPaymentMethodActivity?.activity == "delete" ? "Deleted" : "Added" }} By: {{ autoPaymentMethodActivity?.activity_by }} @ {{ autoPaymentMethodActivity?.activity_at | formatDate }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </template>
            <!-- AutoPay Read mode -->
            <template v-slot:display>
              <v-row class="pa-0 ma-0" @click="showNotificationModal('edit_autopay')">
                <v-col class="pa-0" style="margin: -1px 0 2px 0;" cols="12">
                  AutoPay will run
                  {{
                    lease.auto_pay_after_billing_date == 1
                      ? lease.auto_pay_after_billing_date + "-Delinquent"
                      : lease.auto_pay_after_billing_date
                  }}
                  days after the billing date.
                  <div v-if="autoPaymentMethodActivity?.activity" class="text-body-2 hb-text-light">
                    Last {{ autoPaymentMethodActivity?.activity == "delete" ? "Deleted" : "Added" }} By: {{ autoPaymentMethodActivity?.activity_by }} @ {{ autoPaymentMethodActivity?.activity_at | formatDate }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </hb-form>

          <v-divider></v-divider>
          <!-- Rent Tax Exempt -->
          <span @click="showNotificationModal('no_tax_rent')">
            <rent-tax-exempt-items
                :lease="lease"
                form-label="Rent Tax Exempt"
                :is_closed="(is_closed || isAuctioned)"
                @cancel="cancel"
            />
          </span>
          <v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="hb-expansion-panel" v-if="leaseWithTenants.Unit.lock !== null && hasPermission('davinci_lock_info')" v-model="digital_footprints_settings" multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="px-4 hb-lease-dropdown-header" :class="{'hb-link' : digital_footprints_settings.includes(0)}">
          <span class="hb-font-body-medium hb-text-night">Digital Footprint</span>
        </v-expansion-panel-header>
        <v-divider v-if="digital_footprints_settings.includes(0)"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">
          <!-- DaVinci Locks -->
          <hb-form class="hb-text-night" :full="true" v-if="leaseWithTenants.Unit.lock && leaseWithTenants.Unit.lock !== null && hasPermission('davinci_lock_info')" label="DaVinci Locks">
            <v-row no-gutters class="mb-0" v-for="(lock, i) in leaseWithTenants.Unit.lock" :key="'lock' + i">
              <v-col class="pt-0 pb-0 mb-0" cols="12">
                <div class="lock-details">
                  
                  <div>
                    <div class="hb-font-caption">
                      Serial Number
                    </div>
                    <span class="pb-2">
                      {{ lock.serial_no !== null && lock.serial_no !== '' ? lock.serial_no : 'Not Available' }}
                    </span>
                  </div>

                  <div class="mt-2">
                    <div class="hb-font-caption">
                    Combination
                    </div>
                    <span class="pb-2">
                      {{ lock.status == 1006 && !hasPermission('view_tenant_lock') ? '****' : lock.lock_code }}
                    </span>
                  </div>

                  <HbStatusGeneral
                  class = "mt-2"
                  :type= "getColorIconForStatus(lock.status).type"
                  :icon= "getColorIconForStatus(lock.status).icon"
                  :status= "getTooltipText(lock.status).status_code_value"
                  tooltip-header=""
                  :tooltip-body="getTooltipText(lock.status).description"
                  />

                </div>
                <v-divider v-if="i !== leaseWithTenants.Unit.lock.length - 1" class = "mt-2 mb-2"></v-divider>
              </v-col>
            </v-row>
          </hb-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels class="hb-expansion-panel" v-model="services_settings" multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="px-4 hb-lease-dropdown-header" :class="{'hb-link' : services_settings.includes(0)}">
          <span class="hb-font-body-medium hb-text-night">Services</span>
        </v-expansion-panel-header>
        <v-divider v-if="services_settings.includes(0)"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">
          <!-- Insurance / Protection Plan -->
          <hb-form label="Insurance / Protection Plan">
            <insurance-info :lease="lease" :contact="contact" />
          </hb-form>

          <!-- Services -->
          <hb-form label="Services">
            <services-info :lease="lease" :contact="contact" />
          </hb-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider v-if="!services_settings.includes(0)"></v-divider>

    <exempt-rent-change 
      ref="exemptRent"
      :property="leaseWithTenants.Unit.property_id"
      @updateExemptStatus="updateExemptStatus($event)"
      />

    <assign-rent-plan
      ref="changeRentPlanModal"
      :is-value-tiered="false"
      @selected="updateChangedPlan($event)"
    />

    <!-- Promotion & Discount edit -->
    <hb-modal
      title="Payment Details"
      size="large"
      v-model="showPaymentView"
      :footerOff="true"
      v-if="showPaymentView"
      show-help-link
    >
      <template v-slot:content>
        <edit-payment
          :hideHeader="true"
          :payment_id="payment_id"
          @close="showPaymentView = false"
        >
        </edit-payment>
      </template>
    </hb-modal>

    <add-edit-vehicle
      v-model="addEditVehicle"
      v-if="addEditVehicle"
      :selected="selectedVehicle"
      :lease_id="lease.id"
      :contact_id="contact.id"
      @close="addEditVehicleClose"
    ></add-edit-vehicle>

    <!-- Sensitive and Personal Information -->
    <!-- <v-divider></v-divider>
    <v-row class="ma-0 pa-0">
      <v-col cols="4" class="row-title pa-0 pl-4 lease-column-1">
        <div class="mt-2 mb-2">
          <div class="mt-2">
            Sensitive and Personal
            <br />
            Information
            <hb-tooltip>
              <template v-slot:body>
                <div style="max-width: 250px">
                  Storing paper documents or electronic data that contain
                  personal information relating to clients, customers or others
                  with whom you do business.
                </div>
              </template>
            </hb-tooltip>
          </div>
        </div>
      </v-col>
      <v-col v-if="lease.id" cols="8" class="ma-0 pa-0">
        <sensitive-information
          :lease-id="lease.id"
          :only-editable="false"
          :hide-actions="false"
          :initial-sensitive-info="lease.sensitive_info_stored"
        />
      </v-col>
    </v-row> -->
    <payments-notification-modal
	  v-model="notificationModal"
	  :notificationType="notificationModalType"
	/>

  <ResetToDefaultProfile
	  v-model="resetToDefaultProfile"
    v-if="resetToDefaultProfile"
	  :lease="lease"
    :contact="contact"
	/>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import { EventBus } from "../../EventBus.js";
import { calendarMixin } from "../../mixins/calendarMixin";
import api from "../../assets/api.js";
import PromotionsTenantProfile from "./PromotionsTenantProfile.vue";
import InsuranceInfo from "./Insurance/InsurancesInfo.vue";
import AddPaymentMethod from "../contacts/AddPaymentMethod.vue";
import ServicesInfo from "./ServicesInfo.vue";
import DenyOnlinePayments from "./DenyOnlinePayments.vue";

import RentTaxExemptItems from "./RentTaxExemptItems.vue";
import ProtectedPropertyItems from "./ProtectedPropertyItems.vue";
import StoredContents from "./StoredContents.vue";
import AddEditVehicle from "./AddEditVehicle.vue";
import EditPayment from '../payments/EditPayment.vue';
// import SensitiveInformation from "./SensitiveInformation.vue";
import VEHICLE from "@/constants/vehicle.js";
import OverviewLeasePaymentMethods from './OverviewLeasePaymentMethods.vue'
import SkipCancelRent from "../revenue_management/rent_management/SkipCancelRent.vue";
import RentChangeModal from "../revenue_management/rent_management/RentChangeModal.vue"
import AssignRentPlan from "../revenue_management/rent_management/AssignRentPlan.vue";
import ExemptRentChange from "../revenue_management/rent_management/ExemptRentChange.vue"
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { formatMoneyByCurrency,isEmpty } from '../../utils/common';
import PaymentCycle from './PaymentCycle.vue';
import AdditionalContact from './AdditionalContact.vue';
import PaymentsNotificationModal from '../payments/PaymentsNotificationModal.vue';
import ResetToDefaultProfile from '../contacts/BillingProfile/ResetToDefaultProfile.vue'



export default {
  name: "OverviewLeaseSettings",
  components: {
    PromotionsTenantProfile,
    InsuranceInfo,
    AddPaymentMethod,
    ServicesInfo,
    DenyOnlinePayments,
    ProtectedPropertyItems,
    RentTaxExemptItems,
    StoredContents,
    AddEditVehicle,
    EditPayment,
    ExemptRentChange,
    RentChangeModal,
    // HbTimeLine,
    // SensitiveInformation,
    OverviewLeasePaymentMethods,
    SkipCancelRent,
    AssignRentPlan,
    PaymentCycle,
    PaymentsNotificationModal,
    AdditionalContact,
    ResetToDefaultProfile
  },
  mixins: [ calendarMixin, notificationMixin ],
  props: [
    "unit",
    "leaseWithTenants",
    "lease",
    "invoice",
    "services",
    "contact",
    "payment_methods",
    "vehicleData",
    "autoPayData",
    "promotions",
    "nationalAccount"
  ],
  data() {
    return {
      showTimeLine: false,
      showSkipCancel: false,
      showManualRentChangeModal: false,
      isEdit: false,
      rentChangeData: {},
      timeLineText: "View Rent Change Timeline",
      active: "",
      activelyEditing: "",
      is_auto_pay_after_billing_date: false,
      bill_day: 1,
      prorate_invoice: [],
      show_take_payment_modal: false,
      prorate_days: 0,
      promotionsData: {},
      showPaymentView: false,
      payment_id: "",
      autoPaymentMethods: [],
      autoPaymentMethodActivity: {},
      hovering: {
        insurance: false,
        services: false,
        vehicle: false
      },
      addEditVehicle: false,
      selectedVehicle: {},
      vehicle_types: VEHICLE.TYPES,
      changed_fields: {},
      should_email_monthly_invoice: null,
      moveInInfo: [
        {
          label: "Value Tier",
          key: "value_tier",
          toolTip: "",
        },
        {
          label: "Sell Rate at Move-in",
          key: "sell_rate",
          toolTip: "",
          type: "money"
        },
        {
          label: "Offer Rate / Rental Rate",
          key: "offer_rent",
          toolTip: "",
          type: "money"
        },
        {
          label: "Variance (Rent/Sell)",
          key: "sell_variance",
          toolTip: "",
        },
        {
          label: "Attribution",
          key: "attribution",
          toolTip: "",
        },
      ],
      confirmRemoveModal: false,
      hoverItem: -1,
      menuItems: [
        {
          label: 'Change',
          action: 'addEditVehicle',
        },
        {
          label: 'Remove',
          action: 'confirmRemoveVehicle',
        }
      ],
      rmPermissions: [
        "exempt_rent_changes",
        "manage_rent_change_status",
        "manual_rent_changes",
        "assign_rent_plan"
      ],
      paymentCycleOptions: [],
      paymentCycles: [],
      notificationModal: false,
      billingBtnLoading: false,
      denyAutoPay: false,
      notificationModalType: '',
      resetToDefaultProfile: false,
      billing_settings: [],
      rent_settings: [],
      lease_settings: [],
      services_settings: [],
      digital_footprints_settings: [],
    };
  },
  filters: {
      formatDate(date){
          return moment(date).format('MMM DD, YYYY hh:mm A');
      }
  },

  async created() {
    if (this.lease && this.lease.decline_insurance == 1) {
      this.decline_insurance = true;
    }
    this.denyAutoPay = await this.shouldDenyAutoPay();
    this.bill_day = this.lease.bill_day;
    this.showPaymentView = false;
    this.selectedVehicle = this.vehicleData
    this.autoPaymentMethods = this.autoPayData.paymentMethods;
    this.autoPaymentMethodActivity = this.autoPayData.autoPaymentMethodActivity;
    this.promotionsData = this.promotions;
    this.setFieldsValues();

    EventBus.$on("activeEdit", this.setActiveEditName);
    EventBus.$on("showPaymentDone", this.showPayment);
    EventBus.$on("promotion_updated", this.fetchLeasePromotions);
    EventBus.$on("contact_updated", this.fetchLease);
    EventBus.$on("payment_method_deleted", this.paymentMethodRemoved);
    EventBus.$on("activeEdit", this.setActiveEditName);
    EventBus.$on("promotion_updated", this.fetchLeasePromotions);
    EventBus.$on("showPaymentDone", this.showPayment);
    if(this.isPaymentCycleAllowed){
      this.fetchLeasePaymentCycleOptions();
    }
  },

  destroyed() {
    EventBus.$off("activeEdit", this.setActiveEditName);
    EventBus.$off("showPaymentDone", this.showPayment);
    EventBus.$off("promotion_updated", this.fetchLeasePromotions);
    EventBus.$off("contact_updated", this.fetchLease);
    EventBus.$off("payment_method_deleted", this.paymentMethodRemoved);
    EventBus.$off("activeEdit", this.setActiveEditName);
  },

  computed: {
    ...mapGetters({
      getShouldEmailMonthlyInvoice: 'tenantProfile/getShouldEmailMonthlyInvoice',
      currentBillingProfile: 'billingProfileStore/currentBillingProfile',
      getError: 'tenantProfile/getError',
      hasPermission: "authenticationStore/rolePermission",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      isPaymentCycleAllowed: 'propertiesStore/isPaymentCycleAllowed',
      isBillingCycleAllowed: 'propertiesStore/isBillingCycleAllowed'
    }),
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),

    assignRentPlanText() {
      return this.rentChange.rent_plan_id ? `Change Rent Plan`: `Assign Rent Plan`
    },

    showAssignRentPlan() {
      return this.getPropertyRentSettings?.automation_enabled_by_admin &&
        this.hasInternalRentEngine && 
        this.rentManagementPermissions?.assign_rent_plan &&
        this.rentChange?.rent_plan_status !== 'exempt' &&
        this.rentPlans?.length 
    },

    showMenu() {
      return Object.values(this.rentManagementPermissions).some(permission => permission === true);
    },

    showChangeRent() {
      return this.getPropertyRentSettings?.active  && 
        this.rentManagementPermissions?.manual_rent_changes  && 
        !this.is_closed &&
        !this.isAuctioned
    },

    scheduleStatusType() {
      return this.rentChange?.notice_status === "scheduled" ? "guidance" : ""
    },

    showEditRent() {
       return this.showChangeRent && 
        this.rentChange?.rent_change_id
    },

    rentManagementPermissions() {
      let permission = {}
      this.rmPermissions.forEach((label => permission[label] = this.hasPermission(label)))
      return permission
    },

    getTenantId() {
      return this.leaseWithTenants?.Tenants[0]?.contact_id
    },

    getLeaseRentChangeId() {
      return this.leaseWithTenants?.rent_management?.rent_change_id ?? ''
    },

    getTenantName() {
      let contact = this.leaseWithTenants?.Tenants[0]?.Contact
      return `${contact?.first} ${contact?.last}`
    },

    rentChange() {
      return this.leaseWithTenants?.rent_management
    },

    rentLabel() {
      return this.leaseWithTenants.rent_management?.last_rent_change?.rent_change ? "Current Rent:" : "";
    },

    ids() {
      return this.isEdit ? this.rentChange.rent_change_id : this.lease.id
    },

    rentChangeTitle() {
      return this.isEdit ? "Edit Rent Change": "Manual Rent Change"
    },

    notificationDateLabel() {
      const notificationDate = moment(this.rentChange.next_rent_change.notification_date);
      const label = moment().isAfter(notificationDate) ? 'sent': 'sends';
      return `Notification ${label} on`;
    },

    is_closed() {
      return (
        moment(this.lease.end_date).startOf("day") <= moment().startOf("day")
      )
    },

    isAuctioned(){
      return ( ['auction_payment','move_out'].includes(this.lease.auction_status))
    },

    hasMoveInData() {
      return this.leaseWithTenants && this.leaseWithTenants.move_in && Object.keys(this.leaseWithTenants.move_in).length ? true : false
    },

    autopayDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i);
      return days;
    },

    getSelectedVehicleType() {
      return this.vehicle_types.find(vt => vt.id == this.selectedVehicle.type)
        .name;
    },

    getSelectedVehicle() {
      if (
        this.selectedVehicle.year ||
        this.selectedVehicle.make ||
        this.selectedVehicle.model ||
        this.selectedVehicle.color
      ) {
        return (
          (this.selectedVehicle.year ? this.selectedVehicle.year + " " : "") +
          (this.selectedVehicle.make ? this.selectedVehicle.make + " " : "") +
          (this.selectedVehicle.model ? this.selectedVehicle.model + " " : "") +
          (this.selectedVehicle.color ? this.selectedVehicle.color + " " : "")
        );
      } else {
        return "Add Vehicle Information";
      }
    },
      
    getMoveInData() {
      let moveinDetails = [];
      if (this.leaseWithTenants?.move_in) {
        if (Object.keys(this.leaseWithTenants?.move_in).length) {
          this.moveInInfo.forEach((mData) => {
            moveinDetails.push({
              label: mData.label ?? "",
              value: this.populateVarianceTokens(mData ?? {}),
              toolTip: mData.toolTip ?? "",
              type: mData.type ?? "",
              key: mData.key ?? "",
            });
          });
        }
      }
      return moveinDetails;
    },
    
    getExemptActionlabel() {
      return this.rentChange?.rent_plan_status === 'exempt'
        ? 'Remove Rent Change Exemption'
        : 'Exempt from Rent Changes'
    },
    allowedResetToDefault(){
      let billingProfile = this.currentBillingProfile(this.contact.id, this.lease?.Unit?.property_id);
      return (billingProfile.bill_day !== this.lease?.bill_day
                || Boolean(billingProfile.send_invoice) !== this.should_email_monthly_invoice
                || billingProfile.payment_cycle !== this.lease?.payment_cycle
                || billingProfile.tax_exempt !== this.lease?.tax_exempt
                || billingProfile.tax_exempt_description !== this.lease?.tax_exempt_description
                || (billingProfile.auto_pay_after_billing_date !== this.lease?.auto_pay_after_billing_date && !this.lease.deny_payments)
                || (billingProfile?.PaymentMethod[0]?.id !== this.autoPaymentMethods[0]?.id && !this.lease.deny_payments))
              && this.hasPermission('reset_lease_billing_settings')
              && !this.is_closed
              && !this.isAuctioned;
    }
  },

  methods: {
    ...mapActions({
      updateShouldEmailMonthlyInvoice: 'tenantProfile/updateShouldEmailMonthlyInvoice',
      fetchPropertyRentSettings: "revManStore/fetchCurrentPropertyRentConfiguration",
      setDefaultBillingProfileForLease: 'billingProfileStore/setDefaultBillingProfileForLease',
    }),
    ...mapMutations({
      resetPayments: "paymentsStore/resetPayments",
      setPaymentSource: "paymentsStore/setPaymentSource",
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseProperty: "paymentsStore/setLeaseProperty",
      pushInvoices: "paymentsStore/pushInvoices",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setShouldEmailMonthlyInvoice: 'tenantProfile/setShouldEmailMonthlyInvoice'
    }),

    async resetLeaseToBillingProfile(lease){

      this.billingBtnLoading = true;
      if(!this.hasPermission('reset_lease_billing_settings')){
        this.showMessageNotification({type: 'error', description: 'You do not have permission to reset billing settings.'});
      }else{
        this.resetToDefaultProfile = true;
      }
      this.billingBtnLoading = false;
    },
    /**
     * Function to open change rent plans modal
     */
    async changeRentPlan() {
      await this.$refs.changeRentPlanModal.show({
        rentPlans: this.rentPlans,
        currentPlanId: this.rentChange?.rent_plan_id ?? "",
      })
    },

    /**
     * Function for changing selected rent plan (API call)
     */
    async updateChangedPlan(data) {
      let body = {
        rent_plan_id: data.selectedPlanId,
      };
      await api
        .put(
          this,
          api.getPropertyRentManagementUrl(this.leaseWithTenants.Unit.property_id) +
            `leases/${this.lease.id}/plan`,
          body
        )
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: "You have successfully updated Rent Plan.",
          });
          this.fetchLease();
          EventBus.$emit("tenantRentManagementEvent", {
            event: "refreshTable",
          });
          EventBus.$emit("rentChangeQueueEvents", "refreshTable");
          EventBus.$emit("reviewRentChangeEvents", {
            event: "refreshTable",
            data: "",
          });
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: "Rent Plan update failed.",
          });
        });
    },

    isValidValue(value) {
      return !isEmpty(value);
    },

    formatMoney(cost) {
      return !isNaN(parseFloat(cost)) ? formatMoneyByCurrency(parseFloat(cost)) :  ''
    },
    /**
     * 
     * returns true if value is numeric string, 0, number
     * returns false if value is string, null, undefined
     */
    isValidDigit(val) { 
      return (val || val === 0) && !isNaN(val)
    },

    populateVarianceTokens({ key } = {}) {
      let {
        offer_set_variance,
        offer_set_variance_percent,
        offer_sell_variance,
        offer_sell_variance_percent,
      } = this.leaseWithTenants?.move_in ?? {};
      if (this.isVariance(key)) {
        if (
          "offer_set_variance".includes(key) &&
          this.isValidDigit(offer_set_variance) && 
          this.isValidDigit(offer_set_variance_percent)
        ) {
          return `${this.formatMoney(
            offer_set_variance
          )} / ${offer_set_variance_percent}%`;
        } else if (this.isValidDigit(offer_sell_variance) && this.isValidDigit(offer_sell_variance_percent)) {
          return `${this.formatMoney(
            offer_sell_variance
          )} / ${offer_sell_variance_percent}%`;
        }
        return "";
      }
      return this.leaseWithTenants?.move_in[key];
    },

    isVariance(key) {
      const variances = ['offer_set_variance', 'offer_sell_variance']
      return variances.some(e => e.includes(key))
    },

    setActiveEditName(name) {
      this.is_auto_pay_after_billing_date = name == "autopay" ? true : false;
      this.activelyEditing = name;
      if (name == "") {
        this.resetPayments();
        // this.lease.bill_day = this.bill_day;
        this.prorate_invoice = [];
      }
    },

    resetBillingOnField() {
      this.resetPayments();
      this.lease.bill_day = this.bill_day;
      this.show_take_payment_modal = false;
      this.activelyEditing = "";
      this.prorate_invoice = [];
      this.prorate_days = 0;
      EventBus.$emit("reload_tenant_profile_leases");
    },
    async changeBillDay() {
      try {
        let data = { dryrun: true, new_bill_day: this.lease.bill_day };
        let r = await api.post(
          this,
          `${api.LEASES}${this.lease.id}/change-bill-day`,
          data
        );
        this.resetPayments();
        this.prorate_invoice = [];
        this.prorate_days = 0;
        if (r.invoices.change_bill_day_invoices.length) {
          if (r.invoices.change_bill_day_invoices[0].balance > 0) {
            this.prorate_invoice = r.invoices.change_bill_day_invoices[0];
            this.setPaymentSource({
              paymentSource: "BILL_DAY_CHANGE"
            });
            this.createLeaseStructure({
              invoices: r.invoices.change_bill_day_invoices,
              lease: Object.assign({}, this.lease),
              unit: this.lease.Unit,
              updateInvoice: false
            });
            this.setLeaseProperty({
              leaseIndex: 0,
              property: "OpenInvoices",
              propertyValue: r.invoices.open_invoices
            });
            this.pushInvoices({
              invoices: [
                ...r.invoices.open_invoices,
                ...r.invoices.change_bill_day_invoices
              ]
            });
            this.setLeaseBilledMonths({
              leaseIndex: 0,
              billedMonths: 0
            });
            this.prorate_days =
              this.dateDifferenceInDays(
                moment(this.prorate_invoice.period_start, "YYYY-MM-DD").startOf(
                  "day"
                ),
                moment(this.prorate_invoice.period_end, "YYYY-MM-DD").startOf(
                  "day"
                )
              ) + 1;
          }
        }
      } catch (err) {}
    },

    async fetchLeasePromotions() {
      let r = await api.get(
        this,
        api.LEASES + this.lease.id + "/all-discounts"
      );
      this.promotionsData = r;
    },

    showPayment(id) {
      this.payment_id = id;
      this.showPaymentView = true;
    },

    /**
     * Function to open rent exempt modal
     */
     async showExemptModal() {
      let data = {
          lease_id: this.lease.id, 
          tenant_name: this.getTenantName, 
          lease_rent_plan_status: this.rentChange?.rent_plan_status 
        }
      await this.$refs.exemptRent.show(
        data, 
        this.lease.id,
        "",
        this.rentChange?.rent_plan_status
      )
    },

    /**
     * Function to exempt rent change plan for tenant (API call)
     */

    async updateExemptStatus(data) {
      this.showMessageNotification({
        type: data.type,
        description: data.description,
      });
      if (data.type === "success") {
        this.fetchLease()
        EventBus.$emit("tenantRentManagementEvent", { event: 'refreshTable' })
        EventBus.$emit("reviewRentChangeEvents", {
          event: "refreshTable",
          data: "",
        });
        EventBus.$emit("rentChangeQueueEvents", "refreshTable");
      }
    },

    async downloadRentChangeNotice(id){
      try {
          const downloadedFile = await api.put(this, api.UPLOADS + id + '/download?setDestination=true');
          const fileContent = downloadedFile.file;
          let buffer = Buffer.from(fileContent.Body.data);
          let blob = new Blob([buffer], { type: downloadedFile.mimetype });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.rentChange.rent_change_notice.name} - ${this.getTenantName}` ||  downloadedFile.name;
          link.click();
      } catch(err) {
          this.showMessageNotification({ description: err });
      }
    },
    
    async fetchLeaseAutoPaymentMethods() {
      let r = await api.get(
        this,
        api.LEASES + this.lease.id + "/payment-methods/autopay"
      );
      this.autoPaymentMethods = r.paymentMethods;
      this.autoPaymentMethodActivity = r.autoPaymentMethodActivity;
    },

    async fetchPaymentMethods(pm) {
      EventBus.$emit("payment_methods_updated");
      await this.setToAutoPay(pm);
      this.fetchLeaseAutoPaymentMethods();
    },

    async refreshLeaseData(deny){
      await this.fetchLeaseAutoPaymentMethods();
      this.denyAutoPay = deny;
      EventBus.$emit('reload_tenant_profile_leases');
    },

    paymentMethodRemoved(id) {
      if (
        this.autoPaymentMethods &&
        this.autoPaymentMethods[0] &&
        id &&
        id === this.autoPaymentMethods[0].id
      ) {
        this.autoPaymentMethods = [];
      }
    },

    async setToAutoPay(paymentMethod) {
      try {
        let r = await api.put(
          this,
          api.LEASES +
            this.lease.id +
            "/payment-methods/" +
            paymentMethod.id +
            "/autopay"
        );
        this.showMessageNotification({ type: 'success', description: 'AutoPay payment method has been successfully added.' });
        this.fetchLeaseAutoPaymentMethods();
      } catch (err) {
        this.showMessageNotification({ description: err });
      }
    },

    async removeAutoPay(paymentMethod, lease) {
      try {
        let r = await api.delete(
          this,
          api.LEASES + this.lease.id + "/payment-methods/autopay"
        );
        this.showMessageNotification({ type: 'success', description: 'AutoPay payment method has been successfully removed.' });
        await this.fetchLeaseAutoPaymentMethods();
      } catch (err) {
        this.showMessageNotification({ description: err });
      }
    },

    addEditVehicleClose() {
      this.addEditVehicle = false;
      this.getVehicleData();
    },

    async getVehicleData() {
      let r = await api.get(this, api.LEASES + this.lease.id + "/vehicles");
      if (!r.vehicles.id) {
        this.selectedVehicle = {};
        return;
      }
      this.selectedVehicle = r.vehicles;
    },

    goContact(tenant) {
      let path = `/contacts/${tenant.contact_id}`;
      if(this.unit?.property_id) path += `?property_id=${this.unit?.property_id}`
      this.$router.push(path);
    },

    editStart(name) {
      EventBus.$emit('activeEdit', name);
    },

    async updateSendMonthlyInvoice() {
      await this.updateShouldEmailMonthlyInvoice({
        lease_id: this.lease.id,
        should_email_monthly_invoice: this.should_email_monthly_invoice,
        lease_status: 'current'
      });

      if(!this.getError.length) {
        this.should_email_monthly_invoice = Boolean(this.getShouldEmailMonthlyInvoice({
          lease_id: this.lease.id,
          lease_status: 'current'
        }));

        this.showMessageNotification({ type: 'success', description: 'Email Monthly Invoice has been updated successfully' });
        this.active = '';
        delete this.changed_fields['should_email_monthly_invoice'];
      }      
    },

    async save() {
      if(this.changed_fields['should_email_monthly_invoice']) {
        await this.updateSendMonthlyInvoice();
      };

      EventBus.$emit('saveData', this.prorate_invoice);
      this.active = '';
    },

    setFieldsValues() {
      this.should_email_monthly_invoice = Boolean(this.getShouldEmailMonthlyInvoice({
        lease_id: this.lease.id,
        lease_status: 'current'
      }));
    },
    async shouldDenyAutoPay(){
      try{
        let res = await api.get(this, `${api.LEASES}/${this.leaseWithTenants.id}/deny_payments/history`);
        let history = res.history?.[0];
        if (history){
          if (history?.deny_payments === 0){
            return false;
          }
          return  history?.modified_source !== 'Delinquency' ;
        }
        return false;
      }
      catch(err){
        this.showMessageNotification({ err });
      }
    },

    cancel() {
      this.setFieldsValues();
      EventBus.$emit('activeEdit', '');
      this.active = null;
      EventBus.$emit('reload_tenant_profile_leases');
    },

    fetchLease() {
      this.$emit('fetchLease');
    },
    async removeVehicle() {
      let data = {
        id: this.selectedVehicle.id,
        type: this.selectedVehicle.type,
        active: 0
      }
      await api.put(this, api.LEASES + this.lease.id + '/vehicles', data);
      this.getVehicleData();
      this.confirmRemoveModal = false;
    },
    handleVehicleItemClick(item) {
      if (item.action === 'addEditVehicle') {
        this.addEditVehicle = true;
      } else if (item.action === 'confirmRemoveVehicle') {
        this.confirmRemoveModal = true;
      }
    },

    changeRent(editRent) {
      this.isEdit = editRent;
      this.rentChangeData =  {
        lastChange: this.leaseWithTenants.last_rent_change ?? '',
        newRent: editRent ? this.rentChange?.next_rent_change?.schedule_rent ?? '': '',
        deploymentDate: editRent ? this.rentChange?.next_rent_change?.schedule_date ?? '': '', 
        affectAutomaticRent: editRent ? !!this.rentChange?.next_rent_change?.affect_timeline : false,
        sellRate:  this.leaseWithTenants?.Unit?.price ?? '',
        setRate: this.leaseWithTenants?.Unit?.set_rate ?? '',
        variance: this.rentChange?.rent_sell_variance?.amount ?? '',
        variancePrct: this.rentChange?.rent_sell_variance?.percentage ?? ''
      }
      this.showManualRentChangeModal = true;
    },

    closeRentChangeModal() {
      this.showManualRentChangeModal = false;
      if (this.isEdit) {
        this.isEdit = false;
      }
    },
    
    async fetchLeasePaymentCycleOptions(){
      let r = await api.get(this,`${api.LEASES}${this.lease.id}/payment-cycles`);
      this.paymentCycleOptions = r.lease?.PaymentCycleOptions || [];
      this.paymentCycles = this.paymentCycleOptions.map(pco => pco.label) || [];
    },

    showNotificationModal(key){
      this.notificationModalType = key;
      if (key === 'edit_bill_day' && !this.hasPermission('edit_bill_day_tenant_profile')) {     
        this.notificationModal = true;
      }else if(key === 'edit_autopay' && !this.hasPermission('edit_autopay_in_tenant_profile')){      
      	this.notificationModal = true;
      }else if(key === 'no_tax_rent' && !this.hasPermission(key)){      
      	this.notificationModal = true;
      }
    },

    getColorIconForStatus(lock_status) {
      let type;
      let icon;
      
      if (lock_status === 1004 || lock_status === 1008) {
        type = "error";
        icon = 'mdi-alert';
      } else {
        type = "guidance";
        icon = 'mdi-check-circle'; 
      }

      return { type, icon };
      },

    getTooltipText(status) {
      let status_code_value;
      let description;

      if (status === 1006) {
        status_code_value = 'Tenant Using Lock';
        description = 'The lock has been given to a tenant and they are using it as their lock';

      } else if (status === 1004) {
        status_code_value = 'Assigned Overlock';
        description = 'The lock is securing to a delinquent unit';

      } else if (status === 1005) {
        status_code_value = 'Assigned Vacant';
        description = 'The lock is securing a vacant unit';

      } else if (status === 1008) {
        status_code_value = 'Assigned Auction';
        description = 'The lock is securing to a unit being auctioned';

      } else if (status === 1003) {
        status_code_value = 'Returned to Dropbox';
        description = 'The lock is assumed to have been returned to a dropbox or a manager at the facility';

      } else if (status === 1010) {
        status_code_value = 'On Dropbox';
        description = 'The lock is securing a dropbox';

      } else if (status === 1011) {
        status_code_value = 'Assigned Other';
        description = 'The lock is being used in another capacity not listed';

      } else if (status === 1012) {
        status_code_value = 'Assigned Vendor';
        description = 'The lock is being used by a vendor';

      } else if (status === 1001) {
        status_code_value = 'Available';
        description = 'The lock is available and not currently being used';

      } else if (status === 1002) {
        status_code_value = 'Lost';
        description = 'The lock cannot be found';

      } else if (status === 1007) {
        status_code_value = 'Damaged';
        description = 'The lock is damaged in some way and cannot be used';

      } else if (status === 1009) {
        status_code_value = 'Unscannable';
        description = 'The lock is unscannable and is not being used';

      } else {
        status_code_value = 'NA';
        description = 'NA';
      }

      return { status_code_value, description };
    },
  },
  watch: {
    should_email_monthly_invoice(new_value, old_value) {
      if(old_value != null) this.changed_fields['should_email_monthly_invoice'] = 1;
    },
    getError(value) {
      if(!value?.length) return;
      this.showMessageNotification({ description: value });
    },
    lease() {
      this.fetchLease();
      this.fetchLeaseAutoPaymentMethods();
      this.fetchLeasePromotions();
      this.getVehicleData();
    },
    leaseWithTenants: {
      handler(val) {
        if(val?.Unit?.property_id) {
          this.fetchPropertyRentSettings(val?.Unit?.property_id)
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.hb-timeline {
  min-width: 400px !important;
  width: 385px !important;
}

.rent-details {
  color: #637381;
  div {
    margin-bottom: 0px !important;
    padding-bottom: 2px;
  }
  span {
    color: #101318;
  }
}

  .field-padding {
    ::v-deep .hb-aviary-form-padding-content {
      padding: 0px !important;
    }
  }
  
.lock-details {
  color: #637381;
  div {
    margin-bottom: 0px !important;
    padding-bottom: 2px;
  }
  span {
    color: #101318;
  }
}

.ti-widget {
  .ti-widget__header {
    padding: 25px 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .ti-widget__title {
      font-size: 1.2em;
      line-height: 1em;
      font-weight: bold;
      padding-top: 0px;
      padding-bottom: 5px;
    }
    .ti-widget__subtitle {
      opacity: 0.8;
      font-size: 0.9em;
      line-height: 1em;
      font-weight: normal;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
.gray-color {
  color: #637381;
}
.lease-column-1 {
  background: #f4f6f8;
}
.card-type {
  text-transform: uppercase;
  font-weight: 500;
}
.col-cursor-pointer {
  cursor: pointer;
}

.property-link {
  color: #306fb6;
  text-decoration: none;
}

.row-title {
  font-weight: 500;
  color: #101318;
}

.secondary-button {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}

.hb-lease-dropdown-header {
  min-height: 40px !important;
}

.variance-section {
  margin-top: -8px;
}
.last-section {
  margin-bottom: 0px !important;
}
.info-icon {
  margin-bottom: 9px !important;
}

button.v-expansion-panel-header.hb-link{
  background: #e0f5f5 !important;
}
</style>
<style lang="scss">
.form-body-tooltip {
  span {
    i {
      padding-bottom: 4px !important;
      padding-top: 1px !important;
    }
  }
}
.billing-on-item .v-label {
  margin-top: 0px !important;
}

.billing-btn-wrapper .billing-button .v-btn__loader .v-progress-circular.v-progress-circular--indeterminate {
    height: 20px !important;
    width: 20px !important;
    color: #00848E !important;
}

.billing-button {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}

.billing-button-text {
  font-size: 12px !important;
  color: #101318 !important;
}

.billing-button-hover {
  background: linear-gradient(180deg, #FAFBFC 0%, #EBEFF2 100%);
}
</style>
