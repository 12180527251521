<template>
    <div>
        <hb-data-table-header>
            <template v-slot:description>
                Add / Manage user groups.
            </template>
            <template v-slot:actions>
                <hb-btn small color="secondary" @click="showConfig()">Add New Group</hb-btn>
            </template>
        </hb-data-table-header>

        <v-card elevation="1">
            <v-data-table
                :headers="headers"
                :items="groups"
                disable-pagination
                hide-default-footer
                class="hb-data-table hb-data-table-cursor-on"
                @click:row="showConfig"
            >
                <template v-slot:item.name="{ item }">{{ item.name }}</template>
                <template v-slot:item.description="{ item }">{{ item.description }}</template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu options align-right>
                        <v-list>
                            <v-list-item @click="showConfig(item)">
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
            </v-data-table>
        </v-card>

        <hb-modal :title="title" v-model="addGroups">
            <template v-slot:content>
                <hb-form label="Name" required>
                    <HbTextField v-model="group.name"></HbTextField>
                </hb-form>
                <hb-form label="ObjectID" required>
                    <HbTextField v-model="group.object_id"></HbTextField>
                </hb-form>
                <hb-form label="Description" required>
                    <HbTextField v-model="group.description"></HbTextField>
                </hb-form>
                <hb-form label="Properties" required>
                    <hb-combobox
                        v-model="group.selected"
                        :items="propertyItems"
                        id="primary_properties"
                        select
                        item-value="property_id"
                        item-text="name"
                        return-object
                        multiple
                        @change="updateSelectedProperties"
                    >
                        <template v-slot:item="{ item, attrs }">
                            <v-list-item-action>
                                <v-icon v-if="attrs.inputValue">check_box</v-icon>
                                <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-if="item.number" :class="{'text--disabled': item.disabled}">{{ item.number }} - </span>
                                    <span :class="{'text--disabled': item.disabled}">{{ item.city }} - {{ item.name }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </hb-combobox>
                </hb-form>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="save">
                    <span v-if="group.id">Update Group</span>
                    <span v-else>Save</span>
                </hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';
import api from '../../../../assets/api';

export default {
    name: 'GroupManager',
    data() {
        return {
            groups: [],
            addGroups: false,
            headers: [
                { text: "Group Name", value: "name" },
                { text: "Description", value: "description" },
                { text: "", value: "actions", align: "right", sortable: false, width: 10 }
            ],
            title: '',
            group: {
                id: '',
                name: '',
                description: '',
                object_id: '',
                properties: [],
                selected: [] // This will hold the selected properties
            }
        }
    },
    created() {
        this.fetchGroups();
    },
    computed: {
        ...mapGetters({
            allPropertiesList: 'propertiesStore/properties',
        }),
        propertyItems() {
            return this.allPropertiesList.map(p => ({
                id: p.id,
                name: p.name,
                number: p.number,
                city: p.city,
                gds_id: p.gds_id,
                disabled: p.disabled,
            }));
        },
    },
    methods: {
        async fetchGroups() {
            await api.get(this, api.GET_SSOGROUPS).then(r => {
                this.groups = r.groups.filter(item => item.name?.toLowerCase());
            });
            console.log("Fetched groups : ", this.groups);
        },

        showConfig(c = {}) {
            // Reset the group object for a new or existing group
            this.group = {
                id: c.id || '',
                name: c.name || '',
                description: c.description || '',
                object_id: c.object_id || '',
                properties: c.properties || [],
                selected: [] // Reset selected properties
            };
            this.addGroups = true;

            // Set the title based on whether we're editing or adding
            this.title = this.group.id ? 'Edit Groups' : 'Add New Group';

            // Populate selected properties correctly
            if (this.group.properties) {
                this.group.selected = this.allPropertiesList.filter(p =>
                    this.group.properties.some(prop => prop.property_id === p.id)
                );
            }
        },

        updateSelectedProperties(selectedItems) {
            // Directly set the selected properties to the current selection
            this.group.selected = selectedItems; // This will now only contain the currently selected items
        },

        async save() {
            // Reset the properties to only include the selected ones
            this.group.properties = this.group.selected.map(item => ({
                property_id: item.property_id,
                // Include any other necessary fields from the selected item
            }));

            var data = { group: this.group };
            console.log("Data to save : ", data);
            // Ensure that the selected properties are included in the data
            if (this.group.id) {
                await api.put(this, api.GET_SSOGROUPS, data);
            } else {
                await api.post(this, api.GET_SSOGROUPS, data);
            }
            this.addGroups = false;
            await this.fetchGroups();
        },

        deleteItem(c) {
            this.selected = c;
            this.showDelete = true;
        },
    }
}
</script>