<template>
  <hb-modal v-model="dialog" :title="title" @close="closeForm">
    <template v-slot:subheader>
      {{ dialogDescription }}
    </template>
    <template v-slot:content>
      <v-form class="promo-form" ref="form">
        <hb-form
          label="Promotion/Discount"
          description="Choose Promotion or Discount."
          required
          full
        >
          <HbSelect
            v-model="form.label"
            :items="promoTypes"
            item-value="item"
            item-text="label"
            v-validate="'required'"
            data-vv-name="type"
            data-vv-as="Type"
            attach
            :disabled="hasPromoId"
            placeholder="Choose Promotion Type"
            :clearable="false"
            :error="errors.collect('type').length > 0"
          />
        </hb-form>
        <hb-form
          label="Name"
          description="Provide a name  for the promotion/discount."
          required
          full
        >
          <p class="hb-text-light mb-1 hb-font-caption mt-n1">
            This name will be displayed on the website. We recommend short and
            concise names.
          </p>
          <hb-text-field
            v-model.trim="form.name"
            :placeholder="form.label === 'discount' ? 'Enter Discount Name' : 'Enter Promotion Name'"
            v-validate="'required|max:100'"
            data-vv-name="name"
            data-vv-as="Name"
            :error="errors.collect('name').length > 0"
          />
        </hb-form>
        <hb-form
          label="Description"
          description="Provide a brief description of the promotion/discount."
        >
          <HbTextarea
            v-model="form.description"
            placeholder="Enter Description"
            v-validate="'max:255'"
            data-vv-name="description"
            data-vv-as="Description"
            :maxlength="255"
            :error="errors.collect('description').length > 0"
            placeholder-fix
            :rows="2"
          />
        </hb-form>
        <hb-form
        v-if="form.label === 'discount' && getSettings?.nationalAccountFeature === '1'"
          label="National Account"
          description="Enable to create national account specific discount."
        >
        <HbCheckbox
            v-model="form.isNationalAccountDiscount"
            label="Apply discount for national account only"
        />
        </hb-form>
        <hb-form
          label="Amount"
          description="Define the effect of the promotion/discount."
          full
          required
        >
          <promo-effect-list v-if="dialog && useEffect" ref="promoEffect" :effects="form.effect" @consecutive-month-view="enableConsecutiveMonth = $event"/>
          <div v-else>
            <v-row class="ma-0">
              <v-col md="4" class="pa-0">
                <HbSelect
                  box
                  condensed
                  medium
                  v-model="form.type"
                  :items="PROMOTION.PROMO_TYPES"
                  item-value="item"
                  item-text="label"
                  v-validate
                  :data-vv-name="`method_promo_type`"
                  attach
                  placeholder="$"
                  :clearable="false"
                />
              </v-col>
              <v-col md="4" class="pa-0 pr-3 pl-6">
                <v-text-field
                  :error-messages="errors.first('value')"
                  :class="{'custom-field-error' : errors.first('value')}"
                  required
                  class="pa-0 mt-0"
                  id="value"
                  name="value"
                  label="Amount"
                  hide-details
                  single-line
                  v-model="form.value"
                  v-validate="`${validateAmount}`"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </hb-form>
        <RoundingForm v-model="form.rounding_type" />
        <hb-form v-if="form.label !== 'discount'" label="Period" required full>
          <template v-slot:tooltipHeader> Period header </template>
          <template v-slot:tooltipBody> Period Body </template>
          <promo-period ref="promoPeriod" :period="form.period" :enable-consecutive-month="enableConsecutiveMonth" />
        </hb-form>
        <hb-form
          v-if="form.label !== 'discount'"
          label="Conditions"
          description="Apply special conditions to your promotions from the options below."
          full
        >
          <promo-conditions
            ref="promoConditions"
            :conditions="promoData.promotion_conditions"
            :length-of-stay="promoData.promotion_length_of_stay"
            :month-to-prepay="promoData.promotion_required_months"
          />
        </hb-form>
        <!-- <hb-form v-if="form.label !== 'discount'" label="Promo Code" full>
          <div>
            <hb-text-field
              v-model="form.promo_code.code"
              placeholder="Enter Promo Code"
              v-validate="'max:15'"
              data-vv-name="promo_code"
              data-vv-as="Promo Code"
              :error="errors.collect('promo_code').length > 0"
            />
          </div>
          <div class="mt-3 mb-0">
            <v-row no-gutters>
              <div class="hb-inline-sentence-text pb-0">
                How many times can this promo code be used(per tenant)?
              </div>
              <hb-text-field
                condensed
                box
                small
                v-model="form.promo_code.reuse_times"
                placeholder="0"
                v-validate="'numeric|min_value:1'"
                data-vv-name="promo_code_usage"
                data-vv-as="Promo Code Usage"
                :error="errors.collect('promo_code_usage').length > 0"
              />
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="5">
                <hb-date-picker
                  v-model="form.promo_code.start"
                  :label="'Start Date'"
                  :solo="false"
                  :min="currentDate"
                  :removePaddingMargin="true"
                >
                </hb-date-picker>
              </v-col>
              <v-col cols="5">
                <hb-date-picker
                  v-model="form.promo_code.end"
                  :min="form.promo_code.start"
                  :label="'End Date'"
                  :solo="false"
                  :removePaddingMargin="true"
                >
                </hb-date-picker>
              </v-col>
            </v-row>
          </div>
        </hb-form> -->
        <hb-form
          v-if="form.label === 'discount'"
          label="Eligible Tenants"
          description="Select the types of tenants who are eligible for this discount."
        >
          <HbCheckbox
            v-model="form.eligible_tenants.military"
            label="Military"
          />
          <HbCheckbox v-model="form.eligible_tenants.student" label="Student" />
          <HbCheckbox v-model="form.eligible_tenants.senior" label="Senior" />
        </hb-form>
        <hb-form
          label="Properties"
          :description="`Assign properties to the ${form.label}.`"
        >
        <hb-combobox
            label="Select Properties"
            v-model="selectedProperties"
            :items="properties"
            :key="comboKey"
            select
            multiple
            return-object
          >
            <template v-slot:item="{ item, attrs }">
              <v-list-item-action>
                <v-icon v-if="isSelected(item)">check_box</v-icon>
                <v-icon v-else>check_box_outline_blank</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </hb-combobox>
        </hb-form>
      </v-form>
      <ConfirmationPopup v-if="hasPromoId" v-once ref="confirmationPopup" />
    </template>
    <template v-slot:right-actions>
      <hb-btn @click="showConfirmationPopup" :loading="loading">Save</hb-btn>
    </template>
    <template v-slot:left-actions v-if="hasPromoId && form.modified">
      <div class="hb-inline-sentence-text pt-6">
        {{ form.modified }}
      </div>
    </template>
  </hb-modal>
</template>

<script>
import PromoEffectList from "./PromoEffectList.vue";
import PromoPeriod from "./PromoPeriod.vue";
import PromoConditions from "./PromoConditions.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import HbDatePicker from "../../assets/HummingbirdDatepicker.vue";
import { cloneDeep } from "lodash";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import RoundingForm from "../../assets/RoundingForm.vue";
import { capitalizeFirstLetter } from "../../../utils/common";
import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { PROMOTION } from "./Constants.js";

import api from "../../../assets/api.js";

import moment from "moment";

export default {
  name: "PromoEditForm",
  components: {
    PromoEffectList,
    PromoPeriod,
    PromoConditions,
    ConfirmationPopup,
    RoundingForm,
    HbDatePicker,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  mixins: [notificationMixin],
  data() {
    return {
      promoNameError: false,
      enableConsecutiveMonth: false,
      selectedProperties: [],
      comboKey: false,
      promoTypes: [
        {
          item: "promotion",
          label: "Promotion",
        },
        {
          item: "discount",
          label: "Discount",
        },
      ],
      form: {
        label: 'promotion',
        name: "",
        description: "",
        rounding_type: null,
        isNationalAccountDiscount: false,
        effect: [],
        type: "percent",
        value: "",
        period: {
          startingMonth: null,
          duration: null,
          isNextConsecutiveMonth: false,
          isNextMonth: false,
          day: 1,
        },
        eligible_tenants: {
          military: false,
          student: false,
          senior: false,
        },
        conditions: [],
        modified: '',
        promo_code: {
          code: "",
          reuse_times: 1,
          start: "",
          end: "",
        },
      },
      defaultData: {
        label: 'promotion',
        name: "",
        description: "",
        rounding_type: null,
        effect: [],
        type: "percent",
        value: "",
        period: {
          startingMonth: null,
          duration: null,
          isNextConsecutiveMonth: false,
          isNextMonth: false,
          day: 1,
        },
        eligible_tenants: {
          military: false,
          student: false,
          senior: false,
        },
        conditions: [],
        modified: '',
        promo_code: {
          code: "",
          reuse_times: "",
          start: "",
          end: "",
        },
      },
      promoData: {},
      loading: false,
      useEffect: false,
      //clonedData: {},
    };
  },
  async created() {
    this.PROMOTION = PROMOTION;
  },
  watch: {
    dialog(value) {

      this.$validator?.reset();
      this.$refs?.promoEffect?.$validator?.reset();
      this.$refs?.promoPeriod?.$validator?.reset();
      this.$validator?.errors?.clear();
      this.$refs?.promoEffect?.$validator?.errors?.clear();
      this.$refs?.promoPeriod?.$validator?.errors?.clear();
      if (!value) this.resetForm();

      // if (this.promotion?.id) this.form = cloneDeep(this.promotion);
      // else
    },
    selectedProperties: {
      handler(newval, oldval) {
        if (oldval?.length > 0 && newval?.length === 0) this.comboKey = !this.comboKey
      },
      immediate: true
    },
    "form.type"() {
      this.enableConsecutiveMonth = this.form.type && this.form.type === 'fixed';
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
      activePromotions: "revManStore/getActivePromotions",
      properties: "propertiesStore/filtered",
      getSettings: 'authenticationStore/getSettings',
    }),
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    /** Property for handle dialog box v-model */
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    validateAmount() {
      if (this.form.type === this.PROMOTION.PROMO_TYPES.dollar) {
        return "required|decimal:2|max_value:999|min_value:.01";
      } else if (this.form.type === this.PROMOTION.PROMO_TYPES.percent) {
        return "required|decimal:2|max_value:100|min_value:.01";
      } else if (this.form.type === this.PROMOTION.PROMO_TYPES.fixed) {
        return "required|decimal:2|max_value:999|min_value:0";
      } else {
        return "required";
      }
    },
    /** Property to return description according page type */
    dialogDescription() {
      let initialText = this.promoData?.promotion_id ? "Edit" : "Create";
      return `${initialText} the rules and effects of the promotion / discount.`;
    },
    /** Property to return title according page type */
    title() {
      return this.hasPromoId
        ? "Edit a Promotion / Discount"
        : "Create a Promotion / Discount";
    },
    hasPromoId() {
      return Boolean(this.promoData?.promotion_id);
    },
    eligibleTenants() {
      return Object.keys(this.form.eligible_tenants).filter(
        (tenant) => this.form.eligible_tenants[tenant]
      );
    },
  },
  methods: {
    /**
     * Function for clear form entries while closing model view
     * */
    resetForm() {
      this.$nextTick(() => {
        this.form = cloneDeep(this.defaultData);
        this.$refs.promoConditions?.reset()
        this.$refs?.promoEffect?.$validator?.errors?.clear();
        this.promoData = {}
        this.selectedProperties = []
      });
    },

    validatePromoName() {
      let duplicatePromos = this.activePromotions.some((promo) =>
        promo.name === this.form.name && promo.id !== this.promoData.promotion_id
      );
      if(duplicatePromos) {
        this.promoNameError = true;
        this.$validator.errors.add({
          field: "name",
          msg: 'A Promotion with the same name already exists.'
        });
      } else this.promoNameError = false;
    },

    closeForm() {
      this.$emit('close',this.promoData?.promotion_id ?? '')
    },
    isSelected(item) {
      return this.selectedProperties.some((element) => element.id === item.id);
    },
    /**
     * Function to display the confirmation modal.
     */
    async showConfirmationPopup() {
      this.$validator?.errors?.clear();
      const valid = await this.$validator.validateAll();
      this.validatePromoName();
      // await this.validateEffects();
      if(this.form.label !== 'discount') await this.$refs.promoPeriod.$validator.validateAll();
      if (!valid || this.errors?.items?.length) return;
      if (this.hasPromoId) {
        let confirmed = await this.$refs.confirmationPopup
          .show({
            title: `Edit Promotion / Discount`,
            message: `You are about to edit <strong>${this.form?.name}</strong> Promotion / Discount.<br/><br/>Are you sure you want to continue?`,
            buttonType: "primary",
            resolver: `Continue`,
          })
          .catch(() => false);
        if (confirmed) this.submit();
      } else this.submit();
    },

    /**
     * Function to save data
     */
    async submit() {
      this.loading = true
      //let effects = this.$refs.promoEffect.getData();
      let data = {
        is_national_account : this.form.isNationalAccountDiscount,
        name: this.form.name,
        label: this.form.label,
        description: this.form.description,
        //effects: effects ?? [],
        type: this.form.type,
        value: this.form.value,
        rounding_type: this.form.rounding_type,
      };
      let additionalFields = {};
      if (this.form.label == "promotion") {
        let { conditions, lengthOfStay, monthToPrepay } =
          await this.$refs.promoConditions.getAPIData();
        let period = this.$refs.promoPeriod.getData();
        additionalFields = {
          period: period,
          length_of_stay: lengthOfStay,
          required_months: monthToPrepay ?? null,
          conditions: conditions,
          promo_code: null
          // promo_code: {
          //   name: this.form.promo_code.code,
          //   max_use: this.form.promo_code.reuse_times,
          //   start_date: this.form.promo_code.start,
          //   end_date: this.form.promo_code.end,
          // },
        };
      } else {
        additionalFields = {
          eligible_tenants: this.eligibleTenants,
        };
      }
      data = {
        ...data,
        ...additionalFields,
        properties: this.selectedProperties.length? this.selectedProperties.map(p => {
          return {
            id: p.id
          }
        }) : []
      };
      try {
        const apiMethod = this.hasPromoId ? "put" : "post";
          const url = api.PROMOTIONS + `${this.hasPromoId ? `${this.promoData?.promotion_id}` : ""}`;
        let response = await api[apiMethod](this, url, data);
        console.log("response: ", response);
        EventBus.$emit("promoLibraryEvents", {
          event: "triggerTableRefresh"
        });
        if (data.label === "discount") EventBus.$emit("refreshDiscountTable")
        this.showMessageNotification({
          type: "success",
          description: `${capitalizeFirstLetter(this.form.label)} ${
            this.hasPromoId ? "updated" : "created"
          } successfully`,
        });
        this.dialog = false;
      } catch (err) {
        console.log(err);
        this.showMessageNotification({
          description: err,
        });
      }
      this.loading = false
    },

    /**
     * Function to validate effects
     */
    async validateEffects() {
      let errorMessages = [];
      let errorFields = [];
      let effectValues = [];
      let productRepeatError = false;
      const promises = this.$refs.promoEffect.effectList.map(
        async (effect, index) => {
          if (!effect?.on) errorMessages.push("Effect field is required");
          if (effect?.on === "merchandise" && !effect?.merchandise_items.length)
            errorMessages.push("Merchandise Item field is required");
          if (!effect?.amount.type)
            errorMessages.push("Amount method field is required");
          if (effect?.on === "merchandise") {
            let effectValue = effect.amount.type + effect.amount.value;
            if (effectValues.includes(effectValue)) {
              errorMessages.push(
                "Amount value and type should not be repeated for merchandise"
              );
              productRepeatError = true;
            } else effectValues.push(effectValue);
          }
          const valueErrorMessages = {
            percent:
              "Amount percentage value should not include any decimal places and value should be between 1 and 100.",
            dollar:
              "Amount value should have a maximum of two decimal places and value should be between 1 and 999.",
            fixed:
              "Amount value should have a maximum of two decimal places and value should be between 0 and 999.",
          };
          let effectValueError =
            effect?.amount.type && this.hasRateByError(effect?.amount.type, effect?.amount.value);
          if (effectValueError)
            errorMessages.push(valueErrorMessages[effect?.amount.type]);
          errorFields.push({
            on: !effect?.on,
            merchandise_items:
              effect?.on === "merchandise" && !effect?.merchandise_items.length,
            type: !effect?.amount.type || productRepeatError,
            value: effectValueError || productRepeatError,
            index: index,
          });
        }
      );
      this.$refs.promoEffect.showError(errorFields);
      await this.$refs.promoEffect.$validator.validateAll();
      errorMessages = [...new Set(errorMessages)];
      errorMessages.forEach((msg) => {
        this.$validator.errors.add({
          field: "effects",
          msg,
        });
      });
      await Promise.all(promises);
    },

    /**
     * Function to check scheduled plan value field validation
     * @param {String} value value of the stage
     * @returns a boolean value
     * */
    hasRateByError(value_type, value) {
      if (value === "") return true;
      if (
        ['percent']?.includes(value_type) &&
        !/(^(100)$|^[1-9][0-9]?)$/.test(value)
      )
        return true;
      else if (["dollar", "fixed"].includes(value_type)) {
        if (
          /^[0-9]+(\.[0-9]{1,2})?$/.test(value) &&
          Number(value) >= (value_type === 'fixed' ? 0 : 1) &&
          Number(value) <= 999
        ) {
          return false;
        }
        return true;
      }
    },

    show(data) {
      if (data && data.rowData) {
        this.promoData = data.rowData;
        let promoData = data.rowData;
        console.log("promoData: ", promoData);
        this.form.modified = promoData.promotion_modified_by || '';

        const filteredProperties = promoData?.promotion_properties?.length
          ? this.properties.filter((property) =>
              promoData.promotion_properties.some((promoProperty) => promoProperty.id === property.id)
            )
          : [];

        this.selectedProperties.splice(0, this.selectedProperties.length, ...filteredProperties);

        this.form.label = promoData.promotion_type;
        this.form.name = promoData.promotion_name;
        this.form.description = promoData.promotion_description;
        this.form.rounding_type = promoData.promotion_rounding;
        this.form.isNationalAccountDiscount = promoData.promotion_is_national_account == 'Yes' ? 1 : 0
        // this.form.effect = promoData.promotion_effects;

        this.form.period = promoData.promotion_period;
        if(promoData.promotion_amount){
          this.form.type = promoData.promotion_amount.type;
          this.form.value = promoData.promotion_amount.value;
        }
        if (promoData.promotion_eligible_tenants?.length)
          promoData.promotion_eligible_tenants.forEach((item) => {
            if (this.form.eligible_tenants[item] != undefined)
              this.form.eligible_tenants[item] = true;
          });
        console.log("this.form.: ", this.form);

        // this.form.promo_code = promoData.promotion_promo_code
      }
    },
  },
};
</script>
<style scoped>
</style>
