<template>
	<div>
		<v-card v-if="activeMerchandiseTab === 'fees'" flat color="#F9FAFB" style="color: rgba(0, 0, 0, 0.87);">
			<v-card-title class="pl-7">Contact Summary</v-card-title>
			<v-card-text style="color: rgba(0, 0, 0, 0.87);" >

				<v-container>
					<v-row dense>
					<v-col>
						<div v-if="property.name">{{property.name}}</div>
						<div v-if="property.Address">{{property.Address.address}} {{property.Address.address2}}<br />
						{{ property.Address | formatAddress }}
						</div>
						<div v-if="property.Phones && property.Phones.length" v-for="(phone, i) in property.Phones" :key="i">
						{{phone.phone | formatPhone}} - {{phone.type}}
						</div>
					</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
				<v-container v-show="step > 1">
					<v-row dense>
					<v-col>
						<div v-if="contact.first && contact.last">{{contact.first}} {{contact.last}}</div>
						<div v-if="contact.email">{{contact.email}}</div>
						<div v-if="contact.Phones && contact.Phones.length">{{ contact.Phones[0].phone | formatPhone}}<br /></div>
					</v-col>
					</v-row>
				</v-container>
				<v-divider v-show="step > 1"></v-divider>

				<v-container>
					<v-row v-for="(service, i) in services" :key="i" dense>
					<v-col cols="8">{{service.name}}<span v-if="service.qty > 1" class="ml-1">Qty x {{service.qty}}</span></v-col>
					<v-col class="align-right"><span v-if="service.qty > 1">{{service.price * service.qty | formatMoney}}</span><span v-else>{{service.price | formatMoney}}</span></v-col>
					</v-row>
				</v-container>


				<v-container style="background-color: white;" v-if="invoice.total_due && step > 1">
					<v-row dense>
					<v-col >Subtotal</v-col>
					<v-col class="align-right">{{invoice.sub_total | formatMoney}}</v-col>
					</v-row>
					<v-row dense>
					<v-col >Tax</v-col>
					<v-col class="align-right">{{invoice.total_tax | formatMoney}}</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-row dense>
					<v-col style="font-weight: 500;">Total</v-col>
					<v-col style="font-weight: 500;" class="align-right">{{invoice.total_due | formatMoney}}</v-col>
					</v-row>
				</v-container>

			</v-card-text>
		</v-card>

		<div v-else class="cart-summary mx-4 mt-6 mb-3">
			<div class="d-flex align-center justify-space-between pb-4">
				<div class="d-flex">
					<hb-icon style="position: relative; top: 6px;">mdi-tag</hb-icon>
					<div class="hb-font-header-2-medium pl-4">Shopping Cart</div>
				</div>
			</div>
			<hb-card class="pa-4">
				<div v-for="product in services" class="pb-1">
					<div class="align-start d-flex justify-space-between mt-2 mb-3" v-if="product.qty > 0">
						<div class="pr-2 pt-1">
							<div class="hb-button-text-regular font-weight-medium pb-2">
								{{ product.name }}
							</div>
							<div class="hb-text-light hb-button-text-small">
								{{ product.description | truncateText(150) }}
							</div>
						</div>
						<div class="d-flex align-center">
							<hb-text-field
								:value="product.price"
								@keydown.enter="updateProductPriceEvent($event, product)"
								dense
								box
								width="135px"
								class="merchandise-product-cls-2 mr-6"
								v-if="product.amount_type === 'variable'"
							>
								<template v-slot:prepend-inner>
									$
								</template>
							</hb-text-field>
							<div v-else class="d-flex align-center">
								<div class="mr-2" style="">
									<div class="d-flex justify-space-between">
										<div class="d-flex align-center justify-space-between px-2 py-1"
											style="width: 100%; border: 1px solid #DFE3E8; border-radius: 5px;">
											<!-- <hb-btn icon>mdi-minus</hb-btn> -->
											<hb-icon color="#000" small class="hand" @click="updateProduct(product, 'minus')">mdi-minus</hb-icon>
											<span class="d-inline-block px-3">{{ product.qty }}</span>
											<hb-icon color="#000" class="hand" small @click="updateProduct(product, 'plus')">mdi-plus</hb-icon>
										</div>
									</div>
								</div>
								<div class="mr-2" style="width: 60px;text-align: right;">{{product.price * product.qty | formatMoney}}</div>
							</div>
							<hb-icon color="#637381" class="hand" small @click="removeService(product)">mdi-close-circle</hb-icon>
						</div>
					</div>
				</div>
				<div v-if="invoice.total_due">
					<v-divider></v-divider>
					<div class="total-summary pt-4">
						<div class="subtotal d-flex justify-end hb-text-light pb-1">
							<div>Subtotal</div><span style="min-width: 70px; text-align: right;">{{invoice.sub_total | formatMoney}}</span>
						</div>
						<div class="subtotal-tax d-flex justify-end hb-text-light pb-1">
							<div>Tax</div><span style="min-width: 70px; text-align: right;">{{invoice.total_tax | formatMoney}}</span>
						</div>
						<div class="total d-flex justify-end font-weight-medium pb-1">
							<div>Total</div><span style="min-width: 70px; text-align: right;">{{invoice.total_due | formatMoney}}</span>
						</div>
					</div>
				</div>
			</hb-card>
		</div>
	</div>


</template>


<script type="text/babel">
import MiniProfileView from "../LeadIntake/MiniProfileView.vue";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash';


export default{
	name: "MerchandiseSummaryPanel",
	mixins: [ notificationMixin ],
	components:{
		MiniProfileView
	},
	computed: {
		...mapGetters({
            activeMerchandiseTab: 'navigationStore/activeMerchandiseTab',
			activeDrawerComponent: 'navigationStore/activeDrawerComponent',
			merchandiseSettings: 'productStore/merchandiseSettings',
		})
	},
	methods: {
		...mapActions({
			setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
		}),
		removeService(product){
			this.$emit('removeServices', product.product_id);
		},
		updateProduct(product, type) {
			if (this.merchandiseSettings) {
				if (type == 'plus' && (product.allow_negative || product.qty < product.stock)) {
					product.qty +=1
				}
				if (type == 'minus' && product.qty > 1 && (product.allow_negative || product.stock > 0)) {
					product.qty -=1
				}
			} else {
				if (type == 'plus') product.qty +=1;
				else if(product.qty > 1) product.qty -=1;
			}
			this.calculateTotalInvoiceEvent(this, product);
		},
		updateProductPriceEvent(event, product) {
			let { target } = event;
			if (!target.value) {
				this.showMessageNotification({ description: `Price is required for ${product.name}.` });
				return;
			}
			if (isNaN(target.value)) {
				this.showMessageNotification({ description: `Invalid price is value provided.` });
				return;
			}
			if (target.value < 1) {
				this.showMessageNotification({ description: `Price must be greater then 0.` });
				return;
			}
			product.price = Math.abs(Math.round(target.value * 1e2) / 1e2).toFixed(2);
			this.calculateTotalInvoiceEvent(this, product);
		},
		calculateTotalInvoiceEvent: debounce((_this, product) => {
			_this.$set(_this.services, product.product_id, product);
			_this.$emit('calculateTotal');
			_this.setActiveDrawerComponent({
                ..._this.activeDrawerComponent,
                services: _this.services
            })
		}, 1000),
	},
	props:['contact', 'property', 'services', 'invoice', 'step', 'isGuestUser']
}


</script>

<style>
.merchandise-product-cls-2 .hb-text-field-rounded .v-input__control .v-input__slot {
    min-height: 33px !important;
}
</style>
