<template>
  <div>
    <!--
    <div v-if="!facilityLevel">
      <span v-if="editMode" class="ml-6"
      >Configure multi-month Payment Cycles.</span
      >
      <span v-else class="ml-6">
        <span class="font-weight-medium">Step 1:</span> Configure multi-month payment cycles.</span
      >
      <v-divider class="mt-3"></v-divider>
    </div>
    -->
    <hb-form
      label="Payment Cycle"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      @editStart="handleEditStart('Payment Cycle')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      v-if="isPaymentCycleAllowed && !isBillingCycleAllowed"
      :auto-layout="false"
      full
      last
      :divider="readMode ? false : true"
      :content-position="!readMode ? contentPosition : undefined"
    >
      <template v-slot:display>
        <v-row no-gutters>
          <HbCheckbox
            v-model="template.enable_payment_cycles"
            readonly
            label="Enable Multi-Month Payments"
          />
        </v-row>

        <div class="ml-10">
          <hb-checkbox
            v-if="template.enable_payment_cycles && template.payment_cycle_options[0]"
            v-model="template.payment_cycle_options[0].enabled"
            readonly
            class="mt-2"
          >
            <template v-slot:label>
              <span>{{ template.payment_cycle_options[0].label }} Payments</span>
              <span v-if="promoName(template.payment_cycle_options[0].promotion_id)" class="pl-1">- {{ promoName(template.payment_cycle_options[0].promotion_id) }}</span>
            </template>
          </hb-checkbox>

          <hb-checkbox
            v-if="template.enable_payment_cycles && template.payment_cycle_options[1]"
            v-model="template.payment_cycle_options[1].enabled"
            readonly
            class="mt-3"
          >
            <template v-slot:label>
              <span>{{ template.payment_cycle_options[1].label }} Payments</span>
              <span v-if="promoName(template.payment_cycle_options[1].promotion_id)" class="pl-1">- {{ promoName(template.payment_cycle_options[1].promotion_id) }}</span>
            </template>
          </hb-checkbox>

          <hb-checkbox
            v-if="template.enable_payment_cycles"
            v-model="template.add_next_month_mml"
            readonly
            class="mt-3"
          >
            <template v-slot:label>
              Start the Payment Cycle from next month after this day - {{ template.add_next_month_mml_days}}
            </template>
          </hb-checkbox>

          <div class="mt-3" v-if="template.enable_payment_cycles">
            Revert to month to month if tenant does not pay within {{ template.revert_payment_cycle }} {{ template.revert_payment_cycle === 1 ? 'day' : 'days' }}.
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <HbCheckbox
          v-model="template.enable_payment_cycles"
          :true-value="1"
          :false-value="0"
          label="Enable Multi-Month Payments"
        />

        <div class="ml-10">
          <hb-checkbox
            v-show="template.enable_payment_cycles"
            v-model="template.payment_cycle_options[0].enabled"
            align-top
            class="mt-3"
          >
            <template v-slot:content>
              <v-row no-gutters style="margin-top:-6.5px;">
                <div>{{ template.payment_cycle_options[0].label }} Payments</div>

                <div :class="{ 'ml-3' : $vuetify.breakpoint.smAndUp, 'my-2' : $vuetify.breakpoint.xs }">
                  <HbSelect
                    :width="$vuetify.breakpoint.xsOnly ? '140px' : $vuetify.breakpoint.smOnly ? '170px' : '300px'"
                    v-model="template.payment_cycle_options[0].promotion_id"
                    v-validate="template.enable_payment_cycles && template.payment_cycle_options[0].enabled ? 'required' : ''"
                    :items="promotions"
                    item-text="name"
                    item-value="id"
                    name="quarterly_payments_promotion"
                    data-vv-scope="form_4"
                    data-vv-as="Quarterly Payments Promotion"
                    :error="errors.has('form_4.quarterly_payments_promotion')"
                    :clearable="false"
                    placeholder="Select Promotion"
                  />
                </div>
              </v-row>
            </template>
          </hb-checkbox>

          <hb-checkbox
            v-show="template.enable_payment_cycles"
            v-model="template.payment_cycle_options[1].enabled"
            align-top
            class="mt-3"
          >
            <template v-slot:content>
              <v-row no-gutters style="margin-top:-6.5px;">
                <div>{{ template.payment_cycle_options[1].label }} Payments</div>

                <div :class="{ 'ml-3' : $vuetify.breakpoint.smAndUp, 'my-2' : $vuetify.breakpoint.xs }">
                  <HbSelect
                    :width="$vuetify.breakpoint.xsOnly ? '140px' : $vuetify.breakpoint.smOnly ? '170px' : '300px'"
                    v-model="template.payment_cycle_options[1].promotion_id"
                    v-validate="template.enable_payment_cycles && template.payment_cycle_options[1].enabled ? 'required' : ''"
                    :items="promotions"
                    item-text="name"
                    item-value="id"
                    name="annual_payments_promotion"
                    data-vv-scope="form_4"
                    data-vv-as="Annual Payments Promotion"
                    :error="errors.has('form_4.annual_payments_promotion')"
                    :clearable="false"
                    placeholder="Select Promotion"
                  />
                </div>
              </v-row>
            </template>
          </hb-checkbox>

          <hb-checkbox
            v-show="template.enable_payment_cycles"
            v-model="template.add_next_month_mml"
            align-top
            class="mt-3"
          >
            <template v-slot:content>
              <v-row no-gutters class="mt-n3">
                <div class="hb-inline-sentence-text">
                  Start the cycle from the next month after this day
                </div>
                <HbSelect
                  box
                  condensed
                  small
                  v-model="template.add_next_month_mml_days"
                  :items="invoiceDays"
                  :clearable="false"
                  v-validate="template.enable_payment_cycles && template.add_next_month_mml ? 'required' : ''"
                  id="add_next_month_mml_days"
                  name="add_next_month_mml_days"
                  data-vv-scope="form_4"
                  data-vv-as="Extra Month"
                  :error="errors.has('form_4.add_next_month_mml_days')"
                  :disabled="!template.add_next_month_mml"
                  class="mt-1"
                />
              </v-row>
            </template>
          </hb-checkbox>

          <v-row v-show="template.enable_payment_cycles" no-gutters class="mt-5 mb-n3">
            <div class="hb-inline-sentence-text">
              Revert to month to month if tenant does not pay within
            </div>

            <HbSelect
              condensed
              box
              small
              v-model="template.revert_payment_cycle"
              v-validate="template.enable_payment_cycles ? 'required' : ''"
              :items="revert_options"
              name="revert_option_days"
              data-vv-scope="form_4"
              data-vv-as="Revert Option Days"
              :error="errors.has('form_4.revert_option_days')"
            />

            <div class="hb-inline-sentence-text">
              day(s).
            </div>
            
          </v-row>
        </div>
      </template>
    </hb-form>

    <hb-form
      label="Billing Cycle"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      @editStart="handleEditStart('Billing Cycle')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      v-if="!isPaymentCycleAllowed && isBillingCycleAllowed"
      :auto-layout="false"
      full
      last
      :divider="readMode ? false : true"
      :content-position="!readMode ? contentPosition : undefined"
    >
      <template v-slot:display>
        <v-row v-for="cycle in template.BillingCycleOptions" class="ml-3 pb-6 hb-font-body">
          <div>
            {{ cycle.value | formatMonth }}
          </div>
        </v-row>
      </template>
      <template v-slot:edit>

        <span class="billing-cycle-text">Select all billing cycles.</span>
        <HbCheckbox
          v-model="selectAll"
          :label="'Select All'"
          @change="toggleBillingCycle"
          class="mt-2"
        />

        <HbCheckbox
          v-for="(b, i) in template.available_billing_cycles"
          :key="b.id"
          :label="b.value | formatMonth"
          :value="b.id"
          v-model="template.template_billing_cycles[i].selected"
        /> 
      </template>
    </hb-form>
    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Payment Cycle</span>
        </div>
      </v-col>
      <v-col class="font-weight-regular pa-0" cols="9">
        
      <hoverable-edit-field
        :activeEditing="activelyEditing === 'payment_cycle_options'"
        :hasPermissionToEdit="isPermissionToEdit"
        name="payment_cycle_options"
      >
        <template slot="editingMode">
  
          <v-row>
            <v-col>
              <HbSwitch
                right
                v-model="template.enable_payment_cycles"
                label="Enable Multi-Month Leases"
              />
            </v-col>
          </v-row>


          
          <v-row class="mr-0" v-if="template.enable_payment_cycles && template.payment_cycle_options[0]">
            <v-col class="font-weight-regular pb-0 pt-0 ml-4" cols="6">
              <HbCheckbox 

                v-model="template.payment_cycle_options[0].enabled" 
                :label="template.payment_cycle_options[0].label + ' Payments'">
              </HbCheckbox>
            </v-col>

            <v-col cols="5" class="ma-0 pa-0"> 
             <HbSelect
                    :items="promotions" 
                    label="Select Promotion"
                    hide-details
                    attach
                    item-text="name"
                    item-value="id"
                    class="pa-0 mt-0"
                    single-line
                    v-model="template.payment_cycle_options[0].promotion_id"
                />
                
            </v-col>
          </v-row>


          <v-row class="mr-0 py-2" v-if="template.enable_payment_cycles && template.payment_cycle_options[1]">
            <v-col class="font-weight-regular pb-0 ml-4 pt-0" cols="6">
              
              <HbCheckbox 
                v-model="template.payment_cycle_options[1].enabled" 
                :label="template.payment_cycle_options[1].label  + ' Payments'">
              </HbCheckbox>
            </v-col>

            <v-col cols="5" class="ma-0 pa-0"> 
              <HbSelect
                    :items="promotions" 
                    label="Select Promotion"
                    hide-details
                    attach
                    item-text="name"
                    item-value="id"
                    class="pa-0 mt-0"
                    single-line
                    v-model="template.payment_cycle_options[1].promotion_id"
              />
                
            </v-col>
          </v-row>

          <v-row v-show="template.enable_payment_cycles" no-gutters>
             <div class="hb-inline-sentence-text ml-4">
              Revert to month to month if tenant does not pay within
            </div>
              <HbSelect
                small
                condensed
                box
                :items="revert_options" 
                hide-details
                attach
                class="pa-0 mt-0"
                single-line
                v-model="template.revert_payment_cycle"
              />
             <div class="hb-inline-sentence-text">
              days
            </div>
            
          </v-row>

          </template>

            
        <template slot="readMode" v-if="editMode">

          
           <v-row>
              <div class="hb-inline-sentence-text pa-1 pb-3 mr-2">Enable Multi-Month Payments</div>
              <HbSwitch
                small
                box
                condensed
                disabled
                v-model="template.enable_payment_cycles"
              />            
          </v-row>


          
          <v-row no-gutters class="pl-3  pb-2" v-if="template.enable_payment_cycles && template.payment_cycle_options[0]">
            <v-col cols="1">
              <HbCheckbox 
                  class="mt-0"
                  dense
                  box
                  condensed
                  disabled
                  v-model="template.payment_cycle_options[0].enabled" >
                </HbCheckbox>
            </v-col>
            <v-col class="mr-4 pt-1" :class="{ 'disabled': !template.payment_cycle_options[0].enabled}">{{ template.payment_cycle_options[0].label }} Payments</v-col>
            <v-col>{{ promoName(template.payment_cycle_options[0].promotion_id) }}</v-col>
            
          </v-row>


          <v-row no-gutters class="pl-3 pb-4" v-if="template.enable_payment_cycles && template.payment_cycle_options[1]">
            <v-col cols="1">
            <HbCheckbox 
                box
                dense
                condensed
                disabled
                v-model="template.payment_cycle_options[1].enabled" >
              </HbCheckbox>
            </v-col>
             <v-col class="mr-4 pt-1" :class="{ 'disabled': !template.payment_cycle_options[1].enabled}">{{ template.payment_cycle_options[1].label }} Payments</v-col>
            <v-col>{{ promoName(template.payment_cycle_options[1].promotion_id) }}</v-col>
            
          </v-row>

      <v-row v-if="template.enable_payment_cycles" no-gutters class="pl-3 pt-0">
             <div class="hb-inline-sentence-text">
                Revert to month to month if tenant does not pay within {{template.revert_payment_cycle}} days
              </div>
          </v-row>
        </template>
      </hoverable-edit-field>
    </v-col>
    </v-row>
    -->


  </div>
</template>

<script type="text/babel">
import HoverableEditField from "../../assets/HoverableEditField.vue";
import Loader from '../../assets/CircleSpinner.vue';
import api from '../../../assets/api.js';
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
  name: "BillingConfigurations",
  mixins: [ notificationMixin ],
  components: {
    HoverableEditField,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    template: {
      type: Object,
      required: true
    },
    facilityLevel: {
      type: Boolean,
      default: false
    },
    contentPosition: {

    },
    activeTab: {

    }
  },
  data() {
    return {
      loaded: false,
      isEditing: false,
      promotions: [],
      active: '',
      clonedLeaseTemplate: {},
      selectAll: false
    };
  },
  created(){
    if(!this.template) return;

    if(!this.editMode){

      let quarterly = this.template.payment_cycle_options?.length && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'quarterly');
      let annual = this.template.payment_cycle_options?.length && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'annual');

      this.template.payment_cycle_options =  this.template.payment_cycle_options || [];

      if(!this.readMode){
        this.template.revert_payment_cycle = '';
      }

      this.$set(this.template.payment_cycle_options, 0, {
        enabled: !!quarterly,
        label: 'Quarterly',
        promotion_id: quarterly && quarterly.promotion_id || ''
      })
      
      this.$set(this.template.payment_cycle_options, 1, {
        enabled: !!annual,
        label: 'Annual',
        promotion_id: annual && annual.promotion_id || ''
      })
    }
    
    this.getPromotions();

    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  filters:{

  },
  computed: {
    revert_options(){
      let opts = [];
      for(let i = 1; i <= 30; i++){
        opts.push(i)
      }
      return opts

    }, 
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission',
      isPaymentCycleAllowed: 'propertiesStore/isPaymentCycleAllowed',
      isBillingCycleAllowed: 'propertiesStore/isBillingCycleAllowed'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    },
    invoiceDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i );
      return days;
    },
  },
  methods: {
    promoName(promotion_id){
         if(!promotion_id) return '';
          let promo = this.promotions.find(p => p.id == promotion_id);
          if(!promo) return '';
          return promo.name; 
      }, 
    async getPromotions(){
        
        let r = await api.get(this, api.PROMOTIONS + '?label=discount');
        this.promotions = r.promotions; 
      
    },
    addPaymentCycle(){
        
        this.template.payment_cycle_options.push({
            period: '',  
            promotion_id: ''
        })
    },
    removePaymentCycle(index){
        if(index > 0){
            this.template.payment_cycle_options.splice(index, 1)
        }
    },
    async validateForm() {
      const status = await this.$validator.validateAll("form_4");

      if(!status){
        return status;
      }

      if(!this.isPaymentCycleAllowed) return true;

      let errors = [];
      if(this.template.enable_payment_cycles && !this.template.payment_cycle_options.filter(p => p.enabled).length){
        errors.push({
          msg: "Please enable at least one payment cycle"
        })
      };
      if(this.template.enable_payment_cycles && !this.template.revert_payment_cycle){
          errors.push({
            msg: "Please enter a value for revert payment cycle"
          })
      }
      for(let i = 0; i < this.template.payment_cycle_options.length; i++){
          if(this.template.enable_payment_cycles && this.template.payment_cycle_options[i].enabled && !this.template.payment_cycle_options[i].promotion_id){
            errors.push({
              msg: `Please choose a promotion for ${this.template.payment_cycle_options[i].label} payment cycle`
            })
          }

      }

      if(errors.length){        
		this.showMessageNotification({id: this.$options.name, list: errors});
        return false;
      }
      return true;

    },
    handleEmittedCancel(){
      EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    handleEditStart(label){
      this.clonedLeaseTemplate = JSON.parse(JSON.stringify(this.template));
    },
    clearActive(){
      this.active = '';
    },
    toggleBillingCycle() {
      if (this.selectAll){  
        this.template.template_billing_cycles = this.template.available_billing_cycles.map(val => ({id: val.id, selected: true})); 
      }
      else{
        this.template.template_billing_cycles = this.template.available_billing_cycles.map(val => ({id: val.id, selected: false})); 
      }
    },
  },
  watch: {
    "activeTab"(){
      if(this.activeTab !== 3 && this.active === 'Payment Cycle'){
        EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
        this.clearActive();
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
.disabled{
  opacity: .5;
}
.billing-cycle-text{
  color: #FF00C7;
}
</style>
