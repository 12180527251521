<template>
  <div>
    <hb-modal title="Add Property" v-model="dialog" size="large">
      <template v-slot:subheader>
        Create a new property and manage settings
      </template>
      <template v-slot:content>
        <hb-form label="Property Number" :active.sync="active" required>
          <HbTextField
              v-model="formData.number"
              v-validate="'max:45|required'"
              data-vv-scope="default"
              data-vv-name="property_number"
              data-vv-as="Property Number"
              :error="errors.has('default.property_number')"
              placeholder="Property Number"
          ></HbTextField>
        </hb-form>
        <hb-form label = "Property Name" :active.sync="active" required>
          <HbTextField
              v-model="formData.name"
              v-validate="'max:45|required'"
              data-vv-scope="default"
              data-vv-name="property_name"
              data-vv-as="Property Name"
              :error="errors.has('default.property_name')"
              placeholder="Property Name"
          ></HbTextField>
        </hb-form>
        <hb-form label = "Legal Name" :active.sync="active">
          <HbTextField
              v-model="formData.legal_name"
              v-validate="'max:45'"
              data-vv-scope="default"
              data-vv-name="legal_name"
              data-vv-as="Legal Name"
              :error="errors.has('default.legal_name')"
              placeholder="Legal Name"
          ></HbTextField>
        </hb-form>
        <hb-form label = "Email" :active.sync="active" required>
          <HbTextField
              v-model="formData.Emails[0].email"
              v-validate="{required: true, max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
              data-vv-scope="default"
              data-vv-name="email"
              data-vv-as="Email"
              :error="errors.has('default.email')"
              placeholder="Email"
          ></HbTextField>
        </hb-form>
        <hb-form label = "Phone" :active.sync="active" required>
          <v-row>
            <v-col cols="3" class="pt-0 pb-0 ma-0 pr-3 pl-3">
                <HbAutocomplete
                    v-model="phoneCode"
                    v-validate="'required'"
                    :items="countryCodesList"
                    :clearable="false"
                    data-vv-scope="default"
                    data-vv-as="Phone Code"
                    :name="'phone_code'"
                    :error="errors.has('default.phone_code')"
                    :error-messages="errors.collect('default.phone_code')"
                    placeholder="Code"
                    @change="checkValidPhone(phoneCode, phoneNumber)"
                    @focusout="checkValidPhone(phoneCode, phoneNumber)"
                >
                    <template v-slot:selection="data">
                        +{{data.item}}
                    </template>
                    <template v-slot:item="data">
                        +{{data.item}}
                    </template>
                </HbAutocomplete>
            </v-col>
            <v-col cols="9" class="pa-0 ma-0">
                <HbTextField
                    v-model="phoneNumber"
                    v-validate="{required: true, max:16, numeric: true}"
                    data-vv-scope="default"
                    data-vv-name="phone"
                    data-vv-as="Phone"
                    :error="(errors.has('default.phone')) || (isInvalidPhone)"
                    placeholder="Phone"
                    @input="checkValidPhone(phoneCode, phoneNumber)"
                    @focusout="checkValidPhone(phoneCode, phoneNumber)"
                >
                </HbTextField>
            </v-col>
          </v-row>
        </hb-form>
        <hb-form label = "Address" :active.sync="active" required>
          <v-row>
            <v-col class="pt-0 pb-0 ma-0 pr-3">
              <HbAutocomplete
                  :items="countriesListMixin"
                  v-validate="'required'"
                  v-model="formData.Address.country"
                  placeholder="Country"
                  data-vv-scope="default"
                  data-vv-as="Country"
                  name="country"
                  :error="errors.has('property.country')"
                  @change="setPropertyCountry"
              >
              </HbAutocomplete>
            </v-col>
          </v-row>
          <v-row >
            <v-col class="pt-0 pb-0 ma-0 pr-3">
              <HbTextField
                  v-model="formData.Address.address"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="address"
                  data-vv-as="Address"
                  :error="errors.has('default.address')"
                  placeholder="Street"
              >
              </HbTextField>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0 ma-0 pr-3">
              <HbTextField
                  v-model="formData.Address.address2"
                  v-validate="'max:45'"
                  data-vv-scope="default"
                  data-vv-name="address2"
                  data-vv-as="Suite / Apt"
                  :error="errors.has('default.address2')"
                  placeholder="Suite / Apt"
              >
              </HbTextField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
              <HbAutocomplete
                :items="propertyStateListMixin || []"
                item-text='name'
                item-value='value'
                id="state"
                placeholder="State"
                v-model.lazy="formData.Address.state"
                data-vv-name="state"
                data-vv-as="State"
                data-vv-scope="default"
                :hide-details="!errors.collect('default.state').length"
                :error-messages="errors.collect('default.state')"
                @change="setPropertyState"
              />
            </v-col>
            <v-col cols="5" class="pt-0 pb-0 ma-0 pl-3">
              <HbAutocomplete
                :items="propertyCityListMixin || []"
                v-validate="propertyCityListMixin && propertyCityListMixin.length ? 'required' : ''"
                v-model="formData.Address.city"
                label="City"
                id="city"
                data-vv-name="city"
                data-vv-as="City"
                data-vv-scope="default"
                :hide-details="!errors.collect('default.city').length"
                :error-messages="errors.collect('default.city')"
                @keydown.enter="setPropertyCityList($event.target.value, true)"
              >
              </HbAutocomplete>
            </v-col>
            <v-col cols="3" class="pt-0 pb-0 ma-0 pr-3 pl-3">
              <HbTextField
                  v-model="formData.Address.zip"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="zip"
                  data-vv-as="Zip"
                  :error="errors.has('default.zip')"
                  placeholder="Zip"
              >
              </HbTextField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pt-0 pb-0 ma-0 pr-3 pl-3">
              <HbTextField
                v-model="formData.Address.region"
                v-validate="'max:45'"
                data-vv-scope="default"
                data-vv-name="region"
                data-vv-as="Region"
                :error="errors.has('default.region')"
                placeholder="Region"
              >
              </HbTextField>
            </v-col>
              <!-- <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
                <HbAutocomplete
                  v-model="formData.Address.Suburb"
                  :items="suburbsList || []"
                  item-text="name"
                  return-object
                  placeholder="Suburb"
                  @keydown.enter="setSuburbList($event.target.value)"
                >
                </HbAutocomplete>
              </v-col> -->
              <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
                <HbTextField
                  v-model="formData.Address.district"
                  v-validate="'max:45'"
                  data-vv-scope="default"
                  data-vv-name="district"
                  data-vv-as="District"
                  :error="errors.has('default.district')"
                  placeholder="District"
                >
                </HbTextField>
              </v-col>
          </v-row>
        </hb-form>
        <hb-form label = "Google Analytics (GA) ID" :active.sync="active">  
          <HbTextField
            v-model="formData.google_analytics_id"
            v-validate="'max:45'"
            data-vv-scope="default"
            data-vv-name="google_analytics_id"
            data-vv-as="Google Analytics(GA) ID"
            :error="errors.has('default.google_analytics_id')"
            placeholder="GA ID"
          >
          </HbTextField>
        </hb-form>
        <hb-form label = "Property Web URL" :active.sync="active">
          <HbTextField
            v-model="formData.landing_page"
            v-validate="'max:300'"
            data-vv-scope="default"
            data-vv-name="web_url"
            data-vv-as="Property Web URL"
            :error="errors.has('default.web_url')"
            placeholder="Property Web URL"
          >
          </HbTextField>
        </hb-form>
        <hb-form label = "Tax / ABN Number" :active.sync="active">
          <HbTextField
            v-model="formData.tax_number"
            v-validate="'max:45'"
            data-vv-scope="default"
            data-vv-name="tax_number"
            data-vv-as="Tax / ABN Number"
            :error="errors.has('default.tax_number')"
            placeholder="Tax / ABN Number"
          >
          </HbTextField>
        </hb-form>
        <hb-form label = "Coordinates" :active.sync="active">
          <v-row>   
            <v-col cols="6" class="pt-0 pb-0 ma-0 pl-3 pr-3">
              <HbTextField
                v-model="formData.Address.lat"
                v-validate="'between:-90,90'"
                data-vv-scope="default"
                data-vv-name="latitude"
                data-vv-as="Latitude"
                :error="errors.has('default.latitude')"
                placeholder="Latitude"
              >
              </HbTextField>
            </v-col>
            <v-col cols="6" class="pa-0 ma-0">
              <HbTextField
                v-model="formData.Address.lng"
                v-validate="'between:-180,180'"
                data-vv-scope="default"
                data-vv-name="longitude"
                data-vv-as="Longitude"
                :error="errors.has('default.longitude')"
                placeholder="Longitude"
              >
              </HbTextField>
            </v-col>
          </v-row>
        </hb-form>
        <hb-form label = "Surrounding Cities" :active.sync="active">
          <hb-combobox   
              v-model="formData.Suburbs" 
              :items="suburbsList"
              item-text="name"
              return-object
              label="Surrounding Cities"
          ></hb-combobox>
        </hb-form>
        <hb-form label = "Market Region(s)" :active.sync="active" >
          <template v-slot:tooltipBody>
            This field is required to use Market Regions as criteria for creating a property groups. Market Regions represent areas where properties can be assigned to support business decisions based on marketing or financial considerations.
          </template>
          <hb-combobox   
              v-model="formData.MarketRegions" 
              :items="marketRegionsList"
              item-text="name"
              return-object
              label="Market Regions"
          ></hb-combobox>
        </hb-form>
        <hb-form label = "Merchandise Delivery" :active.sync="active">
          <hb-radio-group v-model="formData.merchandise_delivery" row>
              <hb-radio label="Yes" :value="1"></hb-radio>
              <hb-radio label="No" :value="0"></hb-radio>
          </hb-radio-group>
        </hb-form>
        <hb-form label = "Year Opened" :active.sync="active">
          <template v-slot:tooltipBody>
            This field is required to use the Year Opened as criteria for creating a property groups. Enter the year the property began operations.
          </template>
          <HbDatePicker
            v-model="formData.year_opened"
            @click:clear="formData.year_opened = null"
            label="MM/DD/YYYY"
            :clearable="true"
            clear-icon="mdi-close-circle"
            :solo="false"
            :single-line="true"
            data-vv-as="year_opened"
            :error="(isInvalidYear)"
          />
        </hb-form>
        <hb-form label = "Year Closed" :active.sync="active">
          <HbDatePicker
            v-model="formData.year_closed"
            @click:clear="formData.year_closed = null"
            label="MM/DD/YYYY"
            :clearable="true"
            clear-icon="mdi-close-circle"
            :solo="false"
            :single-line="true"
            data-vv-as="year_closed"
          />
        </hb-form>
        <hb-form label="Performance Category" :active.sync="active">
          <template v-slot:tooltipBody>
            This field is required to use the Performance Category as criteria for creating a property group. It includes three options: New Property: A newly built or acquired property. Expanding Property: A property that has recently undergone expansion. Performance Based: Properties classified as high-performing or performance-challenged based on the selected occupancy threshold.
          </template>
          <HbSelect 
            v-model="formData.performance_category"
            :items="performanceCategoryList"
            item-text="name"
            item-value="label"
            placeholder="Performance Category"
            :clearable="false"
          />
        </hb-form>
        <hb-form label = "Occupancy Threshold" :active.sync="active">
          <template v-slot:tooltipBody>
            This field is required to use the Occupancy Threshold as criteria for creating a property group. If "Performance Based" is selected under Performance Category, this threshold will determine whether a property is classified as high-performing or performance-challenged. The default occupancy threshold is set to 85.
          </template>
          <HbSelect 
            v-model="formData.occupancy_threshold"
            :items="occupancyThresholds"
            placeholder="Occupancy Threshold"
            :clearable="false"
          />
        </hb-form>
        <hb-form label="Management Contact" :active.sync="active">
          <template v-slot:tooltipBody>
            This field is required to use the Management Contact as criteria for creating a property group. Assign the responsible contact to this property to enable grouping and downloading reports associated with them.
          </template>
          <HbSelect 
            v-model="formData.operations_manager"
            :items="adminsList"
            item-text="name"
            item-value="contact_id"
            placeholder="Operations Manager"
            :clearable="false"
          />
        </hb-form>
        <hb-form label="Same Store Class" :active.sync="active">
          <template v-slot:tooltipBody>
            This field is required to use Same Store Class as criteria for creating a property group. Assign a rating of A, B, or C, or use a scale from 0-10 to classify the property based on its overall health.
          </template>
          <HbSelect 
            v-model="formData.same_store_class"
            :items="sameStoreClass"
            placeholder="Same Store Class"
            :clearable="false"
            item-text="value"
            item-value="value"
          />
        </hb-form>
        <hb-form label="Property Build type" :active.sync="active">
          <HbSelect 
              v-model="formData.build_type"
              :items="propertyBuildTypeList"
              item-text="name"
              item-value="label"
              placeholder="Property Build type"
              :clearable="false"
          />
        </hb-form>
        <hb-form label = "Property Web CMS ID" :active.sync="active">
          <HbTextField
              v-model="formData.web_cms_id"
              placeholder="Property Web CMS ID"
          >
          </HbTextField>
        </hb-form>
        <hb-form label = "General Ledger ID" :active.sync="active">
          <HbTextField
              v-model="formData.general_ledger_id"
              placeholder="General Ledger ID"
          >
          </HbTextField>
        </hb-form>
        <hb-form label="Entity" :active.sync="active" >
          <HbAutocomplete 
              v-model="formData.Entity"
              :items="entityList"
              item-text="name"
              return-object
              placeholder="Entity"
              @keydown.enter="setEntityList($event.target.value)"
          />
        </hb-form>
        <hb-form label = "BankID" :active.sync="active">
          <HbTextField
              v-model="formData.bank_id"
              placeholder="BankID"
          >
          </HbTextField>
        </hb-form>
        <hb-form label = "Tax Code" :active.sync="active">
          <HbTextField
              v-model="formData.tax_code"
              placeholder="E.g. SI-GSTTS-ABALZE"
          >
          </HbTextField>
        </hb-form>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="save">Save</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import {  mapActions } from "vuex";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import PHONE from "@/constants/phone.js";
import moment from 'moment';

export default {
  name: "AddProperty",
  mixins: [countryStateCityMixin, notificationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    suburbsList: {
      value: Array,
      default: []
    },
    marketRegionsList: {
      value: Array,
      default: []
    },
    adminsList: {
      value: Array,
      default: []
    },
    entityList: {
      value: Array,
      default: []
    },
    performanceCategoryList: {
      value: Array,
      default: []
    },
    propertyBuildTypeList: {
      value: Array,
      default: []
    },
  },
  components: {
    HbDatePicker
  },
  created() {
    this.setCountriesListEvent();
    this.formData.Address.country = this.defaultCountry;
    this.setPropertyStatesListEvent(this.formData.Address.country);
    this.setPropertyCountryCitiesListEvent(this.formData.Address.country);
    this.setOccupancyThresholdList();
  },
  data() {
    return {
      formData: {
        name: "",
        legal_name: '',
        number: "",
        Emails: [
          {
              id: null,
              type: "Main",
              email: ""
          }
        ],
        Phones: [
          {
              id: null,
              type: "Main",
              phone: ""
          }
        ],
        Address: {
          country: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          region: "",
          district: ""
        },
        Suburbs: [],
        MarketRegions: [],
        Entity: {},
        merchandise_delivery: 1,
        occupancy_threshold: 85,
      },
      active: '',
      countryCodesList: PHONE.COUNTRY_CODES,
      phoneCode: '1',
      phoneNumber: '',
      occupancyThresholds: [],
      operationsManager: {},
      sameStoreClass: [
                { header: "Type" },
                { value: "A", group: "Type" },
                { value: "B", group: "Type" },
                { value: "C", group: "Type" },
                { header: "Range" },
                { value: "0", group: "Range"},
                { value: "1", group: "Range"},
                { value: "2", group: "Range" },
                { value: "3", group: "Range" },
                { value: "4", group: "Range" },
                { value: "5", group: "Range" },
                { value: "6", group: "Range" },
                { value: "7", group: "Range" },
                { value: "8", group: "Range" },
                { value: "9", group: "Range" },
                { value: "10", group: "Range" },
            ],
      notification: false,
      isInvalidPhone: false,
      isInvalidYear: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions({
          addProperty: "propertiesStore/addProperty"
      }),
    setPropertyCountry(){
      this.formData.Address.state = ''
      this.formData.Address.city = ''
      this.setPropertyStatesListEvent(this.formData.Address.country)
      this.setPropertyCountryCitiesListEvent(this.formData.Address.country)
    },
    setPropertyState(){
        this.formData.Address.city = ''
        this.setPropertyCitiesListEvent(this.formData.Address.country, this.formData.Address.state)
    },
    setOccupancyThresholdList() {
      for(let i=0; i<101; i++) {
          this.occupancyThresholds.push(i);
      }
    },
    setPropertyCityList(value, toValidate = false){
      if(!value) return
      if(toValidate){
          const isSpecialCharacter = this.validateCityName(value)
          const cityValidation = this.errors.items.indexOf('property.city')
          this.errors.items.splice(cityValidation);
          if(isSpecialCharacter){
            return this.errors.add({
                      field: 'property.city',
                      msg: 'Please add a valid city name.'
                  }) 
          }
      }
      const isCityExists = this.propertyCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
      if(isCityExists) return

      this.propertyCityListMixin.push(value)
      const country = this.countriesListMixinRaw.find(item => item.name === this.formData.Address.country);
      this.addMissingCity({
          country_code: country.isoCode,
          state_code: this.formData.Address.state,
          city: value
      })
    },
    setSuburbList(value){
      if(!value) return
      
      const isSuburbExists = this.suburbsList.some((suburb) => suburb?.name?.toLowerCase() === value.toLowerCase())
      if(isSuburbExists) return
      this.suburbsList.push({name: value, id: null});
    },
    setEntityList(value){
      if(!value) return
      
      const isEntityExists = this.entityList.some((entity) => entity?.name?.toLowerCase() === value.toLowerCase())
      if(isEntityExists) return
      this.entityList.push({name: value, id: null});
    },
    checkValidPhone(code, number) {
      let phone = '+' + code + number;
      try {
          let parsedPhoneNumber = parsePhoneNumber(phone);
          this.isInvalidPhone = !parsedPhoneNumber.isValid();
      }
      catch(err) {
        this.isInvalidPhone = true;
      }
    },
    async save() {
      let status = await this.$validator.validateAll("default");
      if (!status) return
      else if(this.isInvalidPhone) {
        this.showMessageNotification({ id: this.$options.name, type: "error", description: 'Please Enter a Valid Phone Number' });
        return;
      }
      let data = this.formData;

      let yearOpened = moment(data.year_opened);
      let yearClosed = moment(data.year_closed);

      if(yearOpened.isAfter(yearClosed)) {
        this.isInvalidYear = true;
        this.showMessageNotification({ description: 'The Year Opened cannot be greater than the Year Closed' });
        return;
      }


      data.Phones[0].phone = '+' + this.phoneCode + this.phoneNumber;
      data.Suburbs = this.formatData(data.Suburbs);
      data.MarketRegions = this.formatData(data.MarketRegions);

      await this.addProperty(data).then(
        response => {
            this.showMessageNotification({type: `success`, description: `Property ${data.name} saved successfully`});
            this.$emit('close')
        },
        err => {
            this.showMessageNotification({ description: `Failed to save property ${data.name}`, list: [{ msg: err }] })
        }
      );


    },
    formatData(data){
      let formatted = [];

      for(let i=0; i<data.length; i++) {
        let unFormatted = data[i];
        
        if(typeof unFormatted == 'string') {
          formatted.push({name: unFormatted, id: null})
        }
        else {
          formatted.push(unFormatted)
        }
      }
      return formatted;
    },
  }
}
</script>
