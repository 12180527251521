export default {
  charmNotification: state => state.charmNotification,
  listViewItems: state => state.listViewItems,
  charmNotificationType: state => state.charmNotificationType,
  charmNotificationTitle: state => state.charmNotificationTitle,
  isCharmNotificationToastOpen: state => state.isCharmNotificationToastOpen,
  charmNotificationView: state => state.charmNotificationView,
  charmNotificationCount: state => state.charmNotificationCount,
  reservationsNotifications: state => state.reservationsNotifications,
  reservationsNotificationsCount: state => state.reservationsNotificationsCount,
  missedCallNotificationsCount: state => state.missedCallNotificationsCount,
  voicemailsNotificationsCount: state => state.voicemailsNotificationsCount,
  searchedContact: state => state.searchedContact,
  searchedContactDetails:state => state.searchedContactDetails,
  charmAgentAvailability:state =>  state.charm_agent_availability?.available ? true : false
};
