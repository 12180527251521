<template>
    <div>
        <div class="px-4 pt-3 pb-2">
            <div v-for="product in productServices" class="pb-1">
                <div class="align-start d-flex justify-space-between my-2">
                    <div class="pr-2 pt-1">
                        <div class="hb-button-text-regular font-weight-medium pb-2">
                            {{ product.name }}
                        </div>
                        <div class="hb-text-light hb-button-text-small">
                            {{ product.description }}
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div class="mr-2" style="" v-if="!merchandiseSettings || (merchandiseSettings && product.amount_type !== 'variable')">
                            <div class="d-flex justify-space-between">
                                <div class="d-flex align-center justify-space-between px-2 py-1" style="height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                                    <hb-icon color="#000" :custom-size="12" class="hand" @click="saveService(product, 'remove_quantity')">mdi-minus</hb-icon>
                                    <span class="d-inline-block px-3">{{ product.qty }}</span>
                                    <hb-icon color="#000" class="hand" :custom-size="12" @click="saveService(product, 'add_quantity')">mdi-plus</hb-icon>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2" style="width: 60px;text-align: right;">{{ product.service_price | formatMoney }}</div>
                        <hb-icon color="#637381" class="hand" @click="removeService(product)" small>mdi-close-circle</hb-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';

    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
		 
    export default {
        name: "ConfigureMerchandise",
		mixins: [ notificationMixin ],
        data() {
            return {
                products:[],
                intervalData: null,
                initialQuantity: 0
            }
        },
        async created (){
            this.$emit('update:loading', false)
        },
        props: {
            merchandiseProducts: {
                type: Array,
                default: []
            },
            merchandiseSettings: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            ...mapGetters({
                lease: 'onBoardingStore/lease',
                services: 'onBoardingStore/services',
            }),
            productServices(){
                let merchandProducts = this.merchandiseProducts.filter(p => p.default_type === 'product');
                let servicesTemp = this.services && this.services.length ? this.services.filter(p => p.Product.default_type === 'product').map(item => item.product_id) : [];
                if (servicesTemp.length) {
                    merchandProducts = merchandProducts.filter(p => servicesTemp.includes(p.id));
                    return merchandProducts.length ? merchandProducts : []
                }
                return [];
            }
        },
        methods: {
            async saveService(product, type){
                try {
                    if (!this.initialQuantity) {
                        this.initialQuantity = product.qty;
                    }
                    let status =  await this.validate(this);
                    if(!status) return;

                    if(!product.qty){
                        product.qty = 1
                        // this.showMessageNotification({ type: 'error', description: 'Please enter a valid Qty.' });
                        // return;
                    }else if(!product.service_price){
                        this.showMessageNotification({ type: 'error', description: 'Please enter a valid Price.' });
                        return; 
                    }

                    if (type === 'remove_quantity') {
                        if (product.qty > 1) {
                            product.qty -= 1;
                        } else {
                            return;
                        }
                    } else {
                        product.qty += 1;
                    }

                    clearTimeout(this.intervalData);
                    this.intervalData = setTimeout(async () => {

                        var data = {
                            name: product.name,
                            price: product.service_price,
                            qty: parseInt(product.qty),
                            recurring: 0,
                            prorate: 0,
                            prorate_out: 0,
                            description: product.description,
                            start_date: this.lease.start_date,
                            end_date: null,
                            taxable:product.taxable || 0
                        };
                        let r = {};
                        if(!product.Service){
                            data.product_id = product.id;
                            r = await api.post(this, api.LEASES + this.lease.id + '/services', data);
                            await this.$store.dispatch('onBoardingStore/getServices');
                        } else {
                            r = await api.put(this, api.LEASES +  this.lease.id  + '/services/' + product.Service.id, data)
                            await this.$store.dispatch('onBoardingStore/getServices');
                        }
                        this.$emit('update:loading', true);
                        this.$emit('fetchServices');
                        await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                        EventBus.$emit('updatePaymentDetails');
                        EventBus.$emit('updatePartialPaymentAmount');
                    }, 1000);
                } catch (error) {
                    this.showMessageNotification({ description: error });
                    product.qty = this.initialQuantity;
                    this.initialQuantity = 0;
                }
            },

            async removeService(product){
                product.qty = 0;
                let r = await api.delete(this, api.SERVICES, product.Service.id);
                await this.$store.dispatch('onBoardingStore/getServices');
                this.$emit('fetchServices');
                await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');

            }
        }
    }
</script>
