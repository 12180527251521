<template>
  <div>
    <div class="px-6 py-4" v-if="invoice.id" style="background: #F9FAFB;">
      <v-row class="mx-0">
        <v-col cols="5" class="pa-0 pr-4">
          <v-card>
            <v-card-title class="pa-4 pb-0">
              <span class="subtitle-1 font-weight-medium text-capitalize">{{invoice.Contact.first}} {{invoice.Contact.last}} </span>
            </v-card-title>
            <v-card-text class="px-4">
              <span class="subtitle-1" v-if="invoice.Contact.Phones && invoice.Contact.Phones.length > 0">{{invoice.Contact.Phones[0].phone | formatPhone}} </span><br>
              <span class="subtitle-1"> {{contactAddress.address}}</span><br>
              <span class="subtitle-1"> {{ contactAddress | formatAddress }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7" class="pa-0">
          <v-card>
            <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Invoice ID</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Invoice Date</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Due Date</span>
              </v-col>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="d-flex pa-0 ma-0 hb-default-font-size">
              <v-col cols="4">
                <span class="subtitle-1 font-weight-regular hb-text-night">{{invoice.number}}</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center pr-0">
                <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{invoice.date | formatDateServices}}</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center pr-0">
                <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{invoice.due | formatDateServices}}</span>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-4">
        <v-col class="pa-0">
          <v-card>
            <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
              <v-col cols="7" class="d-flex align-center">
                <span class="ml-3 subtitle-2 font-weight-medium">Description</span>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-end px-0">
                <span class="subtitle-2 font-weight-medium">Qty</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end px-0">
                  <span class="subtitle-2 font-weight-medium">Rate</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end pl-0">
                <span class="subtitle-2 font-weight-medium">Total</span>
              </v-col>

            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0 ma-0 hb-default-font-size">
              <v-row class="d-flex pa-0 mx-0 space-header mb-1">
                <v-col cols="5" class="d-flex align-center py-1">
                  <span class="ml-3 subtitle-2 font-weight-medium " v-if="invoice.Lease">Space #{{invoice.Lease.Unit.number || ''}}</span>
                </v-col>
                <v-col cols="7" class="d-flex align-center py-1 pl-0 justify-end">
                  <span class="subtitle-2 font-weight-regular">Invoice #{{invoice.number || ''}}</span>
                  <span class="ml-1 subtitle-2 font-weight-medium"> - Not Generated</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0" v-for="(line, index) in invoice.InvoiceLines" :key="index">
                <v-col cols="4" class="d-flex align-center">
                  <v-select
                    :items="productTypes"
                    item-text="name"
                    item-value="id"
                    hide-details
                    single-line
                    placeholder="Select a Product"
                    class="pa-0 ma-0 text-capitalize"
                    v-model="line.type"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="d-flex align-center">
                  <v-select
                    :items="productItems(line)"
                    item-text="name"
                    return-object
                    hide-details
                    single-line
                    placeholder="Select an Item"
                    class="pa-0 ma-0 text-capitalize"
                    @change="addProductToInvoice(line.Product, index)"
                    v-model="line.Product"
                  ></v-select>


                </v-col>
                <v-col cols="1" class="d-flex align-center justify-end px-0 py-1" :class="{'pr-1' : isAdjusted}">
                  <v-text-field
                      v-if="isAdjusted && line.type == 'merchandise'"
                      hide-details
                      single-line
                      v-validate="'required|max:45'"
                      required
                      :id="'qty-'+index"
                      :name="'qty-'+index"
                      v-model="line.qty"
                      class="px-0 view-invoice-txt-field pb-1"
                  ></v-text-field>
                  <span v-else-if="line.type == 'merchandise'" class="subtitle-2 font-weight-regular hb-text-night">{{line.qty}}</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end px-0 py-1">
                  <v-text-field
                      v-if="isAdjusted"
                      hide-details
                      single-line
                      v-validate="'required|max:45'"
                      required
                      :id="'cost-'+index"
                      :name="'cost-'+index"
                      v-model="line.cost"
                      prefix="$"
                      class="px-0 view-invoice-txt-field pb-1"
                  ></v-text-field>
                  <span v-else class="subtitle-2 font-weight-regular hb-text-night">{{line.cost | formatMoney}}</span>
                </v-col>
                <v-col cols="2" class="text-right pl-0" :class="{'top-8px' : isAdjusted}">
                  <span class="subtitle-2 font-weight-regular hb-text-night">{{line | lineTotal | formatMoney}}</span><br/>
                  <div v-if="isAdjusted">
                    <hb-link @click="removeProduct(index)">Remove</hb-link>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mx-0" v-if="isAdjusted">
                <v-col cols="7" class="d-flex align-center">
                  <hb-link @click="addProductType">+ Add Charge</hb-link>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-end px-0 py-1"></v-col>
                <v-col cols="2" v-if="showAddProduct" class="d-flex align-center justify-end pl-0 py-1">
                    <hb-link @click="showAddProduct = false">Remove</hb-link>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                  <span class="subtitle-1">Subtotal:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                  <span class="subtitle-1">{{ subTotal | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                  <span class="subtitle-1">Tax:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                  <span class="subtitle-1">{{taxSum | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-2"></v-divider>
              <v-row class="d-flex pa-0 mx-0 inv-header-cell">
                <v-col cols="7" class="d-flex pb-0 pt-2" style="flex-direction:column;" >
                </v-col>
                <v-col cols="3" class="d-flex justify-end px-0">
                  <span class="title font-weight-medium">Balance Due:</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end pl-0">
                  <span class="title font-weight-medium">{{ balanceDue | formatMoney }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>


      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">

import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import { isEqual } from "lodash";

export default {
  name: "CreateNewInvoice",
  data() {
      return {
          invoice:{},
          oldInvoice:{},
          payments:[],
          showAddProduct:false,
          showMakePayment:false,
          products: [],
          charge:{
              product_id: null,
              service_id: null,
              cost: null,
              qty: null
          },
          product:{},
          productTypes: [
            {id: 'merchandise', name: 'Merchandise'},
            {id: 'fee', name: 'Fee'},
            {id: 'adjustment', name: 'Rent Adjustment Product'}
          ],
          productItemsByType: {
            merchandise: [],
            fee: [],
            adjustment: []
          }
      }
  },

  async created (){
    this.invoice = this.selected;
    //for merchandise type is product
    //for fee type is late
    //for rent adjustment product type is rent with category_type ajustment
    var params = `${this.property_id}/products?type=`;
    this.productItemsByType.merchandise = await this.getProductsByType(params + `product`);
    this.productItemsByType.fee = await this.getProductsByType(params + `late`);
    this.productItemsByType.adjustment = await this.getProductsByType(params + `rent&category_type=adjustment`);
  },
  filters:{
    lineTotal(c){
      return c.qty * c.cost;
    }
  },
  computed:{
    ...mapGetters({
        isAdmin: 'authenticationStore/isAdmin',
        leasedProperties: 'propertiesStore/leasedProperties'
    }),
    isPastDue(){
        return this.balanceDue && moment() > moment(this.invoice.due, 'MM/DD/YYYY');
    },
    balanceDue(){
      return this.invoiceTotal - this.totalPayments;
    },
    totalPayments(){
      if(!this.invoice.Payments) return 0;
      return this.invoice.Payments.reduce(function(a, b){
        return a + b.amount;
      }, 0);
    },
    promotionTotal(){
      var discountTotal = 0;
      if(!this.invoice.InvoiceLines) return 0;
      this.invoice.InvoiceLines.forEach(function(line){
          discountTotal += line.DiscountLines.reduce(function(a,b){
              return a += b.amount;
          }, 0);
      });
      return discountTotal;
    },
    taxSum(){
      if(!this.invoice.InvoiceLines) return 0;
      return this.invoice.InvoiceLines.reduce(function(a, b){
          var lineTotal = (b.cost * b.qty);
          if(!b.Product.taxable) return a;
          var notTaxed = b.DiscountLines.filter(function(d){
              return d.pretax;
          }).reduce(function(c,d){
              return c + d.amount;
          }, 0);
          var taxableAmount = lineTotal - notTaxed;
          var lineTax =  b.TaxLines && b.TaxLines.length && b.TaxLines.reduce(function(c,d){
              return c +=  (taxableAmount *  (d.taxrate/100))
          }, 0);
          return a += lineTax;

      }, 0)
    },
    subTotal(){
      if(!this.invoice.InvoiceLines) return 0;
      return  this.invoice.InvoiceLines.reduce(function(a, b){
          return a +=  (b.cost * b.qty)
      }, 0);
    },
    invoiceTotal(){
      return this.taxSum + this.subTotal - this.promotionTotal;
    },
    contactAddress() {
      if(this.invoice) {
        return this.invoice.Contact.Addresses.length > 0 ? this.invoice.Contact.Addresses[0].Address: ''
      }
    },
    leasedProperty() {
      if(this.property_id) {
        return this.leasedProperties.filter(lp => lp.id == this.property_id)[0];
      }
    },
  },
  methods: {
    ...mapActions({
      getProductsByType: 'propertiesStore/getProductsByType'
    }),
    showPamentDetails(p){
      EventBus.$emit('show_lease_payment', p.payment_id)
    },
    payInvoice(){
        this.$router.push('/invoices/pay/'+ this.invoice_id)
    },
    leaseEvents(){
        this.fetchData();
        this.fetchPayments();
    },
    prevent(event){
        event.stopPropagation();
    },
    fetchData(){
        if(!this.invoice_id) return;
        api.get(this, api.INVOICES + this.invoice_id).then(r => {
            this.invoice = r.invoice;
            this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
            this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
        });
    },
    fetchPayments(){
        if(!this.invoice_id) return;
        api.get(this, api.INVOICES + this.invoice_id + '/payments').then(r => {
            this.payments = r.payments;
            this.payments.date = moment(this.payments.date).format('MM/DD/YYYY');
        });
    },
    unapplyPayment(p){
        api.delete(this, api.PAYMENTS + 'apply/', p.id).then(r => {
            this.$refs.unapplyDropdown[0].away();
            EventBus.$emit('lease_payment_saved');
        });
    },
    removeProduct(index){
      if(this.invoice.InvoiceLines){
        this.invoice.InvoiceLines.splice(index, 1);
      }
    },
    away(){
        return this.openSuggestion = false;
    },
    email(){

    },
    download(){

    },
    cancelUnapply(){
        this.$refs.unapplyDropdown[0].away()
    },
    productItems(product) {
      return this.productItemsByType[product.type];
    },
    addProductType() {
      var newLine = {
          type: null,
          Product: {},
          product_id: null,
          cost: 0,
          qty: 1,
          DiscountLines:[],
          TaxLines:[],
      };
      this.invoice.InvoiceLines.push(newLine);
      this.charge = {
          product_id: null,
          cost: null,
          qty: null
      };
      this.product = {};
    },
    addProductToInvoice(product, index){
      this.invoice.InvoiceLines[index].product_id = product.id;
      this.invoice.InvoiceLines[index].cost = product.price || 0;
      this.invoice.InvoiceLines[index].qty = this.charge.qty || 1;

      this.charge = {
          product_id: null,
          cost: null,
          qty: null
      };
      this.product = {};
    },
    createInvoice(){
      let data = {
            dryrun: true,
            lease_id: this.invoice.lease_id,
            contact_id: this.invoice.Contact.id,
            date: this.invoice.date,
            due: this.invoice.due,
            period_start: this.invoice.period.start,
            period_end: this.invoice.period.end,
            InvoiceLines: this.invoice.InvoiceLines,
            Contact: this.invoice.Contact,
            Property: this.leasedProperty
          }
          api.post(this, api.LEASES + this.invoice.lease_id + '/create-invoice', data).then(r => {
            if(r.invoice && r.invoice.InvoiceLines) {
              this.oldInvoice = JSON.parse(JSON.stringify(r.invoice));
              this.invoice.InvoiceLines = r.invoice.InvoiceLines;
            }
          })
    }
  },
  props:['selected', 'readonly', 'isAdjusted', 'property_id'],
  watch:{
    selected:function(newInvoice){
      this.invoice = newInvoice;
      this.oldInvoice = JSON.parse(JSON.stringify(newInvoice));
    },
    "invoice.InvoiceLines": {
      handler(newInvoiceLines){
        console.log("new invoice line:",newInvoiceLines);
        console.log("old invoice line:",this.oldInvoice.InvoiceLines);
        if(!isEqual(newInvoiceLines, this.oldInvoice.InvoiceLines) && newInvoiceLines.length && (newInvoiceLines.reduce((inital, line) => inital + line.cost, 0) > 0)){
          this.createInvoice();
        }
      },
      deep: true
    }
  }
}

</script>

<style scoped>

  span{
    color: #101318;
  }

  .inv-header-cell{
    background: #F4F6F8;
  }

  .space-header {
    background: #F9FAFB;
    box-shadow: inset 0px -1px 0px #DFE3E8;
  }
  .hb-payment {
    color: #306FB6;
  }
  .top-8px{
    position:relative;
    top:8px
  }
</style>

<style lang="scss">
  .view-invoice-txt-field{
    max-width: 50px;
    .v-input__slot{
      &:before{
        border-color: rgba(0, 0, 0, 0.15) !important;
        border-bottom-left-radius: 5px;
      };
      input{
        text-align: right !important;
        padding-bottom: 0;
        padding-top: 0;
      }
    } 
  }
</style>
