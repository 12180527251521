<template>
  <div class="flex-panel-container">

    <hb-header
      style="display: block;"
      class="flex-shrink"
      one-action
      align-top
      :padding="false"
      :divider="false"
    >
        <template v-slot:left>
            <mini-profile-view
                v-if="contact?.id"
                class="pt-4 pb-2 px-6"
                :contact-id="contact.id"
                :use-contact="false"
                :contact-data="contact"
            />
        </template>
        <template v-slot:right>
            <hb-btn class="pt-1 pr-1" icon tooltip="Close" @click="$emit('close')" active-state-off>mdi-close</hb-btn>
        </template>
    </hb-header>

    <div class="flex-shrink">
        <!-- SUB NAV -->

        <v-sheet>
          <hb-tabs
            v-model="contactTab"
            background-color="#ffffff"
        >
            <v-tab
              v-for="menuOption in menuOptions"
              :key="menuOption.key"
              :href="'#tab-' + menuOption.key"
              @click="view = menuOption.key"
            >
              {{ menuOption.label }}
            </v-tab>
          </hb-tabs>
          <v-divider class="ma-0 pa-0"></v-divider>
        </v-sheet>

        <!-- END SUBNAV -->
      </div>

      <v-card flat class="edit-panel flex-grow">
          <v-sheet>
            <contacts-overview
                v-if="view === 'overview' && contact.id"
                :contact="contact"
                @close="$emit('close')"
                ref="leadOverviewRef"
                :application="application"
            >
            </contacts-overview>
            <!--BCT : CHR-151 -> Added 'currentPropertyId' prop.-->
            <chat-window 
            hideHeader 
            chatWindowHeight="medium" 
            layout="leads-list"
            :dense="true" 
            v-if="view === 'interactions' && contact.id"  
            :InteractionObj="contact" 
            :context="interactionContext"
            :activeReservations="getActiveReservations()"
            ref="LeadChatWindowRef"
            >
            </chat-window>
            <view-lead v-if="view === 'lead' && contact.id" :contact_id="contact.id"></view-lead>
            <contact class="mt-n4" v-if="view === 'contact' && contact.id" :contact="contact"></contact>
            <access class="pl-5 pr-6" v-if="view === 'access' && contact.id" :contact="contact"></access>
          </v-sheet>
        </v-card>



    <v-toolbar flat class="panel-footer position-relative flex-shrink">
        <v-spacer></v-spacer>
          <span>
            <hb-btn color="primary" :disabled="isLoading" @click="moveIn(contact.id)" v-if="contact.id">Create New Lead/Move-In</hb-btn>
          </span>
          <v-progress-circular v-show="isLoading" width="1" size="12" indeterminate color="primary" ></v-progress-circular>
       </v-toolbar>
  </div>
</template>

<script type="text/babel">
    import Interactions from '../../contacts/Interactions.vue'
    import ViewLead from '../../leads/ViewLead.vue'
    import CreditCheck from '../../applications/CreditCheck.vue'
    import ContactsOverview from '../../contacts/ContactsOverview.vue'
    import Contact from '../../contacts/Contact.vue'
    import Access from '../../contacts/Access.vue'

    import Dropdown from '../../assets/Dropdown.vue'
    import DropdownMenu from '../../assets/DropdownMenu.vue'
    import AddInteraction from '../../contacts/AddInteraction.vue'
    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';
    import ChatWindow from '../../communication_center/ChatWindow.vue'
    import MiniProfileView from '../LeadIntake/MiniProfileView.vue'

    export default{
        name: "ContactView",
        data() {
            return {
                contact: {
                    id: null
                },
                application: {
                    id: null
                },
                selectedApplication:{},
                contactTab: 'tab-overview',
                interactionContext: 'contact',
                recordInteraction: false,
                showContact: false,
                view: null,
                styleProps: {width: '75px'},
                lead_statuses: ['active', 'new', 'prospect'],
                menuOptions: [
                    {

                        key: 'overview',
                        label: 'Overview'
                    },
                    // {

                    //     key: 'lead',
                    //     label: 'View Lead'
                    // },
                    {

                        key: 'contact',
                        label: 'Contact Details'
                    },
                    {
                        key: 'interactions',
                        label: 'Communication'
                    },
                    {
                        key: 'access',
                        label: 'Access'
                    }

                ],
                isLoading: false,
            }
        },
        props: {
            contact_id: {
                type: String
            },
            application_id:{
                type: String,
                required: false
            },
            show: {
                type: String
            },
            fromLeaseWidget: {
                type: Boolean
            }
        },
        components:{
            Interactions,
            ViewLead,
            ContactsOverview,
            Contact,
            Dropdown,
            DropdownMenu,
            Access,
            AddInteraction,
            CreditCheck,
            ChatWindow,
            MiniProfileView
        },
        created(){
            this.view = this.show || 'overview';
            this.contactTab = 'tab-' + this.view;
            EventBus.$on('contact_updated', this.fetchData);
            EventBus.$on('priority_action', this.closeWindow);
            this.fetchData();
            if(this.application_id){
                this.fetchApplication()
            }
             EventBus.$on('setView', this.setView);
        },
        destroyed(){
            EventBus.$off('contact_updated', this.fetchData);
            EventBus.$off('priority_action', this.closeWindow);
            EventBus.$off('setView', this.setView);
        },
        computed:{
            computedContactTitle() {
                return (this.contact.first ? this.contact.first : '') + (this.contact.middle ? ' ' + this.contact.middle : '') + (this.contact.last ? ' ' + this.contact.last : '');
            },
            computedContactPhone() {
                if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
                    return this.contact.Phones[0].phone;
                } else {
                    return undefined;
                }
            },
            computedContactPhoneVerified() {
                if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
                    return true;
                } else {
                    return false;
                }
            },
            computedContactAddress() {
                if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
                    const address_1 = this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '';
                    const address_2 = this.contact.Addresses[0].Address.address2 ? this.contact.Addresses[0].Address.address2 : '';

                    let address = address_1;
                    if(address_2 != '') address += ' ' + address_2;
                    
                    address += ', ' + this.$options.filters.formatAddress(this.contact.Addresses[0].Address); 
                    
                    return address;
                } else {
                    return undefined;
                }
            },
            computedRepresentativeName() {
                if (this.contact.Representative) {
                return (this.contact?.Representative?.Contact?.first ? this.contact?.Representative?.Contact?.first : '') + (this.contact?.Representative?.Contact?.last ? ' ' + this.contact?.Representative?.Contact?.last : '');
                }
                return undefined;
            },
        },
        methods:{
            async moveIn(contact_id){

                this.isLoading = true;
                if (this.view === 'overview') {
                    this.$refs.leadOverviewRef.showBlade();
                } else {
                    await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: contact_id});
                    EventBus.$emit('new_lead',{location: "from_contact_view"});
                    this.$emit('close');
                }
            },
			getActiveReservations(){
            //BCT : CHR-151 adding address details active reservations.
            const reservations = this.$refs.leadOverviewRef?.activeReservations ?? [];
            const {Reservations: contactReservations} = this.contact;
            for(const reservation of reservations){
               for(const res of contactReservations){
                 if(res.Lease.Unit.number == reservation.Unit.number){
                    reservation.Address = res.Lease?.Unit?.Address || {};
                 }
               }
            }
            return reservations;
            },
            closeWindow(){
                this.$emit('close');
            },
            fetchData(){
                api.get(this, api.CONTACTS + this.contact_id).then(r => {
                    this.contact = r.contact;
                });
            },
            fetchApplication(){
                api.get(this, api.APPLICATIONS + this.application_id).then(r => {
                    this.application = r.application;
                });
            },
            closeContactWindow(){
                this.$refs.contactDropdown.hideListbox();
            },
            closeInteractionWindow(){
                this.$refs.interactionDropdown.hideListbox();
            },
            updateInteractions(){
                // TODO Update interactions window
            },
            addToCurrentLease(){
                this.$store.commit('addContact', this.contact);
                this.$router.push('/configure-lease');
            },
            resetContact(){
                this.contact = {
                    id: null
                }
            },
            sendMessageEvent(){
                this.contactTab = 'tab-interactions';
                this.view = 'interactions';
                setTimeout(() => {
                    EventBus.$emit('contact_modal_go_email');
                }, 200);
            },
            setView(view){
                this.view = view;
            },
            smsClicked(){
                this.setView('interactions');
                setTimeout(() => {
                    this.$refs.LeadChatWindowRef?.phoneNumberClicked();
                }, 1000);
            }
        },
        watch:{
            contact_id(){
                this.resetContact();
                this.fetchData();
            },
            application_id(){
                this.fetchApplication();
            },
            show(){
                this.view = this.show;
            },
            '$route': function(){
                this.$emit('close');
            }
        }

    }


</script>

<style scoped>

  .slide-out-tenant-header{
    border-bottom: none;
  }

  .property-light-black{
    color:#677380;
  }

  .property-transparent{
    background: transparent;
  }

  .property-mailto{
    color:#306FB6;
    text-decoration:none;
  }
  .edit-panel {
    overflow-y: auto;
    bottom: 0px;
    overflow-x: hidden;
    background-color: #F9FAFB;
  }


  .flex-panel-container{
    display: flex;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .flex-grow{
    flex: 1;
  }
  .flex-shrink{
    flex: 0 1;
  }
</style>
