import timeDifferenceFn from '../time_diff';

export default {
  setCharmAgentAvailability(state, data){ 
    state.charm_agent_availability = data;
  },
  setShowCharmNotification(state, data) {
    state.isCharmNotificationToastOpen = data.value || false;
    state.charmNotificationType = data.type || "";
    state.charmNotificationView = data.view;
  },

  setToDefaultView(state, payload) {
    state.charmNotification = {};
    state.charmNotification = {...payload};
    // Initial time
    state.charmNotification.call_back_time = timeDifferenceFn.callBackTime(state.charmNotification.time);
    state.charmNotificationView = "default";
    state.charmNotificationTitle = "";
  },

  setToListView(state, title) {
    state.charmNotificationView = "list";
    state.charmNotificationTitle = title;
  },

  // Every 60 sec 
  setCallBackTimer(state) {
    state.charmNotification.call_back_time = timeDifferenceFn.callBackTime(state.charmNotification.time);
  },
  
  setMissedCallNotifications(state, payload) {
    if (state.charmNotificationType == 'missed calls') {
      state.listViewItems = updateFollowUpTime(payload);
      state.charmNotificationCount = state.listViewItems.length;
    }
    state.missedCallNotifications = [];
    state.missedCallNotifications = [...payload];
    state.missedCallNotificationsCount = state.missedCallNotifications.length;
  },
  setReservationNotifications(state, payload) {
    if (state.charmNotificationType == 'reservations') {
      state.listViewItems = updateFollowUpTime(payload);
      state.charmNotificationCount = state.listViewItems.length;
    }
    state.reservationsNotifications = [];
    state.reservationsNotifications = [...payload];
    state.reservationsNotificationsCount = state.reservationsNotifications.length;
  },
  setVoiceMailNotifications(state, payload) {
    if (state.charmNotificationType == 'voicemails') {
      state.listViewItems = updateFollowUpTime(payload);
      state.charmNotificationCount = state.listViewItems.length;
    }
    state.voicemailsNotifications = [];
    state.voicemailsNotifications = [...payload];
    state.voicemailsNotificationsCount = state.voicemailsNotifications.length;
  },
  setReadNotification(state, payload) {
    state.listViewItems = [...state.listViewItems.filter(f => f.id !== payload.id)];
    state.listViewItems = updateFollowUpTime(state.listViewItems);  
    state.charmNotificationCount = state.listViewItems.length;
    if (payload.status === 'reservation') {
      state.reservationsNotificationsCount = state.listViewItems.length;
      state.reservationsNotifications = [...state.listViewItems];
    }
    if (payload.status === 'voicemail') {
      state.voicemailsNotificationsCount = state.listViewItems.length;
      state.voicemailsNotifications = [...state.listViewItems];
    }
    if (payload.status === 'missed') {
      state.missedCallNotificationsCount = state.listViewItems.length;
      state.missedCallNotifications = [...state.listViewItems];
    }
  },
  notificationDataFromStore(state, payload) {
    state.charmNotificationView = "list";
    state.charmNotificationType = payload.type;
    state.charmNotificationTitle = payload.title;
    if (payload.type == "reservations") {
      state.listViewItems = updateFollowUpTime(state.reservationsNotifications);
      state.reservationsNotificationsCount = state.listViewItems.length;
    } else if (payload.type == "missed calls") {
      state.listViewItems = updateFollowUpTime(state.missedCallNotifications);
      state.missedCallNotificationsCount = state.listViewItems.length;
    } else if (payload.type == "voicemails") {
      state.listViewItems = updateFollowUpTime(state.voicemailsNotifications);
      state.voicemailsNotificationsCount = state.listViewItems.length;
    }
    state.charmNotificationCount = state.listViewItems.length;
  },

  // Every 60 sec 
  setFollowUpTimer(state) {
    state.listViewItems = updateFollowUpTime(state.listViewItems);
  },
  saveSelectedContact(state, payload) {
    if(payload && payload.id) {
      state.searchedContact = {};
      state.searchedContact = {...payload};
      state.charmNotification.selected_contact_id = payload.id;
      state.charmNotification.selected_contact_name = `${payload.first} ${payload.last}`;
      state.charmNotification.selected_contact_status = payload.status;
      state.charmNotification.selected_contact_phone = `${payload.Phones && payload.Phones.length && payload.Phones[0].phone}` || 'NA';
      state.charmNotification.selected_contact_email = payload.email;
      state.charmNotification.selected_contact_address = payload.contact_address;
      state.charmNotification.selected_contact_access_codes = payload.Access;
      state.charmNotification.selected_contact_units = payload.contactUnits;
      state.charmNotification = {...state.charmNotification};
    }
  },
  removeSelectedContact(state){
    state.charmNotification.selected_contact_id = null;
    state.charmNotification.selected_contact_name = null;
    state.charmNotification.selected_contact_status = null;
    state.charmNotification.selected_contact_phone = null;
    state.charmNotification.selected_contact_email = null;
    state.charmNotification.selected_contact_address = null;
    state.charmNotification.selected_contact_access_codes = null;
    state.charmNotification.selected_contact_units = null;
    state.charmNotification.contact_added_to_selected_contact = false;
    state.charmNotification.phone_number_added_to_selected_contact = false;
    state.charmNotification = {...state.charmNotification};
    this.searchedContact ={};
    state.searchedContactDetails ={};
  },
  setAdditionalContact(state, contact) {
    state.searchedContactDetails?.alternate_contacts?.push(contact);
    state.charmNotification.contact_added_to_selected_contact = true;
    state.charmNotification = {...state.charmNotification};
  },

  setAdditionalPhone(state, contact_id) {
    state.searchedContactDetails?.alternate_phones?.push(contact_id);
    state.charmNotification.phone_number_added_to_selected_contact = true;
    state.charmNotification = {...state.charmNotification};
  }, 
  
  setDetailsOfSearchedContact(state,data){
    state.searchedContactDetails = data;
  },
  setContactUnitsNotification(state,data){
    state.charmNotification.contact_units = [];
    state.charmNotification.contact_units = data.contact_units;
  },

  setLoadingAction(state, data) {
    if (data.status) {
      state.charmNotification.loading = data.eventStatus;
      state.charmNotification = {...state.charmNotification};
    } else if (data.eventStatus === state.charmNotification.loading) {
      state.charmNotification.loading = false;
      state.charmNotification = {...state.charmNotification};
    }
  },

  stopLoadingAnimation(state) {
    state.charmNotification.loading = false;
    state.charmNotification = {...state.charmNotification};
  }

};

function updateFollowUpTime(list) {
  list.forEach((item, i) => {
    list[i].follow_up_time = timeDifferenceFn.follwUpTime(item.time);
  })
  return [...list];
}
