<template>
    <div>
        <div class="px-4 pt-3 pb-2">
            <div v-for="product in feesServices" class="pb-1">
                <div class="align-start d-flex justify-space-between my-2">
                    <div class="pr-2">
                        <div class="hb-button-text-regular font-weight-medium pb-2">
                            {{ product.name }}
                        </div>
                        <div class="hb-text-light hb-button-text-small">
                            {{ product.description }}
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div class="mr-2 pt-1" style="width: 60px;text-align: right;">{{ product.service_price | formatMoney }}</div>
                        <hb-icon color="#637381" class="hand" @click="removeService(product)" small>mdi-close-circle</hb-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
	import { notificationMixin } from  '../../../mixins/notificationMixin.js';
	
    export default {
        name: "ConfigureMerchandise",
		mixins: [ notificationMixin ],
        data() {
            return {
                products:[],
                updated: false
            }
        },
        components:{
            HbDatePicker
        },

        created (){           
            EventBus.$on('remove_check', this.removeCheck);
        },
        props: {
            feesProducts: {
                type: Array,
                default: []
            }
        },
        computed: {
            ...mapGetters({
                lease: 'onBoardingStore/lease',
                services: 'onBoardingStore/services',
                fees: 'onBoardingStore/fees',
            }),

            feesServices(){
                let feesProducts = this.feesProducts.filter(p => p.default_type === 'late');
                if (this.feesProducts.length > 0) {
                    this.defualt_products = JSON.parse(JSON.stringify(this.feesProducts));
                }
                let servicesTemp = this.services && this.services.length ? this.services.filter(p => p.Product.default_type === 'late').map(item => item.product_id) : [];
                if (servicesTemp.length) {
                    feesProducts = feesProducts.filter(p => servicesTemp.includes(p.id));
                    return feesProducts.length ? feesProducts : []
                }
                return [];
            },

            filtered_products: {
                get(){
                    return this.feesProducts.filter(p => p.default_type === 'late')
                    .map(p => {
                        let service = this.services.find(s => s.product_id === p.id);
                        if (!service) {
                            return p;
                        }
                    }).filter(item => item);
                }
            }
        },
        methods: {
            async updateService(product){
                // && !this.updated

              if(product.activated){
                  console.log("PRODUCT_SAVE", product );
                  await this.saveService(product)
              } else {
                  console.log("PRODUCT_DELETE", product );
                  await this.removeService(product)
              }
            },
            async saveService(product){
                let status =  await this.validate(this);
                if(!status) return;
                if(!product.price){                   
                    this.showMessageNotification({ type: 'error', description: 'Please enter a valid Price.' });
                    return;   
                };
                let r = await api.get(this, api.LEASES + this.lease.id + '/services/' );
                let service = r.services.find((el,idx) => el.product_id === product.id);
                let defualt_product = this.defualt_products.find(x=> x.id === product.id);
                var data = {
                    name: product.name,
                    price: product.service_price || 0,
                    qty: 1,
                    recurring: !!product.recurring,
                    prorate: !!(product.recurring && defualt_product.prorate),
                    prorate_out: !!(product.recurring && defualt_product.prorate_out),
                    description: product.description,
                    start_date: product.start_date,
                    end_date: null,
                    taxable:product.taxable || 0
                };
                if(!service){
                    data.product_id = product.id;
                    await this.$store.dispatch('onBoardingStore/saveService', data);
                    this.$emit('fetchServices');
                    EventBus.$emit('updatePaymentDetails');
                    EventBus.$emit('updatePartialPaymentAmount');
                } else {
                    await this.$store.dispatch('onBoardingStore/updateService', {data: data, service_id: service.id});
                    this.$emit('fetchServices');
                    EventBus.$emit('updatePaymentDetails');
                    EventBus.$emit('updatePartialPaymentAmount');
                }
            },
            async removeService(product){
                let r = await api.get(this, api.LEASES + this.lease.id + '/services/' );
                let service = r.services.find((el,idx) => el.product_id === product.id);
                if(!service && !service.id) return;
                product.activated = false;
                await this.$store.dispatch('onBoardingStore/deleteService', {service_id: service.id});
                this.$emit('fetchServices');
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');
            },
            async removeCheck(product){
                let service = this.filtered_products.find((el,idx) => el.id === product.Product.id);
                service.activated = false;
                if(service.recurring) service.recurring = false;
            }
        },
        destroyed () {
            EventBus.$off('remove_check', this.removeCheck);
        }
    }
</script>