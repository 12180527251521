<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <hb-modal
      size="large"
      :title="(editMode ? 'View / Edit' : 'Add') + ' Feature and Amenity'"
      v-model="dialog"
      @close="onCloseConfirmationDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Main content -->
      <template v-slot:content>
        <hb-modal
          v-model="showConfirmationDialog"
          size="medium"
          title="Discard Setup"
          confirmation
          show-help-link
        >
          <!-- Confirmation Modal -->
          <template v-slot:content>
            <div class="px-6 py-4">
              Are you sure you want to discard this setup?
            </div>
            <div class="px-6 pb-4">
              If you do, you will lose all the information you’ve entered.
              Continue the setup process to ensure Hummingbird saves your
              information.
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('close')">Discard</hb-btn>
          </template>
        </hb-modal>
        <!-- Tabs -->
        <hb-stepper v-model="stepper">
          <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(1)"
              :complete="steps[0].status == 'step-complete'"
              :rules="[() => steps[0].status !== 'step-error']"
              key="1-step"
              step="1"
            >
              Define Values
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              :complete="steps[1].status == 'step-complete'"
              :rules="[() => steps[1].status !== 'step-error']"
              key="2-step"
              step="2"
            >
              Apply Defaults
            </hb-stepper-step>
          </hb-stepper-header>

          <!--// content -->
          <v-stepper-items>
            <template v-if="editMode">
              <div class="px-4 py-4">
                <hb-notification
                  type="caution"
                  title="Warning!"
                  :not-dismissable="true"
                >
                  Tenant HIGHLY recommends that you do not rename these features
                  and amenities. They have been selected to match the customers
                  understanding. Additionally these terms are more relavant for
                  SEO than alternates.
                </hb-notification>
              </div>
            </template>
            <v-divider></v-divider>

            <!-- Define Values screen -->
            <v-stepper-content class="pa-0" key="1-content" step="1">
              <div>
                <!-- Amenity Name -->
                <hb-form label="Name">
                  <v-text-field
                    class="pt-0"
                    v-model="amenityDetail.property_amenity_name"
                    v-validate="`required`"
                    data-vv-name="Name"
                    data-vv-as="Name"
                    single-line
                    label="Enter a name"
                    :error-messages="errors.collect(`Name`)"
                    id="Name"
                    name="Name"
                    hide-details
                  >
                  </v-text-field>
                </hb-form>

                <!-- Amenity Category -->
                <hb-form label="Category">
                  <template>
                    <div
                      style="color: #637381; font-size: 14px;"
                      class="pb-3"
                    >
                      Select a category for this feature and amenity
                    </div>
                  </template>
                  <v-select
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    single-line
                    label="Select a Type"
                    v-model="amenityDetail.amenity_category_id"
                    name="category"
                    id="category"
                    data-vv-name="category"
                    data-vv-as="category"
                    hide-details
                    :error-messages="errors.collect('category')"
                    v-validate="'required'"
                  >
                  </v-select>
                </hb-form>

                <!-- Amenity Options -->
                <hb-form label="Option">
                  <!-- Radio options -->
                  <v-radio-group
                    v-if="amenityDetail && amenityDetail.property_options"
                    v-model="amenityDetail.property_options.type"
                    class="ma-0 pa-0"
                    v-validate="'required'"
                    hide-details
                    id="Option"
                    name="Option"
                    @change="clearOptions"
                  >
                    <template v-slot:label>
                      For a given space, a feature/amenity can be either:
                    </template>
                    <v-radio value="checkbox" class="pb-3">
                      <template v-slot:label>
                        <template>
                          <div style="position: relative; top: 8px">
                            <div>Yes or No</div>
                            <div style="color: #637381; font-size: 12px;">
                              e.g. Climate Controlled
                            </div>
                          </div>
                        </template>
                      </template>
                    </v-radio>
                    <v-radio value="select" class="pb-3">
                      <template v-slot:label>
                        <template>
                          <div style="position: relative; top: 8px">
                            <div>Multiple Options</div>
                            <div style="color: #637381; font-size: 12px;">
                              e.g. Door Type can be Roll-up Door, Swing Door
                              or Curtain Door
                            </div>
                          </div>
                        </template>
                      </template>
                    </v-radio>
                  </v-radio-group>

                  <!-- Add a Custom Option -->
                  <div
                    class="col-8"
                    v-if="amenityDetail && amenityDetail.property_options && amenityDetail.property_options.type === 'select'"
                  >
                    <div>
                      <v-row
                        :key="index"
                        v-for="(option, index) in amenityOptions"
                        :class="{
                          'custom-option': errors.first(`Option-${index + 1}`)
                        }"
                      >
                        <v-text-field
                          class="pt-0 mt-0 pl-6 pb-2"
                          v-model="amenityOptions[index]"
                          single-line
                          label="Add Custom Option"
                          v-validate="`required`"
                          :data-vv-name="`Option-${index + 1}`"
                          :data-vv-as="`Option-${index + 1}`"
                          :readonly="amenitiesLength < index + 1 ? false : true "
                          :error-messages="
                            errors.collect(`Option-${index + 1}`)
                          "
                          :id="`Option-${index + 1}`"
                          :name="`Option-${index + 1}`"
                          hide-details
                        ></v-text-field>
                        <v-icon
                          color="primary"
                          v-if="amenityOptions.length > 1"
                          size="medium"
                          @click="removeOption(index)"
                          :class="{
                            'custom-option-icon': errors.first(
                              `Option-${index + 1}`
                            )
                          }"
                        >
                          mdi-close
                        </v-icon>
                      </v-row>
                    </div>
                    <div class="mt-4">
                      <span class="primary--text" @click="addOption()" style="cursor: pointer"
                        ><v-icon color="primary" size="small">mdi-plus</v-icon
                        >Add a Custom Option</span
                      >
                    </div>
                  </div>
                </hb-form>
              </div>
            </v-stepper-content>

            <!-- // Apply defaults screen -->
            <v-stepper-content class="pa-0" key="2-content" step="2">
              <div v-if="stepper === 2" style="min-height: 300px">
                <hb-form :label="amenityDetail.property_category_name">
                  <v-row>
                    <v-col class="pa-1 pl-5" cols="5">
                      <span>{{ amenityDetail.property_amenity_name }}</span>
                    </v-col>
                    <v-col class="pa-0 pl-5" cols="7">
                      <v-select
                        v-if="amenityDetail && amenityDetail.property_options && amenityDetail.property_options.type == 'select' "
                        class="pt-0"
                        :items="amenityOptions"
                        v-model="amenityDetail.property_default_value"
                        item-value="id"
                        item-text="name"
                        single-line
                        label="Select a value"
                        v-validate="'required'"
                        :error-messages="errors.collect('Default Value')"
                        hide-details
                        id="Default Value"
                        name="Default Value"
                      >
                      </v-select>
                      <v-radio-group
                        v-else
                        row
                        v-model="amenityDetail.property_default_value"
                        class="ma-0 pa-1"
                        v-validate="'required'"
                        hide-details
                        :error-messages="errors.collect('Default Value')"
                        id="Default Value"
                        name="Default Value"
                      >
                        <hb-radio value="Yes" class="pb-3">
                          <template v-slot:label>
                            <span class="text-capitalize">Yes</span>
                          </template>
                        </hb-radio>
                        <hb-radio value="No" class="pb-3">
                          <template v-slot:label>
                            <span class="text-capitalize">No</span>
                          </template>
                        </hb-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </hb-form>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </hb-stepper>
      </template>

      <!-- Buttons -->
      <template v-slot:right-actions>
        <span class="">
          <hb-btn
            v-if="stepper === 2"
            color="secondary"
            @click="back"
            >Back</hb-btn
          >
          <hb-btn color="primary" type="submit" @click="next" :disabled="isLoading('update_amenity')" :loading="isLoading('update_amenity')">
            {{ stepper === 2 ? "Save" : "Next" }}
          </hb-btn>
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "EditFeatureAndAmenity",
  computed: {
    ...mapGetters({
      addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList"
    }),
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    updatedOptions() {
      return this.amenityOptions.map((option) => {
        if (option.toLowerCase() === "yes") {
          return "Yes";
        }
        if (option.toLowerCase() === "no") {
          return "No";
        }
        return option;
      });
    }
  },
  props: {
    value: {
      type: Boolean
    },
    property_id: {
      type: String
    },
    amenity_id: {
      type: String
    },
    activeTab: {
      type: Object
    }
  },
  data() {
    return {
      editMode: false,
      countAmenitiesAdded: 0,
      amenitiesLength: 0,
      tabOptions: [
        { label: "Define Values", key: 1 },
        { label: "Apply Defaults", key: 2 }
      ],
      stepper: 1,
      maxActiveStep: 1,
      showConfirmationDialog: false,
      steps: [
        { number: 1, status: "step-active" },
        { number: 2, status: "step-disabled" }
      ],
      categories: [],
      amenityDetail: {},
      amenityOptions: [],
      initialOption: {}
    };
  },
  mixins: [notificationMixin],
  created() {
    if (this.amenity_id) {
      this.editMode = true;
    }

    this.fetchCategories();

    if (this.editMode) {
      this.fetchAmenityDetails();
    } else {
      this.amenityDetail = this.newAmenityObject();
    }
  },

  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures"
    }),

    fetchCategories() {
      api
        .get(this, api.SM_CATEGORIES)
        .then(response => {
          this.categories = response.categories.map(category => ({
            ...category,
            nameWithType: `${category.name} (${category.type})`
          }));
        })
        .catch(function(err) {
          throw err;
        });
    },

    fetchAmenityDetails() {
      api
        .get(
          this,
          `${api.PROPERTY_AMENITIES}${this.property_id}/amenities/${this.amenity_id}`
        )
        .then(response => {
          this.amenityDetail = response.amenity;
          this.amenityDetail.property_options = JSON.parse(
            this.amenityDetail.property_options
          );
          this.amenityOptions = this.amenityDetail.property_options
            .options
            ? this.amenityDetail.property_options.options
            : [""];
            this.initialOption = Object.assign({}, this.amenityDetail.property_options);
            this.amenitiesLength = this.amenityOptions.length 
        })
        .catch(function(err) {
          throw err;
        });
    },

    removeOption(index) {
      this.amenityOptions.splice(index, 1);
      if(index < this.amenitiesLength) this.amenitiesLength = this.amenitiesLength - 1
      this.amenityDetail.property_default_value = ""
    },
    addOption() {
      this.amenityOptions.push("");
    },
    async goToStep(stepNumber, previousStepCompleted) {
      const maxStep = 3;

      // Button click on last step
      if (stepNumber > maxStep) {
        return;
      }

      // User clicks on next step button (not on stepper)
      if (previousStepCompleted) {
        this.steps[stepNumber - 1].status = "step-active";
        this.steps[stepNumber - 2].status = "step-complete";
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if (stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = "step-active";

          for (let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = "step-editable";
          }

          for (let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = "step-complete";
          }
        }
      }
    },

    next() {
      this.checkDefaultValue();

      this.$validator.validateAll().then(status => {
        if (status) {
          if (this.stepper == 1) {
            if( this.editMode && this.amenityDetail.amenity_assigned_count && this.amenityDetail.property_options.type !== this.initialOption.type) {
              let desc = 'This feature or amenity is applied to spaces, you can not change the option.';
              this.showMessageNotification({type: 'error', description: desc});
            }
            else if (this.amenityDetail.amenity_category_id && this.checkDuplicateOptions()) {
              this.amenityDetail.property_category_name = this.categories.find(
                category => category.id == this.amenityDetail.amenity_category_id
              ).name;
              this.goToStep(this.stepper + 1, true);
            }
          } else {
            this.startLoading('update_amenity');
            this.editMode ? this.updateAmenity() : this.createAmenity();
          }
        } else if (this.stepper === 2 && !this.amenityDetail.property_default_value) {
          let desc = 'There are errors in your form, correct them before continuing.';
          let errList = [`${this.amenityDetail.property_category_name} is missing values for selected options.`];
          this.showMessageNotification({type: 'error', description: desc, list: errList});
        }
      });
    },

    updateAmenity() {
      let payload = { ...this.amenityDetail };
      if (payload.property_options.type === "select") {
        payload.property_options.options = this.updatedOptions;
      } else {
        payload.property_options.options = null;
      }
      payload.property_options = JSON.stringify(
        payload.property_options
      );
      payload.property_default_value =
        payload.property_default_value.toLowerCase() === "yes" ? "Yes" :
        payload.property_default_value.toLowerCase() === "no" ? "No" :
        payload.property_default_value;
      payload.property_amenity_name = payload.property_amenity_name.trim();
      api.put(this, api.UPDATE_AMENITY + payload.property_amenity_mapping_id, payload)
        .then(message => {
          this.stopLoading('update_amenity');
          this.showMessageNotification({type: 'success', description: message});
          // Fetch list and Close popup
          this.fetchFeatures({
            property_id: this.property_id,
            unit_type_id: this.activeTab.unit_type_id
          });
          this.$emit("close");
        })
        .catch(err => {
          this.showMessageNotification({type: 'error', description: err});
          this.back();
        });
    },

    clearOptions() {
      this.amenityDetail.property_default_value = null;
      this.amenityOptions =
        this.amenityDetail.property_options.options &&
        this.amenityDetail.property_options.options.length > 0
          ? this.amenityDetail.property_options.options
          : [""];
    },
    back() {
      this.goToStep(this.stepper - 1, false);
    },

    onCloseConfirmationDialog() {
      if (this.stepper == 1) {
        this.$emit("close");
      } else {
        this.showConfirmationDialog = true;
      }
    },

    newAmenityObject() {
      return {
        property_amenity_name: "",
        property_category_name: "",
        amenity_category_id: "",
        property_id: this.property_id,
        options: "",
        property_options: {
          options: [],
          type: ""
        },
        property_default_value: "",
        unit_type_id: this.activeTab.unit_type_id
      };
    },

    createAmenity() {
      this.amenityDetail.options = {
        type: this.amenityDetail.property_options.type,
        options: this.updatedOptions
      };
      const amenity = this.amenityDetail;
      const default_value =
        amenity.property_default_value.toLowerCase() === "yes" ? "Yes" :
        amenity.property_default_value.toLowerCase() === "no" ? "No" :
        amenity.property_default_value;
      let body = {
        name: amenity.property_amenity_name.trim(),
        unit_type_id: amenity.unit_type_id,
        unit_type_display_name: this.getUnitTypeTitle({unit_type_id:amenity.unit_type_id}),
        category_id: amenity.amenity_category_id,
        options: amenity.options,
        property_id: amenity.property_id,
        default: default_value,
        sort_order: this.addedFeaturesList.length + 1,
      };
      api
        .post(this, api.SPACE_MANAGEMENT + "amenities", body)
        .then(res => {
          this.stopLoading('update_amenity');
          this.$emit("close");
        })
        .catch(err => {
          this.showMessageNotification({type: 'error', description: err});
        });
    },

    checkDefaultValue() {
      if (this.amenityDetail && this.amenityDetail.property_options && (this.amenityDetail.property_options.type == 'select') && !this.amenityOptions.includes(this.amenityDetail.property_default_value)) {
        this.amenityDetail.property_default_value = null;
      }
    },

    checkDuplicateOptions() {
      let valid = true;
      let v = new Set(this.amenityOptions.map(a => a.toLowerCase().trim()));
      if (this.amenityOptions.length > v.size) {
        valid = false;
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: ['Duplicate custom options found.']});
      }
      return valid;
    },

  },
  watch: {
    "errors.items"(val) {
      if(val && val.length) {
        const errorMsg = [];
        let optionsError = false;
        val.forEach((item) => {
          if (item.field.includes('Option-')) optionsError = true;
          else if(item.field == 'Name' && item.rule == 'required') {
            errorMsg.push("Name is missing a value.")
          } else if(item.field == 'category' && item.rule == 'required') {
            errorMsg.push("Category is missing a selection.")
          } else {
            errorMsg.push(item.msg);
          }
        })
        if (optionsError) errorMsg.push('Enter or remove the unfilled custom option.');
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: errorMsg});
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.custom-option {
  .custom-option-icon {
    color: red !important;
  }
.v-input:not(.v-input--is-dirty) ::label {
    color: red !important;
  }
}
</style>
