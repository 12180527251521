<template>
    <div class="section-content ma-0 pa-0">
        
        <v-card elevation="1" class="ma-0 pa-6">
            <v-row class="ma-0 px-3 mb-3">
                <v-col class="pa-0">
                    <span class="hb-larger-font-size font-weight-medium">{{ group.name }}</span><br />
                </v-col>
            </v-row>

            <hb-autocomplete
                rounded
                :items="space_types"
                placeholder="Select Space Type"
                :clearable="true"
                v-model="activeTab"
                width="370px"
                return-object
                style="display:inline-block"
            >
                <template v-slot:selection="{ item }">
                    {{ getUnitTypeTitle({unit_type_id:item}) }}
                </template>
                <template v-slot:item="{ item }">
                    {{ getUnitTypeTitle({unit_type_id:item}) }}
                </template>
            </hb-autocomplete>

            <v-divider class="mt-3" v-if="activeTab"></v-divider>

            <div v-if="activeTab">

                <hb-empty-state 
                    v-if="spaceGroup[activeTab] && !spaceGroup[activeTab].id"
                    class="mt-4"
                    :message="`Add the features and amenities you want to make available for ${getUnitTypeTitle({unit_type_id:activeTab})}.`"
                    btn-txt="Set Features and Amenities and/or Size or Area"  
                    @clicked="editSettings"
                ></hb-empty-state>

                <div v-else class="mt-4">

                <hb-data-table-header>
                    <template v-slot:description>
                        {{ group.description }}
                    </template>
                    <template v-slot:actions>
                       <hb-btn v-if="group.editable" @click="editSettings" color="secondary" small>Edit Grouping Profile</hb-btn>
                    </template>
                </hb-data-table-header>
            
                <hb-data-table
                    v-if="activeSpaceType"
                    :headers="data_table_headers"
                    :items="spaceGroupTableData"
                    item-class="classname"
                    disable-filtering
                    disable-sort
                    disable-pagination
                >
                    <template v-slot:header.left_col="{ header }">
                       <strong>{{ getUnitTypeTitle({unit_type_id:activeTab}) | capitalize}}:</strong> {{ activeSpaceType.num_groups || 0 }} Group(s)
                    </template>
                            
                   <template v-slot:header.right_col="{ header }" class="text-right">
                      <strong>Total Number of Spaces:</strong> {{ activeSpaceType.num_spaces || 0 }}
                    </template>

                </hb-data-table>
                </div>
            </div>

        </v-card>

         <hb-modal v-model="manageSettings" show-help-link v-if="manageSettings" size="large" title="Set Features and Amenities and/or Size or Area" @close="closeWindow">
            
            <template v-slot:content>
                
                <div v-if="!selected.id" class="stepper-container">
                    <hb-stepper v-model="step">
                        <hb-stepper-header>
                            <template v-for="(s, i) in steps" >
                                <hb-stepper-step
                                    :key="'s' + i"
                                    :complete="step > i"
                                    :step="i"
                                >
                                    {{ s.label }}
                                </hb-stepper-step>
                                <v-divider v-if="i < steps.length - 1" :key="i"></v-divider>
                            </template>
                        </hb-stepper-header>
                    </hb-stepper>
                </div>


                <div class="pt-3 pb-4 px-4 hb-text-night-light" v-if="!selected.id">
                    {{steps[step].description}}
                </div>
                <div v-else class="pt-3 pb-4 px-4 hb-text-night-light"> It is a combination of both Features/Amenities and Size/Area Tiers together that create your groups. For e.g, if you select “Climate Control” for Features and Amenities, and select “Group by Size” for Size/Area Tier, then the following groups are created: “Climate Control - 5x5”, “No Climate Control - 5x5", “Climate Control - 10x15”, “No Climate Control -10x15", etc.</div>
                <v-divider></v-divider>
                    

                <div v-if="step === 0 || selected.id" >

                    <hb-form label="Name" v-if="selected.id" required> 
                        <hb-text-field
                            v-model="selected_group.name"
                            v-validate="'required|max:45'"
                            data-vv-scope="profile"
                            data-vv-as="Name"
                            id="name"
                            name="name" 
                            :error="errors.has('profile.name')"
                            placeholder="Enter a Name for This Grouping Profile" 
                        ></hb-text-field>
                    </hb-form>
 

                    <hb-form full label="Description" v-if="selected.id">
                        <hb-textarea
                            v-model="selected_group.description"
                            v-validate="'max:1000'"
                            data-vv-scope="profile"
                            data-vv-as="Description"
                            id="description"
                            name="description"
                            :error="errors.has('profile.description')"
                            placeholder="Enter a Description for this Grouping Profile"
                        ></hb-textarea>
                    </hb-form>


                    <hb-form label="Space Type"> 
                        {{ getUnitTypeTitle({unit_type_id:activeTab})}}
                    </hb-form>
 
                    
                    <hb-form 
                        v-if="amenities[activeTab] && amenities[activeTab].length"
                        full
                        label="Features and Amenities"
                        description="Add the features and amenities that will create your grouping system (max. 4 Features and Amenities) AND sort them in the order or importance starting with most important on the top and the least important at the bottom (Drag and Drop)."
                        :last="!selected.id"
                    >
                        <draggable
                            handle=".new-handle"
                            v-model="features"  @start="drag=true" @end="drag=false">
                            
                            <v-row no-gutters v-for="(f, i) in features" :key="f.amenity_property_id" class="sort-row" >
                                <v-col style="flex: 0" class="new-handle hand pa-2"><v-icon>mdi-drag-vertical</v-icon></v-col>                            
                                <v-col style="flex: 1" >
                                
                                    <v-autocomplete
                                        :items="getSortedAmenities"
                                        :disabled="isLoading($options.name)"
                                        :name="'features_'+i"
                                        :key="'features_'+i" 
                                        item-text="name"
                                        item-value="amenity_property_id"
                                        v-model.lazy="f.amenity_property_id"
                                        dense
                                        single-line
                                        label="Select a Feature or Amenity"
                                        hide-details
                                        ></v-autocomplete>
                                
                                </v-col>                            
                                <v-col style="flex: 1" class="pa-2">
                                    <hb-icon @click="removeFeature(f, i)" small>mdi-close</hb-icon>
                                    </v-col>                            
                            </v-row>
                        </draggable>
                        <div class="my-2 pb-1"  v-if="features.length < 4">
                            <hb-link  @click="addFeature" >+ Add Features and Amenities</hb-link>
                        </div > 
                    </hb-form>

                    <hb-form 
                        label="Features and Amenities"
                        full
                        v-else
                        :last="!selected.id"
                    >
                        <hb-empty-state 
                            message="Features and Amenities have not been setup. Use the Space Features and Amenities settings page to add Features and Amenities for each space type, or continue without adding these groups."
                            btn-txt="Setup Features and Amenities"
                            btn-color="secondary"
                            
                        ></hb-empty-state>

                    </hb-form>
                    
                </div>
                <div v-if="step === 1 || selected.id" >
                    <hb-form 
                        label="Tiers"
                        description="Your spaces can be grouped either by size (e.g., 5’x5’, 10’x15’, etc) or by area (e.g., 0-30sqft, 31-43sqft, 44-231sqft, 231sqft and above). Area Tiers are helpful if you have random or irregular space sizes at your property, and you want to group them together for reporting or management."
                        full
                        last
                    >

                            <v-radio-group v-model="selected.tier_type">
                                <v-radio
                                    v-for="(o, i) in tierOptions"
                                    :key="'to'+i"

                                    :value="o.value"
                                >
                                
                                    <template v-slot:label>
                                        <div>{{o.label}}<br />
                                            <span class="hb-font-caption hb-text-night-light">{{o.description}}</span>
                                        </div>
                                    </template>
                                
                                </v-radio>
                            </v-radio-group>
                            <div v-if="selected.tier_type === 'area'">

                                <v-container  class="ma-0 py-0">
                                    <!-- <v-row dense no-gutters>
                                        <v-col class="pl-4 hb-font-caption mb-2 hb-text-night-light">Enter the highest sqft to define a tier range</v-col>
                                    </v-row> -->
                                    <v-row dense no-gutters v-for="(t, i) in tiers" :key="'t'+i">

                                        <v-col  class="ma-0 pa-2 px-4" style="flex: 0 1 80px;" >Tier {{i + 1 }}: </v-col>
                                        <v-col  class="ma-0 pa-2 px-4" style="flex: 0 1 125px;">{{ minSqft(t, i)}} sqft to </v-col>
                                        <v-col class="ma-0 pt-0" style="flex: 0 1 60px;">
                                            <v-text-field
                                                class="ma-0 pa-0"
                                                dense
                                                v-model="tiers[i]"
                                                v-validate.disable="'required'"
                                                data-vv-scope="tiers"
                                                id="name"
                                                name="name" 
                                                :error="errors.collect('tiers.name').length > 0"
                                                single-line
                                                hide-details 
                                                
                                            ></v-text-field>
                                            
                                        </v-col>
                                        <v-col class="ma-0 py-2 px-4" style="flex: 0 1">sqft</v-col>
                                        <v-col class="ma-0 pa-2 px-4" style="flex: 0 1"> <hb-icon @click="removeTier(i, i)" small>mdi-close</hb-icon></v-col>
                                        
                                    </v-row>   
                                    <v-row dense no-gutters>
                                        <v-col  class="ma-0 pa-2 px-4" style="flex: 0 1 80px;" >Tier {{tiers.length + 1 }}: </v-col>
                                        <v-col  class="ma-0 pa-2 px-4" style="flex: 1">{{ minSqft(tiers[tiers.length], tiers.length)}} sqft and above </v-col>
                                    </v-row>   
                                    <p class="hb-text-night-light hb-font-caption px-4">Create as many Area Tiers as you need, to group all your spaces. For each Tier, select the sqft value to capture and group irregular spaces. Each Area Tier will create a new Group.</p>
                                    <v-row>
                                           <v-col>

                                            <hb-link class="mt-2 pl-4" @click="addTier">+ Add Additional Tier</hb-link>                                            
                                            
                                            <div class="mt-5 pl-4">
                                                <v-checkbox
                                                    v-model="selected.tier_default"
                                                    label="Save Tier Group as Default"
                                                    hide-details
                                                ></v-checkbox>
                                                <p class="hb-text-night-light hb-font-caption">
                                                    Saving as the Tier Group default will apply the added tiers for all future grouping profiles when the “Group by Area” is selected.
                                                </p> 
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                    </hb-form>



                </div>
            </template>
            <template v-slot:actions>
                <hb-btn v-show="step === 0 && !selected.id" color="primary" @click="moveStep(1)">Next</hb-btn>
                <hb-btn v-show="step === 1 && !selected.id" color="secondary" class="mr-3" @click="moveStep(0)">Back</hb-btn>
                <hb-btn v-show="step === 1 || selected.id" color="primary" :loading="saveLoading" :disabled="saveDisabled" @click="saveSettings">Save</hb-btn>
            </template>
        </hb-modal>
        
    </div>
</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
     import Modal from '../../assets/Modal.vue';
    import EmptyStateContainer from '../../assets/EmptyStateContainer.vue';
    import draggable from 'vuedraggable'
    import { EventBus } from '../../../EventBus.js';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    export default {
        name: "SpaceGroupsSettings",
        mixins: [ notificationMixin ],
        data() {
            return {
                activeTab: null,
                saveLoading: false,
                saveDisabled: false,
                sortable: null,
                tierOptions: [
                    {
                        label: 'Group by Size',
                        description: "Examples: 5'x5', 5'x10', 5'x20'",
                        value: 'size'
                    }, 
                    {
                        label: 'Group by Area',
                        description: "Examples: 0 sqft - 20 sqft, 21 sqft - 40 sqft",
                        value: 'area'
                    }
                ], 
                data_table_headers: [
                    {
                        text: '',
                        value: 'left_col'
                    },
                    {
                        text: '',
                        value: 'right_col',
                        align: 'right'
                    }
                ],
                data_table_data: [], 

                step: 0,
                steps: [
                    {
                        key: 'amenities',
                        label: 'Features and Amenities',
                        description: "Select features & amenities you want to group by. This step can be skipped if you do not want to include features and amenities in your groupings."
                    }, 
                    {
                        key: 'tiers',
                        label: 'Size of Area',
                        description: "Select Group by Size or create area brackets."
                    }
                ],
                spaceGroup:{},
                manageSettings: false,
                features: [],
                amenities: {},
                tiers: [],
                selected: {
                    tier_type: 'size',
                    tier_default: false,
                    unit_group_profile_id: '',
                    amenities: [],
                    tiers: []
                },
                selected_group:{}, 
                default_tiers: []
            }
        },
        components:{
            Modal, 
            EmptyStateContainer,
            draggable
        },
        filters:{
            tierSpaces(tier){
                // console.log(tier);
                return tier.reduce((a,b) => a + b.num_spaces, 0)
            }
        },
        computed:{
            getTableHeaderText(){
                return "Test data"
            }, 
            activeSpaceType(){
                return this.spaceGroup[this.activeTab]
            },
            spaceGroupTableData(){
                if(!this.activeSpaceType) return;
                let data = [];
                let activeSpaceTypes = Object.keys(this.activeSpaceType?.groups || {});
                for(let i =0; i < activeSpaceTypes.length; i++){
                    let group = activeSpaceTypes[i];
                    data.push({
                        left_col: `${group} (Spaces ${ this.$options.filters.tierSpaces(this.activeSpaceType?.groups[group])})`,
                        right_col: '',
                        classname: 'subheader-row'
                    })
                    
                    for(let j =0; j < this.activeSpaceType?.groups[group].length; j++){
                        let tier = this.activeSpaceType?.groups[group][j];
                        data.push({
                            left_col: this.activeSpaceType?.tier_type === 'area' ? tier.area : tier.size,
                            right_col : tier.num_spaces,
                            classname: "data-row"
                        });  
                    }
                }
                return data;
            },
            space_types(){
                let sts = [];
                for(let sg in  this.spaceGroup){
                    sts.push(sg)
                }
                return sts;
            },
            getSortedAmenities() {
                return this.amenities[this.activeTab].sort((a, b) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
                })
            } 
        }, 
        props:['group', 'property_id'],
        async created(){
            await this.fetchData(); 
        },
        methods:{
            validateAmenities(){
                
                if(this.features.find(f => !f.amenity_property_id)){
                    return [ 'Please select the amenities you want to include.' ]
                }
                let seen = new Set();
                
                var hasDuplicates = this.features.some(function(amenity) {
                
                    return seen.size === seen.add(amenity.amenity_property_id).size;
                });

                if(hasDuplicates){
                    return [ 'You have added 2 or more of the same amenity.' ]
                }
            },
            validateTiers(){
                let errors = [];
                if(this.selected.tier_type === 'area'){
                    if(!this.tiers.length){
                        return [ 'Please include as least 1 area tier.' ]
                    }
                        
                    if(this.tiers.findIndex(t => !t) >= 0){
                        return [ 'Please enter all the tiers you want to include.' ]
                    }
                    
                    let sorted = true; 
                    let whole = true; 
                    // console.log("this.tiers",this.tiers)
                    for (let i = 0; i < this.tiers.length; i++) {
                        
                        if (+this.tiers[i] >= +this.tiers[i+1]) {
                                sorted = false;
                        }
                    
                        // console.log("ROUND", Math.floor(this.tiers[i]))
                        // console.log("tier",  +this.tiers[i])
                        if (Math.floor(this.tiers[i]) !== +this.tiers[i] ) {
                            whole = false;
                        }

                    }
                    if (!sorted){
                        errors.push('Your tiers must be sorted lowest to highest'); 
                    }

                    if (!whole){
                        errors.push('Please use whole numbers for your tiers'); 
                    }
                    return errors;
                }
            },
            minSqft(tier, i){
                if(i === 0) return 0;
                return this.tiers[i-1];
            }, 
            addTier(){
                this.tiers.push('')
            },
           
            async moveStep(num){
                if(num){
                    let items = this.validateAmenities();
                    if(items && items.length){
                        this.showMessageNotification({ list: items })
                        return;
                    }
                }
                this.step = num;
            }, 
            
            closeWindow(){
                this.tiers = [];
                this.features = [];
                this.selected = {
                    tier_type: 'size',
                    tier_default: false,
                    unit_group_profile_id: '',
                    amenities: [],
                    tiers: []
                };
                this.step = 0;
                this.manageSettings = false;
            },

            async editSettings(){
                this.selected_group = JSON.parse(JSON.stringify(this.group));
                if(this.spaceGroup[this.activeTab]?.id){
                    this.selected = JSON.parse(JSON.stringify(this.spaceGroup[this.activeTab])); 
                    this.features = this.selected.amenities.map((a, i) => {
                        // console.log("this.selected", this.selected)
                        return {
                            amenity_property_id: a.amenity_property_id
                        }
                    });
                    this.tiers = this.selected.tiers.filter(t => t.max_sqft).map(t => t.max_sqft);
                } else {
                    this.tiers = await this.getDefaultTiers();
                }
                await this.getAmenitiesList();
                this.manageSettings = true;
            },
            async getDefaultTiers(){
                 let r = await api.get(this, api.PROPERTIES + this.property_id + "/space-groups/" + this.activeTab + '/default-tiers');
                
                if(r.tiers.length){
                    return r.tiers.map(t => t.max_sqft);
                } else {
                    return ['']
                }
            },
            async getAmenitiesList(){

                let r = await api.get(this, api.SPACE_MANAGEMENT + 'properties/' + this.property_id + '/space-type/' + this.activeTab + '/amenities?type=edit_list');
                
                this.amenities = {};
                for(let i = 0; i < r.amenities.length; i++){
                    for(let j = 0; j < r.amenities[i].amenities.length; j++){
                        let amenity =  r.amenities[i].amenities[j];
                        this.amenities[amenity.unit_type_id] = this.amenities[amenity.unit_type_id] || []
                        this.amenities[amenity.unit_type_id].push({
                            id: amenity.amenity_id,
                            amenity_property_id: amenity.property_amenity_mapping_id,
                            name: amenity.property_amenity_name  || amenity.master_amenity_name
                        })
                    }
                }
              
            }, 
            removeTier(t, i){
                this.tiers.splice(i, 1)
            }, 
            removeFeature(f, i){
                this.features.splice(i, 1)
            }, 
            addFeature(){
                this.features.push({
                    amenity_property_id: '', 
                    name:  ''  
                });
            },
            disableSave(flag){
              this.saveLoading = flag;
              this.saveDisabled = flag;
            },
            async saveSettings(){
              this.disableSave(true);
              let status = await this.$validator.validateAll('profile');
                if (!status) {
                  this.disableSave(false);
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }
                
                let settings = {
                    tier_type: this.selected.tier_type,
                    tier_default: this.selected.tier_default
                }; 
                // console.log("saveSettings",this.features )
                settings.amenities = this.features.map((a, i) => {
                    a.sort = 1;
                    return a; 
                });
                settings.tiers = this.tiers;

                settings.name = this.selected_group.name;
                settings.description = this.selected_group.description;
                let items = [];

                if(!this.selected_group.name){
                    items.push("Please add a name"); 
                }

                let amenity_errors = this.validateAmenities(); 
                if(amenity_errors && amenity_errors.length){
                    items = [].concat(items,amenity_errors);
                } 
                let tier_errors = this.validateTiers();
                if(tier_errors && tier_errors.length){
                    items = [].concat(items,tier_errors);
                } 

                if(items && items.length){
                    this.showMessageNotification({ list: items });
                    this.disableSave(false);
                    return;
                }

                try{
                    let r = await api.put(this, api.PROPERTIES + this.property_id + "/space-groups/" + this.group.id + '/settings/' +  this.activeTab , settings);
                    this.disableSave(false);
                    this.showMessageNotification({ type: 'success', description: 'A new space grouping has been created/edited.' });
                    this.group.name =  this.selected_group.name;
                    this.group.description =  this.selected_group.description;
                    this.fetchData();
                    this.$emit('fetchData');
                    this.closeWindow();
                } catch(err){
                  this.disableSave(false);
                  this.showMessageNotification({ description: err });
                }
            },
            async fetchData(){
                let r = await api.get(this, api.PROPERTIES + this.property_id + "/space-groups/" + this.group.id + '/settings');
                this.spaceGroup = r.spaceGroupSettings;
            },
            getStepStatus(step){
                if(this.step === step) return 'step-active';
                if(this.step > step) return 'step-complete';
            },
        }

    }

</script>

<style scoped>
.sort-row{
    background-color: white;
}
.handle{
    cursor: grab;
}
.sortable-drag{
    cursor: grabbing;
}
/*
.stepper-container{
    border-bottom: 1px solid #E1E6EA;
    text-align: center;
    box-shadow:none;
    padding: 0 50px;
    background: white;
}

.v-stepper__header {
    max-width: 525px;
    border: none;
    box-shadow: none;
    margin: 0 auto;
}
    .v-stepper--alt-labels .v-stepper__step {
    flex-basis: unset !important;
        max-width: unset;
}

.v-application--is-ltr .v-stepper__label {
    text-align: center;
} 
*/
    

</style>

<style>
    /* .stepper-container  .hb-stepper-wrapper .v-stepper__step{
        max-width: 300px;
    } */
    .subheader-row{
        border-top: 1px solid rgb(16, 19, 24);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #F9FAFB;
    }
</style>
