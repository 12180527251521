<template>
    <hb-modal
        :title="'Add ' + (isFees ? 'Fees' : 'Merchandise')"
        v-model="productsDialog"
        @close="closeDialog"
    >
        <template v-slot:subheader>
            Select any and all desired merchandise.
        </template>
        <template v-slot:content>
            <div class="px-5">
                <v-col md="7" class="pt-5 pb-3 px-0">
                    <hb-text-field
                        search
                        v-model="product_search"
                        placeholder="Search"
                    >
                    </hb-text-field>
                </v-col>
                <div class="products-list" v-if="filtered_products.length">
                    <div v-for="product in filtered_products" :key="product.id">
                        <div class="d-flex py-4" :class="{ 'align-start': isFees }">
                            <v-checkbox
                            class="pt-0"
                            :class="{ 'mr-2': !isFees }"
                            v-model="selectedProducts"
                            :value="product.id"
                            />
                            <div class="d-flex" :class="{ 'justify-space-between width-100': isFees }">
                                <div class="thumbnail-img" v-if="!isFees">
                                    <img  v-if="product.image" :src='"data:image/" + product.image.image_type + ";base64," + product.image.data_url' />
                                    <div class="placeholder-img" v-else>
                                        <hb-icon color="#A5B4BF">mdi-image-area</hb-icon>
                                        <!-- <div class="light-color hb-button-text-small pt-2">No Image Available</div> -->
                                    </div>
                                </div>
                                <div class="description-title" :class="{ 'pr-4 pl-0': isFees }">
                                    <div class="font-14px font-weight-medium product-title pb-2">{{product.name}}</div>
                                    <div class="product-description hb-text-light hb-button-text-small">
                                        <span>
                                            {{product.description}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="product-price text-right" :class="{'pt-1': product.amount_type !== 'variable', 'pr-0': isFees}"  :style="{'width': product.amount_type === 'variable' ? '100px' : '80px'}">
                                <hb-text-field
                                    v-model="product.service_price"
                                    dense
                                    box
                                    :width="isFees ? '83px' : merchandiseSettings ? '124px' : '70px'"
                                    class="merchandise-product-lead-2"
                                    :style="{'margin-left': !isFees && merchandiseSettings ? '20px' : ''}"
                                    :class="{ 'mr-3': !isFees && !merchandiseSettings }"
                                    v-if="product.amount_type === 'variable'"
                                    v-validate="'decimal:2|max_value:9999|min_value:.01'"
                                    data-vv-name="price"
                                    :id="'price_' + product.id"
                                    :name="'price_' + product.id"
                                >
                                    <template v-slot:prepend-inner>
                                        $
                                    </template>
                                </hb-text-field>
                                <span v-else>
                                    <span v-if="product.amount_type === 'percentage'">{{product.service_price | formatPercentage }}</span>
                                    <span v-else>{{product.service_price | formatMoney }}</span>
                                </span>
                            </div>
                            <div class="cart-actions" v-if="!isFees && (!merchandiseSettings || (merchandiseSettings && product.amount_type !== 'variable'))">
                                <div class="d-flex justify-space-between align-center">
                                    <div class="stock-btns d-flex align-center justify-space-between px-2 py-1" style="height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                                        <hb-icon color="#000" :custom-size="12" @click="() => { if(product.qty > 1) product.qty -=1 }" class="hand top--2px">mdi-minus</hb-icon>
                                        <span class="top--2px px-2">{{ product.qty }}</span>
                                        <hb-icon color="#000" :custom-size="12" small @click="product.qty +=1" class="hand top--2px">mdi-plus</hb-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider></v-divider>
                    </div>
                </div>
                <hb-empty-state v-else message="Item Not Found" class="mt-2 mb-4" />
            </div>
        </template>
        <template v-slot:left-actions>
        </template>
        <template v-slot:right-actions>
            <hb-btn :disabled="isSaving" :loading="isSaving" @click="addProducts">Add</hb-btn>
        </template>
    </hb-modal>
</template>


<script type="text/babel">

import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import api from '../../../assets/api.js';
import { mapGetters } from 'vuex';
import { EventBus } from '../../../EventBus.js';

export default {
    name: "AddServiceModal",
	mixins: [ notificationMixin ],
    data() {
        return {
            product_search: '',
            selectedProducts: [],
            isSaving: false
        }
    },
    props: {
        productsList: {
            type: Array,
            default: []
        },
        value: {
            type: Boolean,
            default: false
        },
        merchandiseSettings: {
            type: Boolean,
            default: false
        },
        dialogType: {
            type: String,
            default: ''
        }
    },
    created() {
        console.log('selectedProducts :>> ', this.selectedProducts);
    },
    computed: {
        ...mapGetters({
            lease: 'onBoardingStore/lease',
            services: 'onBoardingStore/services'
        }),
        productsDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        filtered_products: {
            get(){
                return this.productsList.filter(p => !this.product_search || p.name.toLowerCase().search(this.product_search.toLowerCase()) >= 0 || (p.description && p.description.toLowerCase().search(this.product_search.toLowerCase()) >= 0))
                .map(p => {
                    let service = this.services.find(s => s.product_id === p.id);
                    if (!service) {
                        return p;
                    }
                }).filter(item => item);
            }
        },
        isFees() {
            return this.dialogType === 'fees';
        }
    },
    methods: {
        async addProducts() {
            try {
                if (!this.selectedProducts.length) {
                    this.showMessageNotification({ type: 'error', description: 'Please select at least one product.' });
                    return;
                }
                let status =  await this.validate(this);
                if(!status) return;
                if (this.merchandiseSettings) {
                    let productWithError = this.productsList.find(item => this.selectedProducts.includes(item.id) && item.qty > item.stock && !item.negative_inventory);
                    if (productWithError) {
                        this.showMessageNotification({ type: 'error', description: `Inventory is not available for "${productWithError.name}". please add inventory first` });
                        return;
                    }
                }
                let payload = this.productsList.filter(item => this.selectedProducts.includes(item.id)).map(product => {
                    let productData = {
                        name: product.name,
                        price: product.service_price,
                        qty: product.qty && !isNaN(parseInt(product.qty)) ? parseInt(product.qty) : 1,
                        recurring: 0,
                        prorate: 0,
                        prorate_out: 0,
                        description: product.description,
                        start_date: this.lease.start_date,
                        end_date: null,
                        taxable:product.taxable || 0,
                        product_id: product.id
                    };

                    if (this.isFees) {
                        productData = {
                            ...productData,
                            recurring: !!product.recurring,
                            prorate: !!(product.recurring && product.prorate),
                            prorate_out: !!(product.recurring && product.prorate_out),
                        }
                    }
                    return productData;
                });
                if (this.isSaving) {
                    return;
                }
                this.isSaving = true;
                await api.post(this, api.LEASES + this.lease.id + '/bulk-services', payload);
                await this.$store.dispatch('onBoardingStore/getServices');
                this.$emit('fetchServices');
                this.closeDialog();
                await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: error });
            } finally {
                this.isSaving = false;
            }
        },
        closeDialog() {
            this.productsDialog = false;
            this.selectedProducts  = [];
            this.product_search = '';
        }
    }
}
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 24px 0px 24px 0px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 24px 0px 24px 0px;
    }

    .merchandise-row-search {
        padding: 0px 0px 24px 0px;
        border-bottom: 1px solid #C4CDD5;
    }

    .merchandise-row:last-child{
        border-bottom: none;
    }
    .remove-service-btn {
        display: block;
        padding-top: 10px;
    }
    .thumbnail-img {
        height: 75px;
        width: 75px;
        background-color: #F9F9F9;
    }

    .placeholder-img {
        height: 75px;
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #F9F9F9;
    }

    .thumbnail-img img {
        width: 100%;
    }

    .product-price {
        min-width: 80px;
        padding-right: 12px;
    }

    .description-title {
        max-width: 406px;
        min-width: 406px;
        padding-left: 14px;
        padding-right: 14px;
    }

    .width-100 {
        width: 100%;
    }

</style>

<style>
.merchandise-product-lead-2 .v-input__control .v-input__slot {
    min-height: 33px !important;
}
</style>