<template>
    <!-- NOTE: IMPORTANT: -->
    <!-- Any changes made to this file's html/vue needs to be made in 2 different places -->
    <!-- because there is an "v-if" and "v-else" used here that both use the same content but it is a workaround -->
    <!-- to handle both the legacy code implementation as well as the proper design system implementation -->
    <!-- please do not miss doing this in both places, if you don't, then 1 place will not reflect the changes -->
    <!-- NOTE: IMPORTANT: -->
    <div>
        <hb-modal v-if="modal" v-model="modalDialog" size="medium" @click="$emit('close')" title-icon="mdi-cash-refund"
            :show-help-link="false" :title="'Refund Amount'">
            <template v-slot:content>
                <div style="margin: 16px;">
                    <v-row no-gutters>
                        <v-col no-gutters>
                            <span>
                                <HbIcon mdi-code="mdi-information" /> Maximum Refundable: <span
                                    class="hb-text-light hb-font-body-medium"> {{ payment.unused_amount | formatMoney
                                    }}</span>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col style="display: flex; align-items: center;" no-gutters>
                            <div class="hb-text-night hb-font-body-medium">Refund Amount:</div>
                            <hb-text-field class="ml-2 mt-2" v-model="refundAmount" condensed box medium
                                v-validate="'required|max:45'" data-vv-scope="default" data-vv-name="refund_amount"
                                placeholder="" :error-messages="errors.collect('refund_amount')">
                                <template class="pt-2" v-slot:prepend-inner>
                                    $
                                </template>
                            </hb-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col no-gutters>
                            <v-textarea :class="{ 'custom-field-error': errors.first('notes') }" rows="1" hide-details
                                v-model="refundNotes" data-vv-name="notes" :error-messages="errors.collect('notes')"
                                label="Notes" id="notes" name="cnotes" autocomplete="off" />
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="recordRefund">Save</hb-btn>
            </template>
        </hb-modal>



    </div>

</template>

<script type="text/babel">
    import REVERSAL from "@/constants/reversal.js";
    import Dropdown from '../assets/Dropdown.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import Datepicker from 'vuejs-datepicker';
    import SendReceipt from '../assets/SendReceipt.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import {mapGetters, mapMutations} from 'vuex';
    import {EventBus} from '../../EventBus.js';
    import Reversal from './Reversal.vue';
    import EmailDocument from './EmailDocument.vue';
    import ReversalConfirmation from './ReversalConfirmation.vue';
    import ADJUSTMENT from "@/constants/adjustment.js";
    import {notificationMixin} from '../../mixins/notificationMixin.js';
    import {PAYMENTS} from '@/constants/payments.js';
    
    export default {
            name: "RecordRefund",
            mixins: [notificationMixin],
            data() {
            return {
                payment: {
                Property: {},
                Lease: {},
                PaymentMethod: {},
                amount: '',
                applied: '',
                AppliedPayments: [],
                Refunds: [],
                date: "",
                id:'',
                lease_id: "",
                method:'',
                notes:"",
                number:"",
                payment_methods_id: "",
                total_applied:0,
                payment_remaining:0,
                transaction_id: "",
                type:"",
                status_desc: '',
                is_auction_payment: false,
                source: '',
                payment_source: '',
            },
            refundAmount:0,
            refundNotes: '',
            void_amount: null,
            refund: null,
            reason: '',
            selectedInvoices: [],
            refundFlag:false,
            refund_option: '',
            showVoid: false,
            paymentVoided: false,
            sendReceiptModal: false,
            reversalConfirmation: false,
            showEmailDocument: false,
            auctionPayment: false,
            is_auctioned_lease_payment: false,
            is_forte_ach_payment: false,
            is_fatzebra_payment:false,
            is_inter_property_payment: false,
            showEmailConfirmation:false,
            isEmailDisabled:false,
            isRefund: false
        }
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                reversal: 'paymentsStore/getReversal',
                reversalLoading: 'paymentsStore/getReversalLoading',
                reversalError: 'paymentsStore/getReversalError',
                hasPermission: 'authenticationStore/rolePermission',
                properties: "propertiesStore/properties",
            }),
            hasPermissionToVoid() {
                return this.hasPermission('card_void_permission');
            },
            dialog: {
                get () {
                return this.value
                },
                set (value) {
                this.$emit('input', value)
                }
            },
            modalDialog: {
                get () {
                return this.value
                },
                set (value) {
                this.$emit('input', value)
                }
            },
            },
            components: {
            Dropdown,
            DropdownMenu,
            Datepicker,
            Status,
            Loader,
            SendReceipt,
            Reversal,
            ReversalConfirmation,
            EmailDocument
            },
            async created(){
            this.REVERSAL_TYPES = REVERSAL.REVERSAL_TYPES;
            this.REFUND = REVERSAL.REFUND;
            this.REVERSALS = REVERSAL.REVERSALS;
            this.SUB_METHOD = ADJUSTMENT.SUB_METHOD;
            await this.fetchPayment();
            EventBus.$on('show_reversal_confirmation', this.showReversalConfirmation);
            EventBus.$on('show_void_confirmation', this.showVoidConfirmation);
            EventBus.$on('setInvoices', this.setInvoices);
            EventBus.$on('selectAllInvoices', this.selectAllInvoices);
            EventBus.$on('reversal_process_update', this.reversalProcessUpdate);
            EventBus.$on('disable_email_letter', this.disableEmailLetter);
            this.PAYMENTS = PAYMENTS;
            },
            filters:{
            },
            methods: {
            ...mapMutations({
                setReversalObject: 'paymentsStore/setReversalObject'
            }),
            async recordRefund(){
                if(!this.refundAmount || this.refundAmount <= 0) {
                this.showMessageNotification({type: 'error', description:'Invalid refund amount. Please enter a valid amount.'});
                return;
                }
                if(this.refundAmount > this.payment.unused_amount){
                this.showMessageNotification({type: 'error', description:'Refund amount cannot exceed remaining balance. Please enter a valid amount.'});
                return;
                }
                if(this.refundNotes.length > 255){
                this.showMessageNotification({type: 'error', description:'Refund notes cannot exceed 255 characters.'});
                return;
                }
                let payload = {
                amount: this.refundAmount,
                notes: this.refundNotes,
                }
    
                let response = await api.post(this, api.GET_NATIONAL_ACCOUNT + this.na_id + '/payments/' + this.payment_id + '/refund', payload)
                console.log("Refund Response: ",response)
                this.showMessageNotification({ type: 'success', description: 'You have successfully recorded a refund.' })
                this.modalDialog = false;
                this.$emit('refetch-payment')
            },
            async fetchPayment(){
                if(!this.payment_id) return;
                console.log("National Account ID: ",this.na_id);
                await api.get(this, api.GET_NATIONAL_ACCOUNT + this.na_id + '/payments/' + this.payment_id).then(async (r) => {
                console.log("Payment: ", r.payment);
                this.payment = r.payment;
                this.payment.Property = r.payment.Property
                this.getRefundOptions();
                this.selectedInvoices = [];
                this.auctionPayment = this.payment.is_auction_payment;
                this.is_auctioned_lease_payment = this.payment.is_auctioned_lease_payment;
                this.is_inter_property_payment = this.payment.is_inter_property_payment;
    
    
                let c = await api.get(this, api.PROPERTIES + this.payment.property_id + '/connections');
                    if(c.connections?.length > 0) {
                    let forteConnection = c.connections.find(c=> c.name ==='forte');
                    this.is_forte_ach_payment = forteConnection ? true:false
                    } else {
                    this.is_forte_ach_payment = false;
                    }
    
                    if(c.connections?.length > 0) {
                    let fatZebraConnection = c.connections.find(c=> c.name ==='fatzebra');
                    this.is_fatzebra_payment =  fatZebraConnection? true : false;
                    }
    
    
                // Removed the check which was hiding the popup when the full refund is initiated.
                // if(r.payment.total_applied === 0) {
                //   this.dialog = false
                // }
                });
            },
            },
            destroyed(){
            EventBus.$off('show_reversal_confirmation', this.showReversalConfirmation);
            EventBus.$off('setInvoices', this.setInvoices);
            EventBus.$off('selectAllInvoices', this.selectAllInvoices);
            EventBus.$off('reversal_process_update', this.reversalProcessUpdate);
            EventBus.$off('disable_email_letter', this.disableEmailLetter);
            },
            watch: {
            payment_id(){
                this.fetchPayment();
            },
            errorList () {
                if(this.errorHas(this.$options.name)) {
                this.refundFlag = false
                }
            },
            reversalError(newVal) {
                if(newVal === '') {
                this.refetchPaymentInfo();
                } else if(newVal?.length) {
                this.showMessageNotification({type:'error', list: [newVal] })
                }
            }
            },
            props: ['payment_id', 'na_id','value', 'hideHeader', 'modal', 'fromMasterVueFile']
        }
    
    </script>
<style scoped>
ul.dropdown-menu {
    max-height: 400px;
}

.inv-header-cell {
    background: #F4F6F8;
}

.hb-inv-number {
    color: #306FB6;
}

span {
    color: #101318;
}

.hb-link-secondary {
    color: #306FB6 !important;
    text-decoration: none;
}

.hb-link {
    color: #00848E;
    text-decoration: none;
}

.form-section {
    background: #F9FAFB;
    border: 0px;
    margin-bottom: 0px;
    padding-bottom: 15px;
}

.letter-btn {
    float: right;
}

.disabled-row {
    background: #F9FAFB;
    opacity: 0.6;
}

.edit-payment-header-title-wrapper {
    background: #E0F5F5;
    height: 52px;
}
</style>