import api from '../../assets/api.js';
import Vue from "vue";

const types = {
    SET_PROPERTIES: 'SET_PROPERTIES',
    SET_LEASED_PROPERTIES: 'SET_LEASED_PROPERTIES',
    SET_FILTERED: 'SET_FILTERED',
    SET_DEFAULT_PROPERTY: 'SET_DEFAULT_PROPERTY',
    SET_FILTER: 'SET_FILTER',
    SET_PROPERTY: 'SET_PROPERTY',
    SET_NON_HB_PROPERTIES: 'SET_NON_HB_PROPERTIES',
    SET_PRIMARY_ROLE_PROPERTIES: 'SET_PRIMARY_ROLE_PROPERTIES',
    SET_INTER_PROPERTY_SETTING: 'SET_INTER_PROPERTY_SETTING',
    SET_BILLING_CYCLE_SETTING: 'SET_BILLING_CYCLE_SETTING',
    SET_PAYMENT_CYCLE_SETTING: 'SET_PAYMENT_CYCLE_SETTING',
    SET_PROPERTY_ACCOUNTING_TEMPLATE: 'SET_PROPERTY_ACCOUNTING_TEMPLATE',
    SET_BULKEDIT_PROPERTY: 'SET_BULKEDIT_PROPERTY',
    SET_IS_PROPERTY_LOADED: 'SET_IS_PROPERTY_LOADED',
    CLEAR_DATA: 'CLEAR_DATA',
    SET_PROPERTY_GROUPS: 'SET_PROPERTY_GROUPS',
    SET_SELECTED_PROPERTY_GROUP: 'SET_SELECTED_PROPERTY_GROUP',
    SET_FILTERED_PROPERTY_GROUPS: 'SET_FILTERED_PROPERTY_GROUPS',
}

class Properties {
	constructor() {
		this.namespaced = true;
		this.state = {
            properties: [],
            primaryRoleProperties: [],
            nonHBProperties: [],
            leasedProperties: [],
            filtered: [],
            filter: [],
            property: {},
            defaultProperty: {},
            isInterPropertyPayment: false,
            allowBillingCycle: false,
            allowPaymentCycle: false,
            propertyAccountingTemplate: {},
            bulkEditProperty: null,
            isPropertyLoaded: false,
            propertyGroups: [],
            selectedPropertyGroup: {},
            filteredPropertyGroups: []
		};
		this.getters = {
            properties: state => state.properties,
            leasedProperties: state => state.leasedProperties,
            filtered: state => state.filtered.length ? state.properties.filter(p => state.filtered.indexOf(p.id) >= 0) : state.properties,
            filter: state => state.filter,
            property: state => state.property,
            defaultProperty: state => state.defaultProperty,
            primaryRoleProperties: state => state.primaryRoleProperties,
            nonHbProperties: state => state.nonHBProperties,
            isInterPropertyPayment: state => state.isInterPropertyPayment,
            isPaymentCycleAllowed: state => state.allowPaymentCycle,
            isBillingCycleAllowed: state => state.allowBillingCycle,
            propertyAccountingTemplate: state => state.propertyAccountingTemplate,
            bulkEditProperty: state => state.bulkEditProperty ?? null, 
            isPropertyLoaded: state => state.isPropertyLoaded,
            propertyGroups: state => state.propertyGroups,
            selectedPropertyGroup: state => state.selectedPropertyGroup,
            filteredPropertyGroups: state => state.filteredPropertyGroups
		};

		this.actions = {
            async fetchPropertyAccountingTemplate({commit}, params) {
                const { id } = params;
                await api.get(this, api.PROPERTIES + id +'/accounting-template').then(payload => {
                    commit(types.SET_PROPERTY_ACCOUNTING_TEMPLATE, payload);
                });
            },
            async setPropertyAccountingTemplate({commit, dispatch}, params) {
                const { property_id, accounting_template_id } = params;
                await api.put(this, api.PROPERTIES + property_id +'/accounting-template/' + accounting_template_id).then(payload => {
                    dispatch('fetchPropertyAccountingTemplate', {id: property_id});
                });
            },
            async setProperties({commit}, params) {
                let all_hb_facilities = JSON.parse(localStorage.getItem('hb_properties')|| "[]");
                if(!all_hb_facilities.length){
                    await api.get(this.$app, api.PROPERTIES, params).then(r => {
                        localStorage.setItem('hb_properties', JSON.stringify(r.properties));
                        all_hb_facilities = r.properties;
                    });  
                }

                commit(types.SET_PROPERTIES, all_hb_facilities);
                commit('authenticationStore/SET_HB_USER', !!all_hb_facilities?.length,  { root: true });
            },
            async getProductsByType({}, params) {
                let r = await api.get(this.$app, api.PROPERTIES + params);
                return r.products;
            },
            async setLeasedProperties({commit}, contact_id) {
                await api.get(this, api.CONTACTS + contact_id + '/leased-properties').then(r => {
                    commit(types.SET_LEASED_PROPERTIES, r);
                });
            }, 
            setProperty({ commit }, property){
                commit(types.SET_PROPERTY, property);
            },
            setDefaultProperty({ commit }, property){
                commit(types.SET_DEFAULT_PROPERTY, property);
            },
            async exchangeToken({commit, dispatch, getters}, event) {

                localStorage.removeItem('hb_user_details');

                event.property_group_id = getters.selectedPropertyGroup?.id || '';
                let r = await api.post(this.$app, api.SET_PROPERTIES, event)
                if(!r.token) return;
                await dispatch('authenticationStore/authenticate', {
                    is_authenticated: true,
                    auth_token: r.token
                }, {root: true})
                
                commit(types.SET_FILTERED, event.property_ids);
            },
            setFiltered({commit, dispatch}, filtered){
                commit(types.SET_FILTERED, filtered || []);
            },
            async addProperty({commit, dispatch}, payload){
                await  api.post(this.$app, api.PROPERTIES, payload).then(r => {
                    dispatch('propertiesStore/resetPropertiesInfo', {all: true}, {root:true});
                });
            },
            async editProperty({commit, dispatch}, payload){
                await api.put(this.$app, api.PROPERTIES + payload.id, payload).then(async r => {
                    await dispatch('propertiesStore/resetPropertiesInfo', {all: true}, {root:true});
                });
            },
            async setBillingSettings({commit, dispatch}, payload){
                await api.get(this.$app, api.SETTINGS + '?category=billing').then(async r => {
                    let value = false;
                    let allowBillingCycle = false;
                    let allowPaymentCycle = false;
                    if(r.settings?.length > 0) {
                        let settingObj = null;
                        settingObj = r.settings.find(x=>x.name === 'allowInterPropertyPayments');
                        value = settingObj?.value;
                        value = value === '1';

                        let billingCycle = r?.settings?.find(x=>x.name === 'allowBillingCycle');
                        allowBillingCycle = billingCycle?.value === '1'

                        let paymentCycle = r?.settings?.find(x=>x.name === 'allowPaymentCycle');
                        allowPaymentCycle = paymentCycle?.value === '1'
                    }
                    commit(types.SET_INTER_PROPERTY_SETTING, value || false);
                    commit(types.SET_BILLING_CYCLE_SETTING, allowBillingCycle || false);
                    commit(types.SET_PAYMENT_CYCLE_SETTING, allowPaymentCycle || false);
                });
            },
            async setPrimaryRoleProperties({commit, getters, rootState}, payload) {
                let hb_primary_role_props = JSON.parse(localStorage.getItem('hb_primary_role_props')|| "{}");
                if(Object.keys(hb_primary_role_props).length == 0){
                    const { userId } = payload;
                    hb_primary_role_props = await api.get(this.$app, api.ADMIN + userId);
                    localStorage.setItem('hb_primary_role_props',JSON.stringify(hb_primary_role_props))
                }

                const { admin } = hb_primary_role_props;
                const { RolesProperties } = admin;
                let primaryRoleId = rootState.authenticationStore.primary_role_id
                let primary_role = RolesProperties?.filter(r => r.role_id === primaryRoleId)
                let properties = primary_role[0]?.Properties || []
                let primaryRolePropertiesList = [];
                getters.properties.forEach(p => {
                    let index = properties.findIndex(x=> x === p.id)
                    if (index !== -1) {
                        primaryRolePropertiesList.push(p)
                    }
                });

                commit(types.SET_PRIMARY_ROLE_PROPERTIES, primaryRolePropertiesList || []);
                commit(types.SET_IS_PROPERTY_LOADED, true)

            },
            async setNonHBProperties({commit, dispatch}, params){
                let non_hb_facilities = JSON.parse(localStorage.getItem('non_hb_prop')|| "[]");
                if(!non_hb_facilities.length){
                    await api.get(this, api.NON_HB_PROPERTIES, params).then(r => {
                        const properties = r.properties.map(p => {
                            return  p?.gds_id ?? ""
                        });
                        non_hb_facilities = properties;
                        localStorage.setItem('non_hb_prop', JSON.stringify(non_hb_facilities));
                    });
                }
                commit(types.SET_NON_HB_PROPERTIES, non_hb_facilities );
            },
            clearData({commit},payload){
                commit(types.CLEAR_DATA);

            },
            setBulkEditProperty({commit, dispatch}, property) {
                commit(types.SET_BULKEDIT_PROPERTY, property || null)
            },
            async resetPropertiesInfo({commit, dispatch}, payload){
                localStorage.removeItem('hb_properties');
                localStorage.removeItem('hb_primary_role_props');
                let userDetails = JSON.parse( localStorage.getItem('hb_user_details')  || "{}");

                await dispatch('propertiesStore/setProperties', {all: true}, {root:true});
                if(userDetails?.contact?.id)
                    await dispatch('propertiesStore/setPrimaryRoleProperties', {userId: userDetails?.contact?.id}, {root:true});
            },
            async fetchPropertyGroups({commit, dispatch, getters}, payload = {}) {
                let { hardRefresh } = payload
                let hbPropertyGroups = JSON.parse(localStorage.getItem('hb_property_groups')|| "[]");

                if(!hbPropertyGroups.length || hardRefresh) {
                    try {
                        let propertyGroups = await api.get(this.$app, api.PROPERTY_GROUPS);
                        localStorage.setItem('hb_property_groups', JSON.stringify(propertyGroups.property_groups));
                        hbPropertyGroups = propertyGroups.property_groups
                    }
                    catch(err) {
                        throw err
                    }
                }

                commit(types.SET_PROPERTY_GROUPS, hbPropertyGroups || []);
                dispatch('setFilteredPropertyGroups');

                let selectedPropertyGroup = getters.selectedPropertyGroup;
                if(selectedPropertyGroup.id) {
                    selectedPropertyGroup = hbPropertyGroups.find(pg => pg.id === selectedPropertyGroup.id);
                }

                commit(types.SET_SELECTED_PROPERTY_GROUP, selectedPropertyGroup || {});
            },
            async addPropertyGroup({dispatch}, payload) {
                try {
                    await api.post(this.$app, api.PROPERTY_GROUPS, payload);
                    await dispatch('fetchPropertyGroups', {hardRefresh: true});
                }
                catch(err) {
                    throw err;
                }
            },
            async deletePropertyGroup({dispatch}, payload) {
                try {
                    await api.delete(this.$app, `${api.PROPERTY_GROUPS}/${payload.id}`);
                    await dispatch('fetchPropertyGroups', {hardRefresh: true});
                }
                catch(err) {
                    throw err;
                }
            },
            async updatePropertyGroup({dispatch}, payload) {
                let {propertyGroupId, data} = payload;
                try {
                    await api.put(this.$app, `${api.PROPERTY_GROUPS}/${propertyGroupId}`, data);
                    await dispatch('fetchPropertyGroups', {hardRefresh: true});
                }
                catch(err) {
                    throw err;
                }
            },
            setSelectedPropertyGroup({commit}, payload) {
                commit(types.SET_SELECTED_PROPERTY_GROUP, payload || {});
            },
            populateSelectedGroup({getters, dispatch}, property_group_id) {
                let selected_property_group = getters.propertyGroups.find(pg => pg.id == property_group_id)
                dispatch('setSelectedPropertyGroup', selected_property_group)
            },
            resetSelectedPropertyGroup({commit}) {
                commit(types.SET_SELECTED_PROPERTY_GROUP, {});
            },
            setFilteredPropertyGroups({commit, getters}) {
                if((getters.properties.length === getters.filtered.length)|| !getters.filtered.length){
                    commit(types.SET_FILTERED_PROPERTY_GROUPS, getters.propertyGroups) 
                    return;
                }

                let selectedProperties = getters.filtered.map(p => p.id)
                selectedProperties = new Set(selectedProperties);
                let selectedPropertyGroups = getters.propertyGroups.filter(pg => pg.properties.every(p => selectedProperties.has(p.id)))

                commit(types.SET_FILTERED_PROPERTY_GROUPS, selectedPropertyGroups)
            }
		};
		this.mutations = {
            [types.SET_PROPERTIES](state, properties) {
                state.properties = properties;
            },
            [types.SET_LEASED_PROPERTIES](state, leasedProperties) {
                state.leasedProperties = leasedProperties;
            },
            [types.SET_FILTERED](state, filtered) {
                state.filtered = filtered;
            },
            [types.SET_FILTER](state, filter) {
                state.filter = filter;
            },
            [types.SET_PROPERTY](state, property) {
                state.property = property;
            },
            [types.SET_DEFAULT_PROPERTY](state, defaultProperty) {
                state.defaultProperty = defaultProperty;
            },
            [types.SET_PRIMARY_ROLE_PROPERTIES](state, properties) {
                state.primaryRoleProperties = properties;
            },
            [types.SET_NON_HB_PROPERTIES](state, properties) {
                state.nonHBProperties = properties;
            },
            [types.SET_INTER_PROPERTY_SETTING](state, value) {
                state.isInterPropertyPayment = value;

            },
            [types.SET_BILLING_CYCLE_SETTING](state, value) {
                state.allowBillingCycle = value;

            },
            [types.SET_PAYMENT_CYCLE_SETTING](state, value) {
                state.allowPaymentCycle = value;

            },
            [types.SET_PROPERTY_ACCOUNTING_TEMPLATE](state, payload) {
                state.propertyAccountingTemplate = payload;
            },
            [types.SET_BULKEDIT_PROPERTY](state, property) {
                state.bulkEditProperty = property;
            },
            [types.SET_IS_PROPERTY_LOADED](state, payload) {
                state.isPropertyLoaded = payload;
            },
            [types.CLEAR_DATA](state, payload) {
                // Object.keys(state).forEach(key => {
                //   if(typeof state[key] == 'object')
                //     state[key] = {};
                //   else if (typeof state[key] == 'string')
                //     state[key] = "";
                //   else
                //     state[key] = null;
                // });

                state.properties = [],
                state.primaryRoleProperties = [],
                state.nonHBProperties = [],
                state.leasedProperties = [],
                state.filtered = [],
                state.filter = [],
                state.property = {},
                state.defaultProperty = {},
                state.isInterPropertyPayment = false,
                state.allowBillingCycle = false,
                state.allowPaymentCycle = false,
                state.propertyAccountingTemplate = {}
                state.isPropertyLoaded = false,
                state.propertyGroups = [],
                state.selectedPropertyGroup = {},
                state.filteredPropertyGroups = []
            },
            [types.SET_PROPERTY_GROUPS](state, payload) {
                state.propertyGroups = payload;
            },
            [types.SET_SELECTED_PROPERTY_GROUP](state, payload) {
                state.selectedPropertyGroup = payload;
            },
            [types.SET_FILTERED_PROPERTY_GROUPS](state, payload) {
                state.filteredPropertyGroups = payload;
            },
		};
	}
}

export default Properties;
