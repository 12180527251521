import store from "../store";
export default [
  {
    name: "Tenants",
    path: "/tenants",
    component: () => import("../components/tenants/Index.vue"),
    meta: {
      requiresAuth: true,
      hasAccess: ["admin"],
      layout: "master",
      re_fetch: true,
    },
    children: [
      {
        path: "/national-accounts", // Nested route for National Accounts
        name: "NationalAccounts",
        component: () =>
          import("../components/tenants/NationalAccountReport.vue"),
        meta: {
          requiresAuth: true,
          hasAccess: ["admin"],
          layout: "master",
          re_fetch: true,
        },
        // TODO 
        beforeEnter: (to, from, next) => {
          let permission = store.getters['authenticationStore/rolePermission']('manage_national_accounts')
          let contactData = JSON.parse(
            localStorage.getItem("contact_data") || "{}"
          );
          if (contactData?.contact?.Company?.Settings?.nationalAccountFeature != "1" || !permission) {
            next({
              path: "/tenants",
              replace: true,
            });
          }
          else{
            next()
          }
        },
      },
    ],
  },
  {
    path: "/my-account",
    component: () => import("../components/tenants/View.vue"),
    meta: {
      requiresAuth: true,
      layout: "unauthenticated",
      hasAccess: ["tenant"],
    },
  },
];
