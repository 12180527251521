<!-- BCT: Component separated from OverviewLeaseWidget -->
<template>
  <div>
    <span style="cursor:default;">
      <div v-if="autoPaymentMethods && autoPaymentMethods.length !== 0">
        <v-card flat v-for="(item, index) in autoPaymentMethods" :key="index" class="px-3">
          <v-list-item
            color="white"
            class="autopay-item my-0 pr-0 pl-0 mx-0"
            :class="{ 'pb-2': is_closed }"
            :key="index"
            :disabled="is_closed"
          >
            <v-list-item-icon class="mx-0 px-0 my-1 pt-2">
              <img
                v-if="item.type === 'card'"
                class="cc-icon"
                :src="item | getCCIcon"
              />
              <img
                v-else-if="item.type === 'ach'"
                class="cc-icon"
                :src="'/img/cc_icons/64/ach.png'"
              />
              <img
                v-else-if="item.type === 'directdebit'"
                class="cc-icon"
                :src="'/img/cc_icons/64/directdebit.png'"
              />
            </v-list-item-icon>
            <v-list-item-content class="mx-0 px-0 my-0 py-0">
              <v-list-item-title>
                <span
                  class="card-detail"
                  :class="{ 'hb-text-night': is_closed }"
                  >{{ item.name_on_card }}</span
                >
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  class="font-weight-medium text-uppercase hb-text-night hb-default-font-size"
                  >{{ item.card_type }} *{{ item?.card_end?.slice(-4) }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action dense>
              <hb-menu options v-if="!is_closed">
                <v-list>
                  <v-list-item class="pointer-cursor" @click="confirmRemoveAutoPay(item)">
                    Remove from AutoPay
                  </v-list-item>
                </v-list>
              </hb-menu>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-show="index < autoPaymentMethods.length - 1"></v-divider>
        </v-card>
      </div>

      <v-menu top close-on-content-click v-else class="ma-0 pa-0">
        <template v-slot:activator="{ on }">
          <v-card flat class="ma-0 pa-0" v-if="!is_closed && !deny_auto_pay">
            <v-list-item v-on="on" class="overview-lease-payment-methods-v-menu-click-to-add">
              <v-list-item-content class="hb-link">
                <v-list-item-title class="pb-1" style="padding-top: 3px"
                  >Click to Add</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card flat class="ma-0 pa-0 pt-3 pl-4" v-else>
            No AutoPay Added
          </v-card>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in paymentMethods"
            :key="index"
            @click="setToAutoPay(item)"
          >
            <v-list-item-icon v-if="item.type === 'card'"
              ><img class="cc-icon" :src="'/img/cc_icons/64/' + item.card_type + '.png'"
            /></v-list-item-icon>
            <v-list-item-icon v-else-if="item.type === 'ach'"
              ><img :src="'/img/cc_icons/64/ach.png'"
            /></v-list-item-icon>
            <v-list-item-icon v-else-if="item.type === 'directdebit'"
              ><img class="cc-icon" :src="'/img/cc_icons/64/directdebit.png'"
            /></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>****{{ item?.card_end?.slice(-4) }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item?.exp_warning | formatDateCustom('MM/YY')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="addPaymentMethod = true">
            <v-list-item-icon> <hb-icon>mdi-plus</hb-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Payment Method</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        class="hb-text-lighter mx-3"
        style="border-bottom: thin dashed;"
      ></div>
    </span>

    <add-payment-method
      v-if="addPaymentMethod"
      v-model="addPaymentMethod"
      :property_id="property_id"
      :contact="contact"
      @close="addPaymentMethod = false"
      @refreshData="refreshData"
    >
    </add-payment-method>

    <hb-modal
      size="small"
      confirmation
      title="Remove from AutoPay"
      v-model="confirmRemoveModal"
      @close="closeRemoveModal()"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-5">
          Are you sure you want to remove the AutoPay payment method?
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="destructive" @click="removeAutoPay(selectedAutopaymentMethod)">Remove</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import AddPaymentMethod from "../contacts/AddPaymentMethod.vue";

export default {
  name: "OverviewLeasePaymentMethods",
  props: [
    "autoPaymentMethods",
    "paymentMethods",
    "is_closed",
    "property_id",
    "contact",
    "deny_auto_pay",
    "is_billing_profile"
  ],
  data() {
    return {
      addPaymentMethod: false,
      selectedAutopaymentMethod: '',
      confirmRemoveModal: false
    };
  },
  components: {
    AddPaymentMethod
  },

  filters: {
    getCCIcon(method) {
      var icon =
        ["amex", "visa", "mastercard", "jcb"].indexOf(method.card_type) >= 0
          ? method.card_type
          : "credit_card";
      return "/img/cc_icons/64/" + icon + ".png";
    }
  },
  created() {},
  methods: {
    setToAutoPay(item){
      this.$emit('setToAutoPay', item);
    },

    confirmRemoveAutoPay(item){
      if(this.is_billing_profile){
        this.$emit('removeAutoPay', item);
      } else {
        this.selectedAutopaymentMethod = item;
        this.confirmRemoveModal = true;
      }
    },

    removeAutoPay(item) {
      this.$emit('removeAutoPay', item);
      this.selectedAutopaymentMethod = '';
      this.confirmRemoveModal = false;
    },

    closeRemoveModal(){
      this.selectedAutopaymentMethod = '';
      this.confirmRemoveModal = false;
    },

    refreshData(data) {
      this.$emit('refreshData', data);
    }
    
  }
};
</script>

<style lang="scss" scoped>
.cc-icon {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  width: 64px;
}

.overview-lease-payment-methods-v-menu-click-to-add.theme--light.v-list-item:hover::before {
  background-color: #999;
}
</style>
