import { render, staticRenderFns } from "./TransferSummary.vue?vue&type=template&id=7087f967&scoped=true&"
import script from "./TransferSummary.vue?vue&type=script&lang=js&"
export * from "./TransferSummary.vue?vue&type=script&lang=js&"
import style0 from "./TransferSummary.vue?vue&type=style&index=0&id=7087f967&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7087f967",
  null
  
)

export default component.exports