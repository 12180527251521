<template>
    <div>
        <div class="contacts-list">
            <div class="row mx-0 pb-3 show-edit-icon" v-for="(relation, index) in relatedContactsList" :key="relation.id">
                <div class="ma-0 pa-0 col col-11">
                    <div>{{ relation.first }} {{ relation.last }}</div>
                    <span class="text-body-2 pb-2 hb-text-light" v-if="getDesignations(relation.id)">{{getDesignations(relation.id) }}</span>
                </div>
                <div class="col col-1 d-flex justify-center align-center text-decoration-none pa-0">
                    <hb-icon class="edit-contact" @click="editContactEvent(relation, index)" small>mdi-pencil</hb-icon>
                </div>
            </div>
            <hb-link @click="showAddContactDialog">+ Add a Contact</hb-link>
        </div>
        <hb-modal v-model="dialog" size="large" v-if="dialog" :title="titleText" @close="closeDialog" show-help-link>
            <template v-slot:content>
                <hb-form label="Designate as" required>
                    <hb-combobox 
                        v-model="relationData.selected_designations_list" 
                        :items="nationalAccount ? designatedAsTypesForNa : designatedAsTypes"
                        v-validate="'required|max:45'"
                        data-vv-scope="contacts"
                        data-vv-as="Designation Type" 
                        item-text="name" 
                        item-value="id"
                        id="designation_type" 
                        name="designation_type"
                        :error="errors.collect('contacts.designation_type').length > 0" 
                        label="Select contact type"
                        @change="validateDesignation"
                        :showSelectAll="false"></hb-combobox>
                </hb-form>

                <hb-form label="Name" required>
                    <v-row class="mx-0">
                        <v-col cols="6" class="ma-0 pa-0 pr-3">
                            <hb-text-field 
                                v-validate="'required|max:45'" 
                                v-model="relationData.Contact.first" 
                                data-vv-as="first name" 
                                label="First Name"
                                single-line
                                id="first_name" 
                                name="first_name" 
                                :hide-details="!errors.collect('contacts.first_name').length"
                                :error="errors.collect('contacts.first_name').length > 0" 
                                data-vv-scope="contacts">
                            </hb-text-field>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                            <hb-text-field 
                                v-validate="'required|max:45'" 
                                v-model="relationData.Contact.last" 
                                data-vv-as="last name" 
                                label="Last Name"
                                single-line 
                                id="last_name" 
                                name="last_name"
                                :hide-details="!errors.collect('contacts.last_name').length"
                                :error="errors.collect('contacts.last_name').length > 0" 
                                data-vv-scope="contacts">
                            </hb-text-field>
                        </v-col>
                    </v-row>
                </hb-form>

                <hb-form label="Email" :required="isEmailRequired">
                    <hb-text-field 
                        v-validate="{ max:100, required: isEmailRequired ? true : false, email: true }"
                        v-model="relationData.Contact.email" 
                        data-vv-as="Email" 
                        label="Email" 
                        single-line 
                        id="relation_email" 
                        name="relation_email"
                        :hide-details="!errors.collect('contacts.relation_email').length"
                        :error="errors.collect('contacts.relation_email').length > 0" 
                        data-vv-scope="contacts">
                    </hb-text-field>
                </hb-form>

                <hb-form label="Phone">
                    <v-row class="ma-0 pa-0" v-for="(secondaryphone, index) in relationData.Contact.Phones" :key="index">
                        <v-col cols="12" class="ma-0 pa-0 pb-4">
                            <hb-select 
                                :items="phone_types" 
                                v-validate="{required: !relationData.Contact.email, max: 45}" 
                                v-model="secondaryphone.type"
                                label="Type" 
                                single-line 
                                data-vv-scope="contacts"
                                :id="'phone_type_' + index"
                                data-vv-as="phone type"
                                :name="'phone_type_' + index"
                                :hide-details="!errors.collect('contacts.phone_type_' + index).length"
                                :error="errors.collect('contacts.phone_type_' + index).length > 0" 
                                ></hb-select>
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0 pr-3">
                            <hb-autocomplete 
                                :items="countryCodesList"
                                v-validate="{required: !relationData.Contact.email}"
                                v-model="contactPhoneCode" 
                                label="Code"
                                :id="'phone_code_' + index"
                                data-vv-scope="contacts"
                                data-vv-as="phone code"
                                :name="'phone_code_' + index"
                                :hide-details="!errors.collect('contacts.phone_code_' + index).length"
                                :error="errors.collect('contacts.phone_code_' + index).length > 0"
                                @change="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                @focusout="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                single-line>
                                <template v-slot:selection="data">
                                    +{{ data.item }}
                                </template>
                                <template v-slot:item="data">
                                    +{{ data.item }}
                                </template>
                            </hb-autocomplete>
                        </v-col>
                        <v-col cols="5" class="ma-0 pa-0 pr-3">
                            <hb-text-field
                                v-validate="{required: !relationData.Contact.email, max: 45, numeric: true}" 
                                v-model="contactPhoneNumber"
                                label="Number"
                                @change="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                @focusout="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                placeholder="Phone Number"
                                :id="'relationship_phone_' + index"
                                :name="'relationship_phone_' + index"
                                data-vv-scope="contacts"
                                data-vv-as="alternate contact phone"
                                :hide-details="!errors.collect('contacts.relationship_phone_' + index).length"
                                :error="errors.collect('contacts.relationship_phone_' + index).length > 0 || checkValidPhone(contactPhoneCode, contactPhoneNumber) === false ? true : false"
                            />                            
                        </v-col>
                        <v-col cols="3" class="pa-0">
                            <hb-checkbox 
                                :id="'relationship_sms_' + index"
                                :name="'relationship_sms_' + index"
                                :data-name="'relationship_sms_' + index"
                                v-model="secondaryphone.sms" 
                                hide-details>
                                <template v-slot:label>
                                    <span class="text-capitalize">SMS</span>
                                </template>
                            </hb-checkbox>
                        </v-col>
                    </v-row>
                </hb-form>

                <hb-form label="Address" :required="isAddressRequired">
                    <v-row class="mx-0 pb-0">
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-row class="ma-0 pa-0">
                                <v-col cols="12" class="pl-0 mb-0 pb-0">
                                    <hb-autocomplete
                                        :items="countriesListMixin"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].Address.country"
                                        label="Country"
                                        single-line
                                        :id="'relationship_address_country'"
                                        data-vv-scope="contacts"
                                        data-vv-as="Country"
                                        :name="'relationship_address_country'"
                                        :hide-details="!errors.collect('contacts.relationship_address_country').length"
                                        :error="errors.collect('contacts.relationship_address_country').length > 0"
                                        @change="setRelationCountry"
                                    >
                                        <template v-slot:selection="data">
                                            {{data.item}}
                                        </template>
                                        <template v-slot:item="data">
                                            {{data.item}}
                                        </template>
                                    </hb-autocomplete>
                                </v-col>
                                <v-col cols="12" class="pl-0 mb-0 pb-0">
                                    <hb-select
                                        :items="location_types"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].type"
                                        label="Type"
                                        single-line
                                        hide-details
                                        :id="'relationship_address_type_'"
                                        :name="'relationship_address_type_'"
                                        data-vv-as="address type"
                                        data-vv-scope="contacts"
                                        :hide-details="!errors.collect('contacts.relationship_address_type_').length"
                                        :error="errors.collect('contacts.relationship_address_type_').length > 0"
                                        :clearable="true"
                                        @click:clear="relationData.Contact.Addresses[0].type = ''"
                                    ></hb-select>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col cols="12" class="pl-0 mb-0 pb-0">
                                    <hb-text-field
                                        :id="'relationship_address_address_'"
                                        :name="'relationship_address_address_'"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].Address.address"
                                        label="Street"
                                        single-line
                                        data-vv-as="Street"
                                        data-vv-scope="contacts"
                                        :hide-details="!errors.collect('contacts.relationship_address_address_').length"
                                        :error="errors.collect('contacts.relationship_address_address_').length > 0"
                                    ></hb-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col cols="12" class="pl-0 mb-0 pb-0">
                                    <hb-text-field
                                        :id="'relationship_address_address2_'"
                                        :name="'relationship_address_address2_'"
                                        v-model="relationData.Contact.Addresses[0].Address.address2"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        label="Suite / Apt"
                                        single-line
                                        data-vv-as="Suite / Apt"
                                        data-vv-scope="contacts"
                                        :hide-details="!errors.collect('contacts.relationship_address_address2_').length"
                                        :error="errors.collect('contacts.relationship_address_address2_').length > 0"
                                    ></hb-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col cols="3" class="px-0 pb-0 pr-3 mb-0">
                                    <hb-text-field
                                        :id="'relationship_address_zip_'"
                                        :name="'relationship_address_zip_'"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].Address.zip"
                                        label="Zip"
                                        single-line
                                        data-vv-as="zip"
                                        data-vv-scope="contacts"
                                        :hide-details="!errors.collect('contacts.relationship_address_zip_').length"
                                        :error="errors.collect('contacts.relationship_address_zip_').length > 0"
                                    ></hb-text-field>
                                </v-col>
                                <v-col cols="4" class="px-0 pb-0 pr-3 mb-0">
                                    <hb-autocomplete
                                        :items="relationStateListMixin[editContactIndex] || []"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].Address.state"
                                        label="State"
                                        item-text="name"
                                        item-value="value"
                                        :id="'relationship_address_state_'"
                                        :name="'relationship_address_state_'"
                                        single-line
                                        data-vv-scope="contacts"
                                        data-vv-name="relationship_address_state_"
                                        data-vv-as="State"
                                        :error="errors.collect('contacts.relationship_address_state_').length > 0"
                                        @change="setRelationState"
                                    >
                                    </hb-autocomplete>
                                </v-col>
                                <v-col cols="5" class="px-0 pb-0 pr-3 mb-0">
                                    <hb-autocomplete
                                        :items="relationCityListMixin[editContactIndex] || []"
                                        v-validate="isAddressRequired ? 'required' : ''"
                                        v-model="relationData.Contact.Addresses[0].Address.city"
                                        label="City"
                                        single-line
                                        :id="'relationship_address_city_'"
                                        :name="'relationship_address_city_'"
                                        data-vv-scope="contacts"
                                        data-vv-name="relationship_address_city_"
                                        data-vv-as="city"
                                        :error="errors.collect('contacts.relationship_address_city_').length > 0"
                                    >
                                        <template v-slot:selection="data">
                                            {{data.item}}
                                        </template>
                                        <template v-slot:item="data">
                                            {{data.item}}
                                        </template>
                                    </hb-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </hb-form>

            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="saveNewRelationship" :disabled="isSaveLoading" :loading="isSaveLoading">Save</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script>

import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import api from '../../assets/api';
import { EventBus } from '../../EventBus.js';
import moment from 'moment';

export default {
    name: "AdditionalContact",
    mixins: [ countryStateCityMixin, notificationMixin ],
    props: {
        lease: {
            type: Object,
            default: null
        },
        contact: {
            type: Object,
            default: null
        },
        nationalAccount:{
            type: Boolean,
        }
    },
    data() {
        return {
            isEditEnable: false,
            combobox1: [],
            relationData: {
                id: null,
                lease_id: '',
                related_contact_id: null,
                selected_designations_list: [],
                type: '',
                Contact: {
                    id: null,
                    first: '',
                    last: '',
                    email: '',
                    Phones: [
                        {
                            id: null,
                            phone: '',
                            type: '',
                            sms: false
                        }
                    ],
                    Addresses:[{
                        type:'',
                        Address:{
                            address: '',
                        address2: '',
                        country: this.defaultCountry,
                        city: '',
                        state: '',
                        zip: '',
                        },
                    }]
                },
            },
            dialog: false,
            phone_types: ['Phone', 'Cell', 'Home', 'Work', 'Fax', 'Other'],
            phone_types_m: ['Cell', 'Home', 'Work', 'Other'],
            designatedAsTypes: [
                {
                    name: "Alternate",
                    id: "is_alternate"
                },
                {
                    name: "Emergency",
                    id: "is_emergency"
                },
                {
                    name: "Authorized Access",
                    id: "is_authorized"
                },
                {
                    name: "Lien Holder",
                    id: "is_lien_holder"
                }
            ],
            designatedAsTypesForNa:[
                    {
                        name: "Authorized Access",
                        id: "is_authorized",
                        disabled: false,
                    },
                    {
                        name: "Representative",
                        id: "is_national_account_representative",
                        disabled: false,
                    }
                ], 
            defaultCountry: 'United States',
            countryCodesList: ["1", "7", "20", "27", "30", "31", "32", "33", "34", "36", "39", "40", "41", "43", "44", "45", "46", "47", "48", "49", "51", "52", "53", "54", "55", "56", "57", "58", "60", "61", "62", "63", "64", "65", "66", "81", "82", "84", "86", "90", "91", "92", "93", "94", "95", "98", "211", "212", "213", "216", "218", "220", "221", "222", "223", "224", "225", "226", "227", "228", "229", "230", "231", "232", "233", "234", "235", "236", "237", "238", "239", "240", "241", "242", "243", "244", "245", "246", "247", "248", "249", "250", "251", "252", "253", "254", "255", "256", "257", "258", "260", "261", "262", "263", "264", "265", "266", "267", "268", "269", "290", "291", "297", "298", "299", "350", "351", "352", "353", "354", "355", "356", "357", "358", "359", "370", "371", "372", "373", "374", "375", "376", "377", "378", "380", "381", "382", "383", "385", "386", "387", "389", "420", "421", "423", "500", "501", "502", "503", "504", "505", "506", "507", "508", "509", "590", "591", "592", "593", "594", "595", "596", "597", "598", "599", "670", "672", "673", "674", "675", "676", "677", "678", "679", "680", "681", "682", "683", "685", "686", "687", "688", "689", "690", "691", "692", "850", "852", "853", "855", "856", "880", "886", "960", "961", "962", "963", "964", "965", "966", "967", "968", "970", "971", "972", "973", "974", "975", "976", "977", "992", "993", "994", "995", "996", "998"],
            phoneCode: '1',
            phoneNumber: '',
            invalidPhone: null,
            contactPhoneCode: '1',
            contactPhoneNumber: '',
            location_types: ['Home', 'Work', 'Other'],
            editContactIndex: 0,
            hasDesignationError: false,
            form: null,
            isSaveLoading: false
        }
    },
    created() {
        this.setCountriesListEvent();
        this.updateForm(JSON.parse(JSON.stringify(this.contact)));
    },
    computed: {
        titleText() {
            return `${ (this.isEditEnable ? 'Edit Contact' :  'Add Contact') + (this.lease?.Unit?.number ? ' for Space ' + this.lease?.Unit?.number : '')}`;
        },
        relatedContactsList() {
            let leaseId = this.lease.id;
            if(this.contact?.Relationships) {
                let relationships = this.contact?.Relationships;
                if(relationships.length && relationships.filter(item => item.lease_id === leaseId).length) {
                    return relationships.filter(item => item.lease_id === leaseId).map(map_item => map_item.Contact)
                }
            }
            return [];
        },
        isAddressRequired() {
            // let designation = this.relationData.selected_designations_list;
            // let found = designation.length && designation.some(item => (item.id === 'is_authorized' || item.id === 'is_emergency'));
            return false;
        },


        isEmailRequired() {
            let designation = this.relationData.selected_designations_list;
            let found = designation.length && designation.some(item => (item.id === 'is_authorized'));
            return found;
        }
    },
    watch: {
        contact(){
            this.updateForm(JSON.parse(JSON.stringify(this.contact)));
        }
    },
    methods: {
        phoneMask(code) {
            switch (parseInt(code)) {
                case 1:
                    return "(###) ###-####";
                default:
                    return "";
            }
        },
        updateForm(data) {
            this.form = {
                salutation: data.salutation,
                first: data.first,
                middle: data.middle,
                last: data.last,
                suffix: data.suffix,
                email: data.email,
                notes: data.notes,
                company: data.company,
                dob: data.dob,
                ssn: data.ssn ? data.ssn.toString(): null,
                gender: data.gender,
                driver_license: data.driver_license,
                driver_license_exp: moment(data.driver_license_exp).format('YYYY-MM-DD') === 'Invalid date' ? '' : moment(data.driver_license_exp).format('YYYY-MM-DD'),
                driver_license_country: data.driver_license_country || this.defaultCountry,
                driver_license_state: data.driver_license_state,
                driver_license_city: data.driver_license_city,
                active_military: data.active_military ? true: false,
                Phones: [],
                Addresses: [],
                Relationships: [],
                Vehicles: [],
                Employment: [],
                Military: {}
            }

            if(data.Military.id){
                this.form.Military = data.Military
            }


            if(data.Phones){
                this.form.Phones = data.Phones.map(p => {
                    return {
                        id: p.id,
                        sms: p.sms,
                        phone: p.phone,
                        type: p.type,
                        verificationToken: p?.verification_token,
                        phone_verified: p?.phone_verified
                    }
                })
            }

            if(data.Addresses) {
                this.form.Addresses = data.Addresses.map((a, i)=> {
                    const countryValue = a.Address.country || this.defaultCountry
                    return {
                        id: a.id,
                        type: a.type,
                        Address: {
                            address: a.Address.address,
                            address2: a.Address.address2,
                            city: a.Address.city,
                            state: a.Address.state,
                            zip: a.Address.zip,
                            country: countryValue
                        }
                    }
                })
            }
            
            if(data.Vehicles){
                this.form.Vehicles = data.Vehicles.map(p => {
                    return {
                        id: p.id,
                        make: p.make,
                        model: p.model,
                        year: p.year,
                        state: p.state,
                        plate: p.plate
                    }
                })
            }

            if(data.Employment){
                this.form.Employment = data.Employment.map(p => {
                    return {
                        id: p.id,
                        employer: p.employer,
                        start_date: p.start_date ? moment(p.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY'): null,
                        end_date: p.end_date ? moment(p.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY'): null,
                        position: p.position,
                        status: p.status && p.status[0] && p.status[0]?.length > 0 ? p.status : [],
                        supervisor: p.supervisor,
                        phone: p.phone,
                        salary: p.salary,
                        salary_timeframe: p.salary_timeframe
                    }
                })
            }

            if(data.Relationships){
                this.form.Relationships = data.Relationships;
            }
        },
        async saveNewRelationship() {
            this.updateForm(JSON.parse(JSON.stringify(this.contact)));
            let status = await this.$validator.validateAll('contacts');
            console.log(this.errors)
            if (!status) {
                this.errorSet(this.$options.name, "There are errors in the section. Please correct them before continuing.");
                return
            }
            if (this.contactPhoneNumber){
                let parsedPhoneNumber = parsePhoneNumber('+' + this.contactPhoneCode + this.contactPhoneNumber);
                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                    this.relationData.Contact.Phones[0].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                } else {
                    this.showMessageNotification({ type: 'error', description: 'Please Enter a Valid Phone Number.'});
                    return;
                }
            }
            else{
                this.relationData.Contact.Phones[0] = {
                    id: null,
                    phone: '',
                    type: '',
                    sms: false
                };
            }
            this.isSaveLoading = true;

            this.form.Relationships.forEach((item, index) => {
                if(item.Contact?.id === this.relationData.Contact?.id) {
                    item.Contact = this.relationData.Contact;
                }
                delete item.Contact?.Phones[0]?.phone_verified;
                delete item.Contact?.Phones[0]?.verification_token;
                if(item.lease_id === this.lease.id && this.relationData.related_contact_id === item.related_contact_id) {
                    this.designatedAsTypes.forEach(d => {
                        let found = this.relationData.selected_designations_list.find(sd => sd.id == d.id);
                        if(found) item[d.id] = 1;
                        else item[d.id] = 0;
                    })
                }
            }); 

            if(!this.relationData.related_contact_id) {
                let data = {
                    lease_id: this.lease.id,
                    related_contact_id: '',
                    id: '',
                    Contact: this.relationData.Contact,
                };
                this.designatedAsTypes.forEach(d => {
                    let found = this.relationData.selected_designations_list.find(sd => sd.id == d.id);
                    if(found) data[d.id] = 1;
                    else data[d.id] = 0;
                })
                data["is_business_representative"] = 0;
                this.form.Relationships.push(data);
            }

            // if(this.foundAlternateForSpace){
            //     this.showMessageNotification({ type: 'error', description: `Alternate for this space has already been associated. please update designations.` });
            //     this.isSaveLoading = false;
            //     return;
            // }

            try {
                let r = await api.put(this, api.CONTACTS + this.contact.id + "?scope=contacts",  this.form);
                this.closeDialog()
                EventBus.$emit('contact_updated',  this.contact.id);
            } catch( err ) {
                this.showMessageNotification({
                    type: "error",
                    description: err,
                });
            } finally {
                this.isSaveLoading = false;
            }
        },
        getDesignations(params) {
            let response = '';
            let relationshipData = this.contact.Relationships.find(item => item.related_contact_id === params && item.lease_id === this.lease.id);
            for (let index = 0; index < this.designatedAsTypes.length; index++) {
                const element = this.designatedAsTypes[index];
                if (relationshipData[element.id]) {
                    response += `${element.name},`
                }
            }
            response = response.substring(0, response.length - 1);
            return response;
        },
        editContactEvent(contact, index) {
            if(!this.hasPermission('edit_business_rep_and_alc')){
                this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                return;
            }
            let relationshipData = this.contact.Relationships.find(item => item.related_contact_id === contact.id && item.lease_id === this.lease.id);
            relationshipData = JSON.parse(JSON.stringify(relationshipData));
            relationshipData.Contact["Addresses"] = relationshipData.Contact["Addresses"].length ? relationshipData.Contact["Addresses"] : [{
                type:'',
                Address:{
                    address: '',
                address2: '',
                country: this.defaultCountry,
                city: '',
                state: '',
                zip: '',
                },
            }];
            relationshipData.Contact["Phones"] = relationshipData.Contact["Phones"].length ? relationshipData.Contact["Phones"] : [{
                id: null,
                sms: false,
                phone: '',
                type: ''
            }];
            this.relationData = {
                id: relationshipData.id,
                related_contact_id: relationshipData.related_contact_id,
                selected_designations_list: this.nationalAccount ? this.designatedAsTypesForNa.filter(item => relationshipData[item.id] == 1): this.designatedAsTypes.filter(item => relationshipData[item.id] == 1) ,
                type: relationshipData.type,
                Contact: relationshipData.Contact,
            };
            this.isEditEnable = true;
            this.dialog = true;
            const countryValue = contact.Addresses[0]?.Address?.country || this.defaultCountry;
            this.setRelationStatesListEvent(countryValue, this.editContactIndex);
            const stateValue = contact.Addresses[0]?.Address?.state || '';
            if(stateValue){
                this.setRelationCitiesListEvent(countryValue, stateValue, this.editContactIndex)
            }

            if (this.relationData.Contact?.Phones[0]?.phone){
                var parseEditedPhone = parsePhoneNumber('+' + this.relationData.Contact.Phones[0].phone);
                this.contactPhoneCode = parseEditedPhone.countryCallingCode || '1';
                this.contactPhoneNumber = parseEditedPhone.nationalNumber || '';
            }
        },
        showAddContactDialog(params = true) {
            if(!this.hasPermission('edit_business_rep_and_alc')){
                this.showMessageNotification({ description: 'You do not have permission to add/edit/remove the Business Representative & Alternate Contact.' }) 
                return;
            }

            this.isEditEnable = false;
            this.relationData = {
                id: null,
                related_contact_id: null,
                selected_designations_list: [],
                type: '',
                Contact: {
                    id: null,
                    first: '',
                    last: '',
                    email: '',
                    Phones: [
                        {
                            id: null,
                            phone: '',
                            type: '',
                            sms: false
                        }
                    ],
                    Addresses:[{
                        type:'',
                        Address:{
                            address: '',
                        address2: '',
                        country: this.defaultCountry,
                        city: '',
                        state: '',
                        zip: '',
                        },
                    }]
                },
            };

            this.contactPhoneCode = '1';
            this.contactPhoneNumber = '';
            if(params) {
                this.dialog = true;
                this.setRelationStatesListEvent(this.defaultCountry, this.editContactIndex)
            }
        },

        setRelationCountry(){
            this.setRelationStatesListEvent(this.relationData.Contact.Addresses[0].Address.country, this.editContactIndex)
            this.relationData.Contact.Addresses[0].Address.state = ''
            this.relationData.Contact.Addresses[0].Address.city = ''
        },
        setRelationState(){
            this.setRelationCitiesListEvent(this.relationData.Contact.Addresses[0].Address.country, this.relationData.Contact.Addresses[0].Address.state, this.editContactIndex)
            this.relationData.Contact.Addresses[0].Address.city = ''
        },
        closeDialog() {
            this.dialog = false;
            this.errors.clear();
            this.showAddContactDialog(false);
        },
        checkValidPhone(code, number){
            const emailPhoneError= "Either Email or Phone is required"
            const emailPhoneRequired = this.errors.items.indexOf(emailPhoneError)

            if(!this.validateEmailPhone() && emailPhoneRequired >= 0){
                this.errors.items.splice(emailPhoneRequired);
            }
            if(code && number){
            var parsedPhoneNumber = parsePhoneNumber('+' + code + number);

            var r = null;
            if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                this.invalidPhone = false;
                r = true;
            } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number?.length > 0) {
                this.invalidPhone = true;
                r = false;
            } else {
                this.invalidPhone = false;
                r = true;
            }
            return r;
            }
        },
        validateEmailPhone(){
            if(!this.relationData.Contact.email && (!this.relationData.Contact.Phones[0]?.phone)){
                return true
            }
            return false
        },
        validateDesignation() {
            let leaseId = this.lease.id;
            let leaseData = this.contact.Relationships.filter(item => item.lease_id === leaseId);
            let designations = this.relationData.selected_designations_list;
            let findDesignation = 'is_alternate';
            let foundDesignation = designations.length && designations.find(item => item.id === findDesignation);
            if (leaseData.length && foundDesignation) {
                if(this.relationData.related_contact_id) {
                    let foundSameDesignateContact = leaseData.find(item => item.related_contact_id !== this.relationData.related_contact_id);
                    if (foundSameDesignateContact) {
                        console.log('other contact found with alternate');
                        this.hasDesignationError = true;
                    }
                } else {
                    let foundSameDesignateContact = leaseData.find(item => item.is_alternate === 1);
                    if (foundSameDesignateContact) {
                        console.log('other contact found with alternate');
                        this.hasDesignationError = true;
                    }
                }
            }
        }
    }
}
</script>


<style scoped>
.contact-list-border {
    border-bottom:1px solid #e0e0e0;
}
.show-edit-icon:hover .edit-contact {
    display: block;
}
.edit-contact {
    cursor: pointer;
    display: none;
}
</style>