<template>
    <hb-modal :title="`${planDetails?.id ? 'Edit' : 'Create'} Advanced Reservations and Rentals Plan`" v-model="dialog" @close="resetPlan()">
        <template v-slot:subheader>
            This feature allows managers to set specific rules for when customers can reserve or rent spaces ahead of
            time. You can define how far in advance reservations can be made, and customize advanced rental windows for
            better control over space availability based on occupancy.
        </template>
        <template v-slot:content>
            <div v-if="dialog">
                <hb-form label="Plan Name" medium required>
                    <HbTextField v-model="form.name" v-validate="'required'" data-vv-scope="default" data-vv-name="name"
                        data-vv-as="Name" :error="errors.collect('default.name').length > 0" placeholder="Enter Name" />
                </hb-form>
                <hb-form label="Description" medium>
                    <HbTextarea v-model="form.description" v-validate="'max:255'" data-vv-name="description"
                        data-vv-scope="default" data-vv-as="Description" :maxlength="255"
                        :error="errors.collect('default.description').length > 0" placeholder="Enter Description"
                        placeholder-fix />
                </hb-form>
                <hb-form label="Advanced Reservations"
                    description="Adjusts the reservation window based on current occupancy levels. Inputting 0 days means no reservations are being taken and 1 day means today."
                    medium full>
                    <apw-plan-threshold v-for="(reservation, index) in form.reservation_settings" ref="apwThreshold"
                        :key="index" :thIndex="index" v-model="form.reservation_settings[index]" :reservation="true"
                        @delete-threshold="removeReservationThreshold"></apw-plan-threshold>
                    <hb-link @click="addReservationSetting()">+ Add Threshold</hb-link>
                </hb-form>
                <hb-form label="Advanced Rentals" 
                description="Adjusts the rental window based on current occupancy levels. Inputting 0 days means no advance rental are being taken and 1 day means today."
                medium full>
                    <div>
                        <apw-plan-threshold v-for="(rental, index) in form.rental_settings" ref="apwThreshold"
                            :key="index" :thIndex="index" v-model="form.rental_settings[index]" :reservation="false"
                            @delete-threshold="removeRentalThreshold"></apw-plan-threshold>
                    </div>
                    <hb-link @click="addRentalSetting()">+ Add Threshold</hb-link>
                </hb-form>
            </div>
            <confirmation-pop-up v-if="planDetails?.id" v-model="editConfirmModel" type="editPlan" :message="`You are about to edit <strong>${planDetails?.name}</strong>. Editing this would apply the changes to all existing space groups that has this plan.`" title="Edit Plan" @edit-plan="updatePlan"></confirmation-pop-up>
        </template>
        <template v-slot:right-actions>
            <hb-btn v-if="planDetails?.id" @click="editConfirmModel = true">Update</hb-btn>
            <hb-btn v-else @click="savePlan">Save</hb-btn>
        </template>
    </hb-modal>
</template>
<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import ApwPlanThreshold from "./ApwPlanThreshold.vue";
import api from "../../assets/api";
import ConfirmationPopUp from "./ConfirmationPopUp.vue";
export default {
    name: 'ApwCreatePlan',
    mixins: [notificationMixin],
    props: ['value','planDetails'],
    data() {
        return {
            form: {
                name: '',
                description: '',
                reservation_settings: [{
                    occupancy_percentage: '0',
                    days: '0'
                }],
                rental_settings: [{
                    occupancy_percentage: '0',
                    days: '0'
                }],
            },
            editConfirmModel: false,
        };
    },
    created() {
        if(this.planDetails){
            this.form = JSON.parse(JSON.stringify(this.planDetails))
        }
    },
    computed:{
        dialog: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
    components: {
        ApwPlanThreshold,
        ConfirmationPopUp
    },
    methods: {
        openModal() {
            this.dialog = true;
        },
        addReservationSetting() {
            this.form.reservation_settings.push({
                occupancy_percentage: '',
                days: ''
            });
        },
        addRentalSetting() {
            this.form.rental_settings.push({
                occupancy_percentage: '',
                days: ''
            });
        },
        resetPlan() {
            this.$validator.errors.remove('duplicate');
            this.dialog = false;
            this.form = {
                name: '',
                description: '',
                reservation_settings: [{
                    occupancy_percentage: '0',
                    days: '0'
                }],
                rental_settings: [{
                    occupancy_percentage: '0',
                    days: '0'
                }],
            }
        },
        async validatePlan(data) {
            let allZeroReservation = this.form.reservation_settings.every(setting => parseInt(setting.occupancy_percentage) === 0);
            let allZeroRental = this.form.rental_settings.every(setting => parseInt(setting.occupancy_percentage) === 0);

            this.$validator.errors.remove('occupancy');
            if (allZeroReservation && allZeroRental && !this.$validator.errors.has("occupancy")) {
                this.$validator.errors.add({
                    field: 'occupancy',
                    msg: 'Please provide either rental or reservation occupancy.'
                });
            }

            let checker = arr => arr.every(v => v === true);

            // Collect all validations, including zero checks
            let allValidations = [];

            // Add zero condition validations
            allValidations.push(!allZeroReservation || !allZeroRental);

            // Add apwThreshold validations
            if (Array.isArray(this.$refs.apwThreshold)) {
                let apwThresholdValidations = this.$refs.apwThreshold.map(ref => {
                    if (ref && ref.$validator) {
                        return ref.$validator.validateAll("general");
                    } else {
                        return Promise.resolve(false);
                    }
                });
                allValidations = allValidations.concat(await Promise.all(apwThresholdValidations));
            }
            let error = await this.$validator.validateAll('default');


            this.$validator.errors.remove('duplicate');
            let reservationDuplicate = this.hasDuplicateOccupancy(data.reservation_settings)
            let rentalDuplicate = this.hasDuplicateOccupancy(data.rental_settings)
            if (reservationDuplicate) {
                this.$validator.errors.add({
                    field: 'duplicate',
                    msg: 'Reservation occupancy percentage cannot be repeated.'
                });
            }
            if (rentalDuplicate) {
                this.$validator.errors.add({
                    field: 'duplicate',
                    msg: 'Rental occupancy percentage cannot be repeated.'
                });
            }

            if (error && checker(allValidations) && !reservationDuplicate && !rentalDuplicate) {
                return true;
            } else {
                return false;
            }
        },
        async savePlan() {
            let data = this.form;
            data.name = data.name.trim().split(" ").filter(Boolean).join(' ');
            let validated = await this.validatePlan(data);
            if (validated) {
                try {
                    data.reservation_settings = this.form.reservation_settings.sort((a, b) => {
                        return parseInt(b.occupancy_percentage) - parseInt(a.occupancy_percentage);
                    });
                    data.rental_settings = this.form.rental_settings.sort((a, b) => {
                        return parseInt(b.occupancy_percentage) - parseInt(a.occupancy_percentage);
                    });
                    await api.post(this, api.APW_MANAGEMENT + 'plans', data)
                    this.showMessageNotification({ type: 'success', description: 'Plan saved successfully.' });
                    this.resetPlan();
                    this.dialog = false;
                    this.$emit('plan-save-success');
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error });
                }
            }
        },
        async updatePlan() {
            let data = this.form;
            data.name = data.name.trim().split(" ").filter(Boolean).join(' ');
            let validated = await this.validatePlan(data);
            if (validated) {
                try {
                    data.reservation_settings = this.form.reservation_settings.sort((a, b) => {
                        return parseInt(b.occupancy_percentage) - parseInt(a.occupancy_percentage);
                    });
                    data.rental_settings = this.form.rental_settings.sort((a, b) => {
                        return parseInt(b.occupancy_percentage) - parseInt(a.occupancy_percentage);
                    });
                    await api.put(this, api.APW_MANAGEMENT + 'plans/' + this.planDetails.id, data)
                    this.showMessageNotification({ type:'success', description: 'Plan updated successfully.' });
                    this.dialog = false;
                    this.$emit('plan-save-success');
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error });
                }
            }
        },
        removeRentalThreshold(index) {
            this.form?.rental_settings?.splice(index, 1);
        },
        removeReservationThreshold(index) {
            this.form?.reservation_settings?.splice(index, 1);
        },
        hasDuplicateOccupancy(data) {
            if (data.length > 1) {
                return data.some((item, index, self) =>
                    self.findIndex(t => t.occupancy_percentage === item.occupancy_percentage) !== index
                );
            } else {
                return false;
            }
        }
    },
};
</script>
<style scoped>
.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}
</style>