<template>
    <div class="section-content pt-4 mr-6">
        <apw-management-spaces
            :key="spaceData.spacegroup_id"
            v-if="hasSpaceData"
            class="pr-4"
            type="advanced reservations and rentals"
            @setPreviousData="setPreviousData($event)"
        />
        <div v-show="!hasSpaceData">
            <hb-header :divider="false" full>
                <hb-page-header title="Advanced Reservations and Rentals Plan Library"
                    description="This section is where users can create, manage, and view different advanced reservations and rentals plans.">
                </hb-page-header>
            </hb-header>

            <MenuTabs :menuOptions="menuOptions" @setMenuOption="setMenuOption" @selectedProperty="setSelectedProperty"></MenuTabs>
            <div v-if="!isPropertyLevel">
                <v-expansion-panels multiple>
                    <hb-expansion-panel guidance>
                        <template v-slot:title>
                            Reservation Cut-Off Time
                        </template>
                        <template v-slot:content>
                            <div class="pa-4">
                                The reservation cut-off time is a daily deadline that determines when reservations for the current day close and reservation window shifts to the next available day. After this time, customers can only reserve for future dates, helping to streamline scheduling and manage capacity effectively.
                                <br />To check or update the reservation cut-off time, go to 
                                <span class="hb-font-body-medium">Settings > Lead Management > Cut-Off Time for Reservation Window.</span>
                            </div>
                        </template>
                    </hb-expansion-panel>
                </v-expansion-panels>
                <div class="pt-6">
                    <div class="hb-font-body-medium">Advanced Reservations and Rentals Plan Library</div>
                    <div class="hb-font-body hb-text-light pt-3">The Advanced Reservations and Rentals Plan Library allows company-wide management of reservation and rental plans. You can create plans to control how far in advance users can reserve or rent, assign them to properties, and set a default plan for all space groups. Deleting a plan removes it from assigned groups, and a default plan will replace it if available.</div>
                </div>
                <div>
                    <hb-btn class="button-container" color="secondary" @click="showPlan" small>Add New Plan</hb-btn>
                </div>
                <apw-create-plan v-model="showCreatePlan" @plan-save-success="getAllPlans"></apw-create-plan>
                <hb-modal v-model="dialog" size="medium" title="Required Permission" confirmation footer-off="true">
                    <template v-slot:content>
                        <div class="px-6 py-4">
                            You do not have permission to create a plan.
                        </div>
                    </template> 
                </hb-modal>
                
                <v-row>
                    <v-col v-for="plan in plans" cols="12" md="6" xl="4">
                        <ApwCard style="height: 480px;" :options="true" :plan="plan" @plan-changed="getAllPlans"></ApwCard>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <apw-manage-plan :property_id="propertyDetails.id"></apw-manage-plan>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import api from '../../assets/api';
import MenuTabs from '../settings/Common/MenuTabs.vue';
import ApwCard from './ApwCard.vue';
import ApwCreatePlan from './ApwCreatePlan.vue';
import ApwManagePlan from './ApwManagePlan.vue';
import { mapGetters, mapActions } from "vuex";
import ApwManagementSpaces from "../revenue_management/utils/Spaces.vue";

export default {
    name: "AdvancedPurchaseWindow",
    components: {
        MenuTabs,
        ApwCard,
        ApwCreatePlan,
        ApwManagePlan,
        ApwManagementSpaces
    },
    data() {
        return {
            showCreatePlan: false,
            dialog: false,
            plans: [],
            menuOptions: [
                {
                    key: "corporate",
                    label: "Corporate Settings",
                },
                {
                    key: "property",
                    label: "Property Settings",
                },
            ],
            isPropertyLevel: false,
            propertyDetails: {}
        };
    },
    async created() {
        await this.getAllPlans();
    },
    computed: {
        ...mapGetters({
            spaceData: "revManStore/getSpaceData",
            hasPermission: 'authenticationStore/rolePermission'
        }),
        hasSpaceData() {
            return Object.keys(this.spaceData).length
        }
    },
    methods: {
        ...mapActions({
            setProperty: "revManStore/setProperty",
            filterGetProperty: "filterStore/getSelectedProperty",
            setBulkEditProperty: "propertiesStore/setBulkEditProperty",
            setDefaultProperty: 'propertiesStore/setDefaultProperty',
        }),
        showPlan() {
            if(this.hasPermission('create_plans')){
                this.showCreatePlan = true;
            }
            else{
                this.dialog=true;
            }
            
        },
        async getAllPlans() {
            try {
                this.plans = await api.get(this, api.APW_MANAGEMENT + 'plans')
            } catch (error) {
                console.log(error);
            }
        },
        async setMenuOption(option){
            if (option == 'property') {
                this.isPropertyLevel = true;
            }else{
                this.isPropertyLevel = false;
                await this.setProperty(null);
                this.propertyDetails = {}
            }
        },
        setSelectedProperty(property){
            this.propertyDetails = property;
            this.filterGetProperty(property);
            this.setBulkEditProperty(property);
        },
        setPreviousData(data) {
            this.isPropertyLevel = true;
            
            if (data?.propertyId) {
                this.previousSpaceGroupData = data;
            }
        },
    },
    async beforeDestroy() {
        if(!this.$route.fullPath.includes('/advanced-reservations-rentals')){
            await this.setProperty(null);
            this.filterGetProperty(null);
            this.setBulkEditProperty(null);
            this.setDefaultProperty({});
        }
    },
}
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}
</style>