import api from "../../../assets/api.js";
import { EventBus } from '../../../EventBus.js';

export default {
  async getProperties({ commit, getters, dispatch }, payload) {
    let charm_subscribed_properties = JSON.parse( sessionStorage.getItem('charm_subscribed_properties')  || null);
    if (charm_subscribed_properties && !payload.refresh) {
      return
    }
    await api.get(this.$app, api.CHARM_SUBSCRIBE_PROPERTIES).then(res => {
        let properties = res.properties?.filter(f => payload.primaryRoleProperties?.some(id => id === f.property_id && f.mini_charm_enabled))
        sessionStorage.setItem('charm_subscribed_properties', JSON.stringify(properties));
        commit("setProperties", properties);
        dispatch('charmSubscriptionStore/setFilteredProperties', payload.primaryRoleProperties, {root: true});
        if (res?.properties?.length && this.getters['authenticationStore/rolePermission']('mini_charm')) {
              let data = {
                agent_id: payload.userId,
                logged_in: true,
                facilities: {
                  available: properties?.map(m => m.gds_id),
                  unavailable: []
              },
                from: 'login'
              }
              if(data.facilities.available.length){
                dispatch('charmSubscriptionStore/setAvailableAgent', data, {root: true})
                .then(() => {
                  return api.get(this.$app, api.CHARM_AVAILABLE_AGENT, { agent_id: payload.userId });
                })
                .then((data) => {
                  data.test = "check";
                  dispatch('charmNotificationStore/setCharmAgentAvailability',(data?.available ? true : false), {root: true})
                })
                .catch((err) => {
                  console.log(err);
                });
              }                
        }
      })
      .catch(e => {
        console.log(e);
      });
  },
  async checkAgentAvailability({dispatch},payload){
    await api.get(this.$app, api.CHARM_AVAILABLE_AGENT,{agent_id: payload.userId}).then((data)=>{
      dispatch('charmNotificationStore/setCharmAgentAvailability',(data?.available ? true : false))
        })
        .catch((err)=>{
          console.log(err)
        })
    },
  async setFilteredProperties({ commit }, payload) {
        commit("setFilteredProperties", payload);
    },

  async setAvailableAgent({commit}, payload) {
    if(localStorage.getItem('auth_token')){
      await api.post(this.$app, api.CHARM_AVAILABLE_AGENT, payload).then(r => {}).catch(e => {console.log(e)});
    }    else{
      return
    }
  },

  setUserDetails({commit}, details) {
    commit('setUserDetails', details);
  }, 
  
  login({getters, dispatch}, payload) {
    if (getters?.properties?.length && getters?.userDetails?.permissions?.some(s => s.label === 'mini_charm')) {
      let data = {
        agent_id: getters?.userDetails?.userId,
        // availability: 'available',
        logged_in: true,
        // availabile_property_ids: getters.properties?.filter(f => getters.filteredProperties?.some(id => id === f.property_id))?.map(m => m.property_id),
        // unavailabile_property_ids: [],
        facilities: {
          "available": getters.properties?.filter(f => f.mini_charm_enabled && getters.filteredProperties?.some(id => id === f.property_id))?.map(m => m.gds_id),
          "unavailable": []
      },
        from: 'login'
      }
      dispatch('charmSubscriptionStore/setAvailableAgent', data, {root: true});
    }
  }

};
