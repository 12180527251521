export const ACCOUNTING = Object.freeze({
  FREQUENCY_LIST: [
    { text: "Daily", value: "daily" },
    { text: "Weekly", value: "weekly" },
    { text: "Every Two Weeks", value: "biweekly" },
    { text: "Monthly", value: "monthly" },
    { text: "Quarterly", value: "quarterly" },
  ],
  EXPORT_RANGE: [
    { text: "Yesterday", value: "yesterday" },
    { text: "Last 7 Days", value: "last_seven_days" },
    { text: "Last Calendar Month", value: "last_calendar_month" },
    { text: "Date Range", value: "date_range" },
    { text: "Date", value: "date" },
  ],
  WEEk_DAYS: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  ACCOUNTING_EXPORTS: {
    APPFOLIO: {
       KEY: 'appfolio',
       FORMATS: {
         CSV: {
           KEY: 'csv_appfolio',
           LABEL: 'AppFolio Summarized - CSV'   
         }
       }
    }
 },
  FORMAT_LIST: [
    { text: "General Ledger - PDF", value: "pdf" },
    { text: "Quickbooks Online - CSV", value: "csv_quickbooks" },
    { text: "Quickbooks Desktop - IIF", value: "iif_quickbooks" },
    { text: 'Quickbooks Desktop - IIF with Class Codes', value: 'iifQuickbooks_class_code' },
    { text: "Yardi - CSV", value: "csv_yardi" },
    { text: "Sage Intacct - CSV", value: "csv_sageintacct" },
    {
      text: "Accountant Summary",
      value: "pdf_accountant_summary",
      disabled: false,
    },
    { text: "Transaction Details", value: "csv_charges_detail" },
    { text: "Balance Sheet - PDF", value: "pdf_balance_sheet" },
    { text: "Yardi Financial Journal - CSV", value: "csv_yardifinancialjournal" },
    { text: 'Yardi Financial Journal - IPP - CSV', value: 'csv_yardi-financial-journal-ipp' },
    { text: 'AppFolio Summarized - CSV', value: 'csv_appfolio' },
    {
      text: "Transaction Journal CSV",
      value: "csv_charges_journal",
      disabled: true,
    },
    {
      text: "Transaction Journal PDF",
      value: "pdf_charges_journal",
      disabled: true,
    },
  ],
  TYPE_LIST: [
    { text: "Summarized", value: "summarized" },
    { text: "Detailed", value: "detailed" },
  ],
  BOOK_TYPES: [
    { text: "Cash Book", value: "0" },
    { text: "Accrual Book", value: "1" },
    { text: "Double Book", value: "2" },
  ],
  recordCashBookData: [
    {
      value: "both_books",
      name: "Yes",
    },
    {
      value: "cash_book_only",
      name: "No",
    },
  ],
  yardiExportRefColumn: [
    {
      value: "transaction",
      name: "Transaction Type",
    },
    {
      value: "management_software",
      name: "Management Software",
    },
  ],
  yardiExportTransactionNoFormat: [
    {
      value: "unique_each_transaction",
      name: "Unique for Each Transaction",
    },
    {
      value: "new_sequence_new_export",
      name: "New Sequence for New Export (starting from 1)",
    },
  ],
  appFolioCCAndACHConsolidated: [
    {
      value: "1",
      name: "Yes",
    },
    {
      value: "0",
      name: "No",
    },
  ],  
  sageIntacctJournalType: [
    {
      value: "SLJ",
      name: "Sales Journal (SLJ)",
    },
    {
      value: "GJ",
      name: "General Journal (GJ)",
    },
    {
      value: "CRJ",
      name: "Cash Receipts Journal (CRJ)",
    },
  ],
  requiredDefaultCOATypes: [
    "parking_rent" ,
    "storage_rent",
    "card_amex",
    "card_visa",
    "card_mastercard",
    "card_discover",
    "cash",
    "check",
    "ach_checking",
    "ach_savings",
  ],
});
