<template>
    <div>

        <div class="mr-6" v-if="level === 'Corporate'">
            <v-row
                v-show="notification.active"
                class="py-1 pl-3 mt-4 mx-0 mb-0"
                :class="'hb-notification-' + notification.type"
            >
                <v-col cols="10" class="ma-0 mt-2 pa-0">
                    <v-icon
                        v-if="notification.type == 'success'"
                        color="#00848E"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-check</v-icon>
                    <v-icon
                        v-if="notification.type == 'warning'"
                        color="#FB4C4C"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-close-circle</v-icon>
                    {{notification.message}}
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                    <v-btn icon class="mr-1" :ripple="false" @click="notification.active = false">
                        <v-icon color="#101318">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <hb-data-table-header class="pt-1">
                <template v-slot:description>
                    Add and manage all properties.
                </template>
                <template v-slot:actions>
                    <hb-btn small color="secondary" @click="showAdd = true">Add New Property</hb-btn>
                </template>
            </hb-data-table-header>

            <hb-data-table
                :headers="headers"
                :items="items"
                @click:row="editProperty"
            >
                <template v-slot:item.number="{ item }">{{ item.number }}</template>
                <template v-slot:item.name="{ item }">{{ item.name }}</template>
                <template
                    v-slot:item.address="{ item }"
                >{{ item.Address | formatAddress(',', true) }}</template>
                <template v-slot:item.year_opened="{ item }">{{ item.year_opened | formatDate }}</template>
                <template v-slot:item.operations_manager="{ item }">{{ getOperationsManagerName(item.operations_manager) }}</template>
                <template v-slot:item.performance_category="{ item }">{{ formatPerformanceCategory(item.performance_category) }}</template>
                <template v-slot:item.merchandise_delivery="{ item }">{{ item.merchandise_delivery ? 'Yes' : 'No' }}</template>
                <template v-slot:item.same_store_class="{ item }">{{ item.same_store_class }}</template>
                <template v-slot:item.MarketRegions="{ item }">{{ item.MarketRegions?.length ? item.MarketRegions.map(mr=>mr.name).join(', ') : ''}}</template>
                <template v-slot:item.Suburbs="{ item }">{{ item.Suburbs?.length ? item.Suburbs.map(s=>s.name).join(', ') : ''}}</template>
                <template v-slot:item.Entity="{ item }">{{ item.Entity?.name }}</template>

                <!-- turn below back on when groups is functioning -->
                <!--
                <template v-slot:item.group="{ item }">{{ item.group }}</template>
                -->

                <template v-slot:item.actions="{ item }">
                    <hb-menu
                        options
                        align-right
                    >
                        <v-list>
                            <v-list-item @click="editProperty(item)">
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>

                            <!-- delete functionality doesn't exist yet -->
                            <!--
                            <v-list-item @click="deleteProperty(item)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                            -->
                        </v-list>
                    </hb-menu>
                </template>
            </hb-data-table>

            <AddEditProperty
                v-if="showEdit"
                v-model="showEdit"
                @close="closeWindow"
                :setNotification="setNotification"
                :activePropertyID="selectedItemID"
                :adminsList="adminsList"
                :suburbsList="suburbsList"
                :entityList="entityList"
                :marketRegionsList="marketRegionsList"
                :performanceCategoryList="performanceCategoryList"
                :propertyBuildTypeList="propertyBuildTypeList"
                @refresh-suburbs="fetchSuburbs"
                @refresh-market-regions="fetchMarketRegions"
                @refresh-entities="fetchEntities"
            ></AddEditProperty>
            <AddProperty
                v-if="showAdd"
                v-model="showAdd"
                :adminsList="adminsList"
                :suburbsList="suburbsList"
                :entityList="entityList"
                :marketRegionsList="marketRegionsList"
                :performanceCategoryList="performanceCategoryList"
                :propertyBuildTypeList="propertyBuildTypeList"
                @close="closeWindow"
            >
            </AddProperty>
        </div>
        <div v-else class="mr-6">
            <property-groups></property-groups>
        </div>
    </div>
</template>
<script type="text/babel">
import AddEditProperty from "./AddEditProperty";
import AddProperty from "./AddProperty";
import PropertyGroups from "./PropertyGroups/Index.vue";
import { parseCountry } from '../../../utils/common';
import { mapGetters, mapActions } from "vuex";
import api from '../../../assets/api.js';

export default {
    name: "PropertiesIndex",
    props: {
        level : {
            type: String,
            default: ''
          }
    },
    data() {
        return {
            notification: {
                active: false,
                type: "",
                message: ""
            },
            headers: [
                { text: "Property Number", value: "number", width: 100 },
                { text: "Name", value: "name", width: 50 },
                { text: "Address", value: "address", width: 150 },
                { text: "Year Opened", value: "year_opened", width: 100 },
                { text: "Operations Manager", value: "operations_manager", width: 80 },
                { text: "Performance Category", value: "performance_category", width: 100 },
                { text: "Merchandise Delivery", value: "merchandise_delivery", width: 100 },
                { text: "Same Store Class", value: "same_store_class", width: 90 },
                { text: "Market Regions", value: "MarketRegions", width: 90 },
                { text: "Surrounding Suburbs", value: "Suburbs", width: 100 },
                { text: "Entity", value: "Entity", width: 20 },

                /* turn below back on when groups is functioning */
                /*
                { text: "Group", value: "group", width: 40 },
                */
               
                { text: "", value: "actions", align: "right", sortable: false, width: 10 }
            ],
            items: [],
            showAdd: false,
            showEdit: false,
            selectedItemID: '',
            adminsList: [],
            suburbsList: [],
            entityList: [],
            marketRegionsList: [],
            performanceCategoryList: [
                {name: 'New Property', label: 'new_property'},
                {name: 'Expanding Property', label: 'expanding_property'},
                {name: 'Performance Based', label: 'performance_based'}
            ],
            propertyBuildTypeList: [
                {name: 'Single Storied', label: 'single_storied'},
                {name: 'Multi Storied', label: 'multi_storied'},
                {name: 'Mixed', label: 'mixed'}
            ],
        };
    },
    components: {
        AddEditProperty,
        PropertyGroups,
        AddProperty
    },
    async created() {
        if (this.properties.length) {
            this.items = this.properties.map(p => {
                return {
                    id: p.id,
                    number: p.number,
                    name: p.name,
                    legal_name: p.legal_name,
                    Emails: p.Emails,
                    Phones: p.Phones,
                    Address: {
                        address: p.Address.address,
                        address2: p.Address.address2,
                        city: p.Address.city,
                        state: p.Address.state,
                        country: p.Address.country,
                        zip: p.Address.zip,
                        region: p.Address.region,
                        district: p.Address.district,
                        lat: p.Address.lat,
                        lng: p.Address.lng,
                        Suburb: p.Address.Suburb,
                    },
                    Suburbs: p.Suburbs,
                    MarketRegions: p.MarketRegions,
                    Entity: p.Entity,
                    merchandise_delivery: p.merchandise_delivery,
                    google_analytics_id: p.google_analytics_id,
                    landing_page: p.landing_page,
                    tax_number: p.tax_number,
                    year_opened: p.year_opened,
                    year_closed: p.year_closed,
                    occupancy_threshold: p.occupancy_threshold,
                    performance_category: p.performance_category,
                    operations_manager: p.operations_manager,
                    same_store_class: p.same_store_class,
                    build_type: p.build_type,
                    web_cms_id: p.web_cms_id,
                    general_ledger_id: p.general_ledger_id,
                    bank_id: p.bank_id,
                    tax_code: p.tax_code
                };
            });
        }
        await this.fetchAdmins();
        await this.fetchSuburbs();
        await this.fetchEntities();
        await this.fetchMarketRegions()
    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered"
        })
    },
    methods: {
        ...mapActions({
            setProperty: "propertiesStore/setProperty"
        }),
        setNotification(item) {
            this.notification = item;
        },
        editProperty(item) {
            this.selectedItemID = item.id;
            this.setProperty(item);
            this.showEdit = true;
            this.showAdd = false;
        },
        deleteProperty() {},
        closeWindow() {
            this.showAdd = false;
            this.showEdit = false;
            this.selectedItemID = '';
        },
        getPropertyAddress(data) {
            let address = []
            for (let key in data.Address) {
                if (data.Address[key] && key !== "address2") {
                    if(key === 'country'){
                        const country = parseCountry(data.Address[key])
                        address.push(country)
                        continue
                    }
                    address.push(data.Address[key])
                }
            }
            return address?.join(', ')
        },
        async fetchAdmins() {
            try {
                await api.get(this, api.ADMIN).then(r => {
                    this.adminsList = r.admins.map(admin => {
                    return {
                        contact_id: admin.contact_id,
                        name: admin?.first + ' ' + admin?.last
                    }
                    });
                });
            }
            catch(err){
                console.log(err)
            }
        },
        async fetchSuburbs() {
            try{
                await api.get(this, api.SUBURBS).then(r => {
                    this.suburbsList = r || []
                });
            }
            catch(err){
                console.log(err)
            }
        },
        async fetchEntities() {
            try{
                await api.get(this, api.ENTITIES).then(r => {
                    this.entityList = r || []
                });
            }
            catch(err){
                console.log(err)
            }
        },
        async fetchMarketRegions() {
            try{
                await api.get(this, api.MARKET_REGIONS).then(r => {
                    this.marketRegionsList = r || []
                });
            }
            catch(err){
                console.log(err)
            }
        },
        formatPerformanceCategory(value){
            let category =  this.performanceCategoryList.find(pc=> pc.label == value) || {};
            return category.name;
        },
        getOperationsManagerName(value){
            let manager = this.adminsList.find(admin => admin.contact_id == value);
            return manager && manager.name;
        }
    },
    watch: {
        properties() {
            if (this.properties.length) {
                this.items = this.properties.map(p => {
                    return {
                        id: p.id,
                        number: p.number,
                        name: p.name,
                        legal_name: p.legal_name,
                        Emails: p.Emails,
                        Phones: p.Phones,
                        Address: {
                            address: p.Address.address,
                            address2: p.Address.address2,
                            city: p.Address.city,
                            state: p.Address.state,
                            country: p.Address.country,
                            zip: p.Address.zip,
                            region: p.Address.region,
                            district: p.Address.district,
                            lat: p.Address.lat,
                            lng: p.Address.lng,
                            Suburb: p.Address.Suburb,
                        },
                        Suburbs: p.Suburbs,
                        MarketRegions: p.MarketRegions,
                        Entity: p.Entity,
                        merchandise_delivery: p.merchandise_delivery,
                        google_analytics_id: p.google_analytics_id,
                        landing_page: p.landing_page,
                        tax_number: p.tax_number,
                        year_opened: p.year_opened,
                        year_closed: p.year_closed,
                        occupancy_threshold: p.occupancy_threshold,
                        performance_category: p.performance_category,
                        operations_manager: p.operations_manager,
                        same_store_class: p.same_store_class,
                        build_type: p.build_type,
                        web_cms_id: p.web_cms_id,
                        general_ledger_id: p.general_ledger_id,
                        bank_id: p.bank_id,
                        tax_code: p.tax_code
                    };
                });
            }
        }
    }
};
</script>

<style scoped>
</style>